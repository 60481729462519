import { api } from '@brskl/core';

class CompanyAPI {
  // Main
  getTradePointStatus = (params = {}) => {
    const path = 'dashboard/utils/get-current-progress';
    return api.endpoints.business.company.get({ path, params });
  };
  getSummaryStatistics = (params = {}) => {
    const path = 'dashboard/store/get-summary-statistic';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getStatistics = (opts = {}) => {
    const { date_start, date_end, status, mode, store_id, limit } = opts;
    const path = 'dashboard/reports/orders/get-statistics';
    const params = {
      fields: {
        avg_amount: 'avg_amount',
        income: 'income',
        period: 'period',
        quantity: 'quantity',
        total_amount: 'total_amount',
        store_id: 'store_id',
        total_count: 'total_count',
        total_customers: 'total_customers',
      },
      filters: {
        date_start,
        date_end,
        status,
        store_id,
      },
      limit,
      grouping: [mode],
    };
    return api.endpoints.business.companyV2.get({ path, params });
  };
  bindPartyToStore = (params = {}) => {
    const path = 'dashboard/store/bind/party';
    return api.endpoints.business.company.post({ path, params });
  };
  saveEmployees = (params = {}) => {
    const path = 'dashboard/store/employee/save';
    return api.endpoints.business.company.post({ path, params });
  };

  // Catalogs

  //depricated ?
  getCatalogListV1 = (params = {}) => {
    const path = 'dashboard/catalog/get-list';
    return api.endpoints.business.company.get({ path, params });
  };
  getCatalogList = (params = {}) => {
    const path = 'dashboard/catalog/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getCatalogById = (params = {}) => {
    const path = 'dashboard/catalog/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  updateCatalog = (params = {}) => {
    const path = 'dashboard/catalog/update';
    return api.endpoints.business.company.post({ path, params });
  };
  bindCatalogToStore = (opts = {}) => {
    const { id, catalog_id } = opts;
    const params = { id, catalog_id };
    const path = 'dashboard/store/bind/catalog';
    return api.endpoints.business.company.post({ path, params });
  };
  bindCatalogToStores = (params = {}) => {
    const path = '/dashboard/catalog/set-stores';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  setCategories = (params = {}) => {
    const path = '/dashboard/catalog/set-categories';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  deleteCatalogById = (opts = {}) => {
    const { catalog_ids } = opts;
    const params = { catalog_ids };
    const path = 'dashboard/catalog/bulk/delete';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  // Stores
  getStoreList = (params = {}) => {
    const path = 'dashboard/store/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getStoreListV1 = (params = {}) => {
    const path = 'dashboard/store/get-list';
    return api.endpoints.business.company.get({ path, params });
  };
  getStoreById = (params = {}) => {
    const path = 'dashboard/store/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  activateStore = (params = {}) => {
    const path = 'dashboard/store/activate';
    return api.endpoints.business.company.post({ path, params });
  };
  activateStoreV2 = (params = {}) => {
    const path = 'dashboard/store/activate';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getStoreBoxState = (params = {}) => {
    const path = 'dashboard/store/box/get-state';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getStoresStatesByIds = (params = {}) => {
    const path = 'dashboard/device/ping/get-list-by-stores-ids';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getStoreScheduleById = (params = {}) => {
    const path = 'dashboard/schedule/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  getPopularItems = (params = {}) => {
    const path = 'dashboard/reports/items/get-popular-items';
    return api.endpoints.business.company.get({ path, params });
  };
  deleteStores = (opts = {}) => {
    const { store_ids } = opts;
    const path = 'dashboard/store/bulk/delete';
    const params = { store_ids };
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getMarketPlaceLink = (params = {}) => {
    const path = 'dashboard/store/get-link';
    return api.endpoints.business.company.post({ path, params });
  };

  // Categories
  getCategoryList = (params = {}) => {
    const path = 'dashboard/category/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getCategoryListV1 = (params = {}) => {
    const path = 'dashboard/category/get-list';
    return api.endpoints.business.company.get({ path, params });
  };
  getCategoryById = (params = {}) => {
    const path = 'dashboard/category/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  updateCategory = (params) => {
    const path = 'dashboard/category/update';
    return api.endpoints.business.company.post({ path, params });
  };
  createCategory = (params = {}) => {
    const path = 'dashboard/category/create';
    return api.endpoints.business.company.post({ path, params });
  };
  getCategoryDiscountById = (params = {}) => {
    const path = 'dashboard/category/discount/get';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getCategoryDiscountByDiscountId = (params = {}) => {
    const path = 'dashboard/category/discount/get-by-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  setCategoryDiscount = (params = {}) => {
    const path = 'dashboard/category/discount/save';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  createCategoryDiscount = (params = {}) => {
    const path = 'dashboard/category/discount/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  updateCategoryDiscountByDiscountId = (params = {}) => {
    const path = 'dashboard/category/discount/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  applyDiscountToAllItemsInCategory = (params = {}) => {
    const path = 'dashboard/category/discount/bulk-apply-items';
    return api.endpoints.business.company.post({ path, params });
  };
  deleteCategory = (params = {}) => {
    const path = 'dashboard/category/delete';
    return api.endpoints.business.company.post({ path, params });
  };
  deleteCategories = (opts = {}) => {
    const { category_ids } = opts;
    const path = 'dashboard/category/bulk/delete';
    const params = { category_ids };
    return api.endpoints.business.companyV2.post({ path, params });
  };

  activateOrDeactivateCategories = (params = {}) => {
    const path = 'dashboard/category/bulk/set-status';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  setCategoriesCatalog = (params = {}) => {
    const path = 'dashboard/category/bulk/set-catalog';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  // Items
  getItemList = (params = {}) => {
    const path = 'dashboard/item/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getSuggestedItems = (params = {}) => {
    const path = 'dashboard/item/suggest/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  generateStickers = (params = {}) => {
    const path = 'dashboard/sticker-set/generate';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getStickerSet = (params = {}) => {
    const path = 'dashboard/sticker-set/get-list';
    return api.endpoints.business.company.get({ path, params });
  };
  createOrUpdateStickerSet = (params = {}) => {
    const path = 'dashboard/sticker-set/create';
    return api.endpoints.business.company.post({ path, params });
  };
  updateItemInStickerSet = (params = {}) => {
    const path = 'dashboard/sticker-set/item/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  exportItemsList = (params = {}) => {
    const path = 'dashboard/item/export-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  setItemsCategory = (params = {}) => {
    const path = 'dashboard/item/bulk/set-category';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getItemById = (opts = {}) => {
    const { id } = opts;
    const params = { id };
    const path = 'dashboard/item/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  getItemDiscountFields = () => {
    const path = 'dashboard/item/discount/get-fields';
    return api.endpoints.business.company.get({ path });
  };
  getItemDiscountById = async (opts = {}) => {
    const { item_id } = opts;
    const params = { item_id };
    const path = 'dashboard/item/discount/get';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getItemDiscountByDiscountId = async (opts = {}) => {
    const { id } = opts;
    const params = { id };
    const path = 'dashboard/item/discount/get-by-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  updateItemById = async (opts = {}) => {
    const params = opts;
    const path = 'dashboard/item/update';
    return api.endpoints.business.company.post({ path, params });
  };
  addItemProps = async (opts = {}) => {
    const { props, item_id } = opts;
    const params = { props, item_id };
    const path = 'dashboard/item/prop/create';
    return api.endpoints.business.company.post({ path, params });
  };
  //

  addSuggestedItems = (params = {}) => {
    const path = 'dashboard/item/suggest/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  createItemDiscount = async (opts = {}) => {
    const { item_id, discount, round_mode, round_precision, status, period, type } = opts;
    const params = { item_id, discount, round_mode, round_precision, status, period, type };
    const path = 'dashboard/item/discount/create';

    return api.endpoints.business.companyV2.post({ path, params });
  };

  updateItemDiscountByDiscountId = async (opts = {}) => {
    const { id, discount, round_mode, round_precision, status, period, type } = opts;
    const params = { id, discount, round_mode, round_precision, status, period, type };
    const path = 'dashboard/item/discount/update';

    return api.endpoints.business.companyV2.post({ path, params });
  };

  updateItemDiscount = async (opts = {}) => {
    const { item_id, discount, round_mode, round_precision, status, period, type } = opts;
    const params = { item_id, discount, round_mode, round_precision, status, period, type };
    const path = 'dashboard/item/discount/save';

    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteItems = (opts = {}) => {
    const { item_ids } = opts;
    const path = 'dashboard/item/delete';
    const params = { item_ids };
    return api.endpoints.business.company.post({ path, params });
  };

  createItem = (params = {}) => {
    const path = 'dashboard/item/create';
    return api.endpoints.business.company.post({ path, params });
  };

  updateItem = (params = {}) => {
    const path = 'dashboard/item/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  setItemsSticker = (params = {}) => {
    const path = 'dashboard/item/bulk/set-sticker';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  setItemStatus = (params = {}) => {
    const path = 'dashboard/item/bulk/set-status';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  uploadItemPhoto = (params = {}) => {
    const path = 'dashboard/item/photo/upload';
    return api.endpoints.business.company.post({ path, params });
  };

  setNames = (params = {}) => {
    const path = 'dashboard/item/bulk/set-name';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  setPrices = (params = {}) => {
    const path = 'dashboard/item/bulk/set-price';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  setCustomOrderIndex = (params = {}) => {
    const path = 'dashboard/item/bulk/set-custom-order-index';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteItemPhoto = (params = {}) => {
    const path = 'dashboard/item/photo/delete';
    return api.endpoints.business.company.post({ path, params });
  };

  // Emploees
  //don't touch this, will crash all db of users
  deleteEmployees = (opts = {}) => {
    const { employee_ids } = opts;
    const path = 'dashboard/employee/bulk/delete';
    const params = { employee_ids };
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getPhoneMasks = (params = {}) => {
    const path = 'dashboard/get-phone-masks';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getCountryFlags = (params = {}) => {
    const path = 'dashboard/get-country-flags';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getTimezoneDictionary = (params = {}) => {
    const path = 'dashboard/get-timezone-dictionary';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  unbindEmployeeFromStore = (params = {}) => {
    const path = 'dashboard/employee/unbind-from-stores';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  // Orders
  getStatusesDictionary = (params = {}) => {
    const path = 'dashboard/order/get-statuses';
    return api.endpoints.business.company.get({ path, params });
  };
  getOrdersList = (params = {}) => {
    const path = 'dashboard/order/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getOrderItemList = (params = {}) => {
    const path = 'dashboard/order/item/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getOrdersDictionarys = (params = {}) => {
    const path = 'dashboard/order/get-dictionary';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getDiscountSetById = (params = {}) => {
    const path = 'dashboard/discount-set/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  getDiscountById = (params = {}) => {
    const path = 'dashboard/discount/get-by-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  updateDiscountSet = (params = {}) => {
    const path = 'dashboard/discount-set/update';
    return api.endpoints.business.company.post({ path, params });
  };
  updateDiscount = (params = {}) => {
    const path = 'dashboard/discount/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  createDiscountSet = (params = {}) => {
    const path = 'dashboard/discount-set/create';
    return api.endpoints.business.company.post({ path, params });
  };
  createDiscount = (params = {}) => {
    const path = 'dashboard/discount/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteDiscount = (params = {}) => {
    const path = 'dashboard/discount/delete';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  uploadDiscountSetImage = (params = {}) => {
    const path = 'dashboard/discount-set/image/upload';
    return api.endpoints.business.company.post({ path, params });
  };
  bindStoresToDiscountSet = (params = {}) => {
    const path = 'dashboard/discount-set/bind-to-stores';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  unbindStoreFromDiscountSet = (params = {}) => {
    const path = 'dashboard/discount-set/unbind-from-stores';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  exportOrderList = (params = {}) => {
    const path = 'dashboard/order/export-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  // Other
  getSettings = () => {
    const path = 'dashboard/get-settings';
    return api.endpoints.business.company.get({ path });
  };

  getSettingsV2 = () => {
    const path = 'dashboard/get-settings';
    return api.endpoints.business.companyV2.get({ path });
  };
  getUnitsCode = () => {
    const path = 'dashboard/get-units';
    return api.endpoints.business.company.get({ path });
  };

  /* TODO: replace this method from stores and add it to main init() */
  getCurrencyCodes = () => {
    const path = 'dashboard/get-currency-codes';
    return api.endpoints.business.company.get({ path });
  };
  createPaymentLink = (params = {}) => {
    const path = 'dashboard/create-payment-link';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  /* TODO: replace this method from stores and add it to main init() */
  getAvailableTaxSystems = () => {
    const path = 'dashboard/party/get-available-tax-systems';
    return api.endpoints.business.company.get({ path });
  };
  getTimeZoneList = () => {
    const path = 'dashboard/get-timezones';
    const options = { headers: { 'Accept-Language': localStorage.getItem('TLocale') } };
    return api.endpoints.business.companyV2.get({ path, options });
  };

  /* TODO: replace this method from stores and add it to main init() */
  getStoreAcceptedTypes = () => {
    const path = 'dashboard/store/get-accepted-types';
    return api.endpoints.business.company.get({ path });
  };
  verifyEmail = (params = {}) => {
    const path = 'dashboard/party/verify-email';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  confirmEmail = (params = {}) => {
    const path = 'dashboard/party/confirm-email';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  // Parties
  verifyParty = (params = {}) => {
    const path = 'dashboard/party/verify-party';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getFieldsToComplete = (params = {}) => {
    const path = 'dashboard/party/get-fields-to-complete';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getExchangePartyList = (params = {}) => {
    const path = 'dashboard/party/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getTerminalPartyById = (params = {}) => {
    const path = 'dashboard/terminal/get-by-party-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getExchangePartyListOld = (params = {}) => {
    const path = 'dashboard/party/get-list';
    return api.endpoints.business.company.get({ path, params });
  };
  getExchangeSettingsName = (params = {}) => {
    const path = 'dashboard/store/get-exchange-settings-name';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getPartyById = (params = {}) => {
    const path = 'dashboard/party/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  getPartyDoc = (params = {}) => {
    const path = 'dashboard/party/doc2/get-first';
    return api.endpoints.business.company.get({ path, params });
  };
  getPartyAccountItem = (params = {}) => {
    const path = 'dashboard/party/account/get-first';
    return api.endpoints.business.company.get({ path, params });
  };
  createParty = (params = {}) => {
    const path = 'dashboard/party/create';
    return api.endpoints.business.company.post({ path, params });
  };
  createPartyDoc = (params = {}) => {
    const path = 'dashboard/party/doc-2/create';
    return api.endpoints.business.company.post({ path, params });
  };
  createPartyAccount = (params = {}) => {
    const path = 'dashboard/party/account/create';
    return api.endpoints.business.company.post({ path, params });
  };
  sendPartyDoc = (params = {}) => {
    const path = 'dashboard/party/send-doc';
    return api.endpoints.business.company.get({ path, params });
  };
  uploadPartyFile = (params = {}) => {
    const path = 'dashboard/party/file/upload';
    return api.endpoints.business.company.post({ path, params });
  };
  getPartyFiles = (params = {}) => {
    const path = 'dashboard/party/file/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  bindPartyToUpdate = (params = {}) => {
    const path = 'dashboard/party/bind-update';
    return api.endpoints.business.company.post({ path, params });
  };
  createInternationalParty = (params = {}) => {
    const path = 'dashboard/party/create-international-with-autocomplete';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  patchParty = (params = {}) => {
    const path = 'dashboard/party/patch';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  patchTerminal = (params = {}) => {
    const path = 'dashboard/terminal/save-for-party';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  patchPartyAccount = (params = {}) => {
    const path = 'dashboard/party/account/patch';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  patchPartyDoc2 = (params = {}) => {
    const path = 'dashboard/party/doc2/patch';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  bindPhotosToDoc2 = (params = {}) => {
    const path = 'dashboard/party/file/bind-to-doc2';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  // Onboarding
  getCompanyData = (params = {}) => {
    const path = 'dashboard/utils/get-party-data';
    return api.endpoints.business.company.get({ path, params });
  };

  getAddress = (params = {}) => {
    const path = 'dashboard/utils/get-address';
    return api.endpoints.business.company.get({ path, params });
  };
  getAddressByCoords = (params = {}) => {
    const path = 'dashboard/utils/find-address-by-coords';
    return api.endpoints.business.company.get({ path, params });
  };

  getBankData = (params = {}) => {
    const path = 'dashboard/utils/get-bank-data';
    return api.endpoints.business.partner.get({ path, params });
  };

  // Employees
  getEmployeesList = (params = {}) => {
    const path = 'dashboard/employee/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getEmployeeById = (params = {}) => {
    const path = 'dashboard/employee/get-by-id';
    return api.endpoints.business.company.get({ path, params });
  };
  createEmployee = (params = {}) => {
    const path = 'dashboard/employee/create';
    return api.endpoints.business.company.post({ path, params });
  };
  updateEmployee = (params = {}) => {
    const path = 'dashboard/employee/update';
    return api.endpoints.business.company.post({ path, params });
  };
  bindEmployeesToStore = (params = {}) => {
    const path = 'dashboard/employee/bulk/bind-to-store';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getEmployeePreferences = async () => {
    const path = 'dashboard/employee/preferences/get';
    return api.endpoints.business.companyV2.get({ path });
  };
  setEmployeePreferences = async (params = {}) => {
    const path = 'dashboard/employee/preferences/set';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getGlobalSearch = (params = {}) => {
    const path = 'dashboard/global-search';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getBoxNoticeState = (params = {}) => {
    const path = 'dashboard/store/box/state/get-notice';
    return api.endpoints.business.company.get({ path, params });
  };
  setBoxNoticeState = (params = {}) => {
    const path = 'dashboard/store/box/state/set-notice';
    return api.endpoints.business.company.post({ path, params });
  };
  createSchedule = (params = {}) => {
    const path = 'dashboard/schedule/create';
    return api.endpoints.business.company.post({ path, params });
  };
  updateStore = (params = {}) => {
    const path = 'dashboard/store/patch';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  wmfImportDrinks = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/wmf/import-drinks';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getDeviceList = (params = {}) => {
    const path = 'dashboard/store/device/get-list';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getModuleList = (params = {}) => {
    const path = 'dashboard/device/get-list';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getRecordsForToday = (params = {}) => {
    const path = 'dashboard/device/temperature/get-records-for-today';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  deviceBind = (params = {}) => {
    const path = 'dashboard/store/device/bind';
    return api.endpoints.business.company.post({ path, params });
  };
  getTypeByBindKey = (params = {}) => {
    const path = 'dashboard/store/device/get-type-by-bind-key';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  /* WriteOffs */
  getMovementById = async ({ id, fields, type }) => {
    const path = 'dashboard/item/movement/get-list';
    const params = {
      fields,
      filters: { id, type },
      limit: 1,
    };
    let result = await api.endpoints.business.companyV2.get({ path, params });

    return result.items[0] || null; /* if there are no items in array null will return */
  };

  getMovementByIdTransfer = async (params = {}) => {
    const path = 'dashboard/item/movement/get-list';
    let result = await api.endpoints.business.companyV2.get({ path, params });
    return result.items[0] || null; /* if there are no items in array null will return */
  };
  getPositionsByMovementId = async ({ movement_id, fields, limit }) => {
    const path = 'dashboard/item/movement/position/get-list';
    const params = {
      fields,
      filters: { item_movement_id: movement_id },
      ...(limit ? { limit } : {}),
      make_cache: true
    };

    return api.endpoints.business.companyV2.get({ path, params });
  };
  getStoreByIdV2 = async ({ id, fields, with_trashed = false }) => {
    const path = 'dashboard/store/get-list';
    const params = {
      fields,
      filters: { id },
      limit: 1,
      ...(with_trashed && {with_trashed: true})
    };
    let result = await api.endpoints.business.companyV2.get({ path, params });

    return result.items[0] || null; /* if there are no items in array null will return */
  };

  getEmployeeByIdV2 = async ({ id, fields, with_trashed = false }) => {
    const path = 'dashboard/employee/get-list';
    const params = {
      fields,
      filters: { id },
      limit: 1,
      ...(with_trashed && {with_trashed: true})
    };
    let result = await api.endpoints.business.companyV2.get({ path, params });

    return result.items[0] || null; /* if there are no items in array null will return */
  };

  setCheckerSelect = async (params = {}) => {
    const path = 'dashboard/store/checker-select';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  /* create item movement */

  createItemMovement = async (params = {}) => {
    const path = 'dashboard/item/movement/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  updateItemMovement = async (params = {}) => {
    const path = 'dashboard/item/movement/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  /* storage report */
  createStorageReport = async (params = {}) => {
    const path = '/dashboard/reports/items/movement/get-list';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getStorageReportByID = async (params = {}) => {
    const path = 'dashboard/reports/items/movement/history/get-report-by-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getStorageReportList = async (params = {}) => {
    const path = 'dashboard/reports/items/movement/history/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  setReportName = async (params = {}) => {
    const path = 'dashboard/reports/items/movement/history/set-report-name';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteStorageReport = async (params = {}) => {
    const path = 'dashboard/reports/items/movement/history/delete-report';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  /* transfers */

  getTransfersItemList = (params = {}) => {
    const path = 'dashboard/item/movement/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  /* terminals */

  createTerminal = async (params = {}) => {
    const path = 'dashboard/party/create-partial';
    return api.endpoints.business.company.post({ path, params });
  };

  /* create store */

  uploadStoreLogo = (params = {}) => {
    const path = 'dashboard/store/upload-logo';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  uploadLogo = (params = {}) => {
    const path = 'dashboard/store/logo/upload';
    return api.endpoints.business.company.post({ path, params });
  };

  deleteStoreLogo = (params = {}) => {
    const path = 'dashboard/store/logo/delete';
    return api.endpoints.business.company.post({ path, params });
  };

  deleteCompanyBrandLogo = (params = {}) => {
    const path = 'dashboard/company-brand/logo/delete';
    return api.endpoints.business.company.post({ path, params });
  };

  bindCompanyBrandToStore = (params = {}) => {
    const path = 'dashboard/store/bind/company-brand';
    return api.endpoints.business.company.post({ path, params });
  };

  createCompanyBrand = (params = {}) => {
    const path = 'dashboard/company-brand/create';
    return api.endpoints.business.company.post({ path, params });
  };

  createStore = (params = {}) => {
    const path = 'dashboard/store/create';
    return api.endpoints.business.company.post({ path, params });
  };
  closeStoreBox = (params = {}) => {
    const path = 'dashboard/store/box/close';
    return api.endpoints.business.company.post({ path, params });
  };
  openStoreBox = (params = {}) => {
    const path = 'dashboard/store/box/open';
    return api.endpoints.business.company.post({ path, params });
  };

  //Profile
  getSelfProfile = (params = {}) => {
    const path = 'dashboard/profile/get';
    return api.endpoints.business.company.get({ path, params });
  };
  updateSelfProfile = async (params = {}) => {
    const path = 'dashboard/profile/update';
    return api.endpoints.business.company.post({ path, params });
  };

  // Coffee
  getCoffeeMachineStats = (params = {}) => {
    // unavailable
    const path = 'dashboard/store/coffee-machine/get-stats';
    return api.endpoints.business.company.get({ path, params });
  };
  getCoffeeMachineSerialNumber = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/get-info';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getCoffeeMachineDrinkTypes = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/item/get-drink-types';
    return api.endpoints.business.company.get({ path, params });
  };
  getCoffeeMachineCup = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/cup';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  setCoffeeMachineCup = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/cup';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  createCoffeeMachineItem = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/item/create';
    return api.endpoints.business.company.post({ path, params });
  };
  setCoffeeMachineItemImage = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/item/set-image';
    return api.endpoints.business.company.post({ path, params });
  };
  updateCoffeeMachineItem = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/item/update';
    return api.endpoints.business.company.post({ path, params });
  };
  tryCoffeeMachineReboot = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/reboot';
    return api.endpoints.business.company.post({ path, params });
  };
  tryCoffeeMachineLock = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/lock';
    return api.endpoints.business.company.post({ path, params });
  };
  tryCoffeeMachineUnlock = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/unlock';
    return api.endpoints.business.company.post({ path, params });
  };

  lockCoffeeMachine(params = {}) {
    const path = 'dashboard/store/coffee-machine/lock';
    return api.endpoints.business.company.post({ path, params });
  }

  unlockCoffeeMachine(params = {}) {
    const path = 'dashboard/store/coffee-machine/unlock';
    return api.endpoints.business.company.post({ path, params });
  }

  // Store
  createStoreGroup = (params = {}) => {
    const path = 'dashboard/store-group/create';
    return api.endpoints.business.company.post({ path, params });
  };
  createCatalog = (params = {}) => {
    const path = 'dashboard/catalog/create';
    return api.endpoints.business.company.post({ path, params });
  };
  createStoreCoffee = (params = {}) => {
    const path = 'dashboard/store/coffee-machine/create';
    return api.endpoints.business.company.post({ path, params });
  };

  // Company
  getSelfCompany = () => {
    const path = 'dashboard/company/get';
    return api.endpoints.business.company.get({ path });
  };
  getAvailableCompanies = () => {
    const path = 'dashboard/employee/get-available-companies';
    return api.endpoints.business.company.get({ path });
  };
  updateSelfCompany = (params = {}) => {
    const path = 'dashboard/company/update';
    return api.endpoints.business.company.post({
      path,
      params,
    });
  };

  bindEmployeeToCompany = (opts = {}) => {
    const path = 'dashboard/employee/bind-company';
    return api.endpoints.business.company.post({ path, params: opts });
  };

  //Menu

  createMenu = (params = {}) => {
    const path = 'dashboard/menu/create';
    return api.endpoints.business.companyV2.post({path, params});
  };

  updateMenu =(params = {}) => {
    const path = 'dashboard/menu/update';
    return api.endpoints.business.companyV2.post({path, params})
  };

  getMenuByID = (params = {}) => {
    const path = 'dashboard/menu/get-by-id';
    return api.endpoints.business.companyV2.get({path, params})
  };

  getMenuList = (params = {}) => {
    const path = 'dashboard/menu/get-list';
    return api.endpoints.business.companyV2.get({path, params})
  };

  deleteMenu = (params = {}) => {
    const path = `dashboard/menu/delete?menu_id=${params.menu_id}`
    return api.endpoints.business.companyV2.post({path, params})
  }

  // Misc
  getPatchNotes = (params = {}) => {
    const path = `/dashboard/documentation/get?type=${params}`;
    return api.endpoints.business.companyV2.get({ path, params });
  };

  deleteEmployee = (params = {}) => {
    const path = 'dashboard/employee/delete';
    return api.endpoints.business.company.post({ path, params });
  };

  bindEmployeeToStores = (params = {}) => {
    const path = 'dashboard/employee/bind-to-stores';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  //new for integration
  getExchangeAvailableServices = (params = {}) => {
    const path = 'dashboard/exchange/settings/service/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getExchangeServiceFields = (params = {}) => {
    const { service } = params;
    const path = 'dashboard/exchange/settings/service/get-required-fields';
    return api.endpoints.business.company.get({ path, params: { service } });
  };

  updateExchangeSettings = (params = {}) => {
    const path = 'dashboard/exchange/settings/update';
    return api.endpoints.business.company.post({ path, params });
  };

  createExchangeSettings = (params = {}) => {
    const path = 'dashboard/exchange/settings/create';
    return api.endpoints.business.company.post({ path, params });
  };

  getExchangeFileFields = (params = {}) => {
    const path = 'dashboard/item/file/get-fields';
    return api.endpoints.business.company.get({ path, params });
  };

  uploadExchangeFileWithBuffer = (params = {}) => {
    const { catalog_id, columns, file, generate_barcode } = params;

    const payload = new FormData();
    payload.append('file', file, file.name);
    payload.append('catalog_id', String(catalog_id));
    payload.append('columns', JSON.stringify(columns));
    payload.append('generate_barcode', JSON.stringify(generate_barcode));

    const path = 'dashboard/item/file/upload-with-buffer';

    return api.endpoints.business.company.post({ path, params: payload });
  };

  parseExchangeBufferChunks = (params = {}) => {
    const { buffer_id, row_start, row_end } = params;
    const path = 'dashboard/item/file/buffer/parse-chunk';

    return api.endpoints.business.company.post({
      path,
      params: {
        buffer_id,
        row_start,
        row_end,
      },
    });
  };

  parseExchangeBufferApprove = (params = {}) => {
    const { buffer_id } = params;
    const path = 'dashboard/item/file/buffer/approve';
    return api.endpoints.business.company.post({
      path,
      params: {
        buffer_id,
      },
    });
  };

  parseExchangeBufferDeny = (params = {}) => {
    const { buffer_id } = params;
    const path = 'dashboard/item/file/buffer/delete';
    return api.endpoints.business.company.post({
      path,
      params: {
        buffer_id,
      },
    });
  };

  // PAYMENTS HERE NOW!!! ==========>
  getAcquiringStoresList = (params = {}) => {
    const path = 'dashboard/store/get-list';
    return api.endpoints.payments.payments.get({ path, params });
  };
  createAcquiringStore = (params = {}) => {
    const path = 'dashboard/store/create';
    return api.endpoints.payments.payments.post({ path, params });
  };
  getOperationsList = (params = {}) => {
    const path = 'dashboard/transaction/get-list';
    return api.endpoints.payments.payments.get({ path, params });
  };
  createBill = (params = {}) => {
    const path = 'dashboard/invoice/create';
    return api.endpoints.payments.payments.post({ path, params });
  };
  getBillLink = (params = {}) => {
    const path = 'dashboard/invoice/get-link';
    return api.endpoints.payments.payments.get({ path, params });
  };
  getBillsList = (params = {}) => {
    const path = 'dashboard/invoice/get-list';
    return api.endpoints.payments.payments.get({ path, params });
  };

  // NEW REPORTS
  getReportsConfig = (params = {}) => {
    const path = 'dashboard/reports/orders/get-report-config';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  generateReport = (params = {}) => {
    const path = 'dashboard/reports/orders/generate-report';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  generateReportTotal = (params = {}) => {
    const path = 'dashboard/reports/orders/generate-report-total';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  //Carts
  getOnlineCartLinkTableData = (params = {}) => {
    const path = 'dashboard/online-cart-link/table-data';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getOnlineCartLinks = (params = {}) => {
    const path = 'dashboard/online-cart-link/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  createOnlineCartLink = (params = {}) => {
    const path = 'dashboard/online-cart-link/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteOnlineCartLink = (params = {}) => {
    const path = 'dashboard/online-cart-link/delete';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getTokensBySecretKey = (params = {}) => {
    const path = 'common/online-cart-auth';
    return api.endpoints.business.company.post({ path, params });
  };

  getStoreQrInfo = (params = {}) => {
    const path = 'dashboard/store/settings-qr/get';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  setStoreQrInfo = (params = {}) => {
    const path = 'dashboard/store/settings-qr/set';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getQrCode = (params = {}) => {
    const path = 'dashboard/store/settings-qr/download';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  //chats
  sendMessage = (params = {}) => {
    const path = 'chat/message/send';
    return api.endpoints.business.support.post({ path, params });
  };

  getMessages = (params = {}) => {
    const path = 'chat/message/get-list';
    return api.endpoints.business.support.get({ path, params });
  };

  setAsRead = (params = {}) => {
    const path = 'chat/message/set-as-read';
    return api.endpoints.business.support.post({ path, params });
  };

  //events section
  getEventsByOrderID = (params = {}) => {
    const path = 'dashboard/order-event/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getOrderEvents = (params = {}) => {
    const path = 'dashboard/order-event/get-group-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getMenu = (params = {}) => {
    const path = 'dashboard/section/get-list';
    return api.endpoints.business.company.get({ path, params });
  };

  getWriteOffReasons = () => {
    const path = '/dashboard/item/movement/get-config';
    return api.endpoints.business.companyV2.get({ path });
  };

  getRating = (params = {}) => {
    const path = 'dashboard/rating/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  }; // period = week|month|three_months|six_months, limit, sort_by = sum_orders_amount|sum_orders_count|sum_items_count, is_only_my_store=1|0.

  getRatingConfig = (params = {}) => {
    const path = 'dashboard/rating/get-config';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  requestApproveRatingCode = ({ phone }) => {
    const path = 'dashboard/rating/participation/send-message';
    const params = { phone };
    return api.endpoints.business.companyV2.post({ path, params });
  };

  sendConfirmRatingCode = ({ confirmation_code, key, is_rating_enabled }) => {
    const path = 'dashboard/rating/participation/confirm';
    const params = {
      confirmation_code,
      key,
      is_rating_enabled,
    };
    return api.endpoints.business.companyV2.post({ path, params });
  };

  toggleRating = (payload) => {
    const path = 'dashboard/rating/update-participation';
    const params = { is_rating_enabled: Number(!!payload) };
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getTop5 = (params = {}) => {
    // period, store_id
    const path = '/dashboard/rating/get-by-store-id';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getTerminalPromos = (params = {}) => {
    // {page: number, limit: number}
    const path = '/dashboard/store/advertisement/get-list';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  getTerminalPromoById = (params = {}) => {
    // {uuid: string}
    const path = '/dashboard/store/advertisement/get-by-uuid';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  createTerminalPromo = (params = {}) => {
    const path = '/dashboard/store/advertisement/create';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  updateTerminalPromo = (params = {}) => {
    const path = '/dashboard/store/advertisement/update';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deleteTerminalPromo = (params = {}) => {
    // {uuid: string}
    const path = '/dashboard/store/advertisement/delete';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  uploadTerminalPromoPhoto = (params = {}) => {
    // {uuid: string, file: base64 string}
    const path = '/dashboard/store/advertisement/upload-file';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  activateTerminalPromo = (params = {}) => {
    // {uuid: string}
    const path = '/dashboard/store/advertisement/activate';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  deactivateTerminalPromo = (params = {}) => {
    // {uuid: string}
    const path = '/dashboard/store/advertisement/deactivate';
    return api.endpoints.business.companyV2.post({ path, params });
  };

  getDailyTelemetry = (params = { store_id: null, date: null }) => {
    const path = 'dashboard/device/temperature/get-daily-summary';
    return api.endpoints.business.companyV2.get({ path, params });
  };

  rebootStoreDevice = (params = { store_id: null }) => {
    const path = 'dashboard/device/reboot';
    return api.endpoints.business.companyV2.post({ path, params });
  };
  getWeeklyTelemetry = (params = { store_id: null, date_from: null, date_to: null }) => {
    const path = 'dashboard/device/temperature/get-daily-summary-2';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  getMinimalTelemetryDate(params = { store_id: null }) {
    const path = 'dashboard/device/temperature/get-min-dates';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  fetchItemRelatedOrders(params = { item_id: null}) {
    const path = 'dashboard/reports/items/item-related-orders';
    return api.endpoints.business.companyV2.get({ path, params });
  };
  fetchItemStats(params = {}) {
    const path = 'dashboard/reports/orders/item-stats-report';
    return api.endpoints.business.companyV2.get({ path, params });
  }
}

export const companyAPI = new CompanyAPI();
