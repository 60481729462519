export const ru1 = {
  "Администратор": "Администратор",
  "Адрес": "Адрес",
  "Адрес изготовителя": "Адрес изготовителя",
  "Адрес регистрации": "Адрес регистрации",
  "Адрес торговой точки": "Адрес торговой точки",
  "Адрес:": "Адрес:",
  "АЗС": "АЗС",
  "Активация категории": "Активация категории",
  "Активация торговой точки": "Активация торговой точки",
  "активен": "активен",
  "Активен": "Активен",
  "Активировать": "Активировать",
  "Активировать торговую точку": "Активировать торговую точку",
  "активна": "активна",
  "Активна": "Активна",
  "Активность": "Активность",
  "Активные устройства": "Активные устройства",
  "Активный": "Активный",
  "Активный товар": "Активный товар",
  "Активных": "Активных",
  "Активных корзин пока нет": "Активных корзин пока нет",
  "Акты": "Акты",
  "Артикул": "Артикул",
  "Архивных корзин пока нет": "Архивных корзин пока нет",
  "Базовая цена": "Базовая цена",
  "Базовый каталог": "Базовый каталог",
  "Базовый каталог:": "Базовый каталог",
  "Банковские реквизиты:": "Банковские реквизиты:",
  "Без разогрева": "Без разогрева",
  "Белки": "Белки",
  "БИК": "БИК",
  "Боковые стикеры": "Боковые стикеры",
  "Брискли Бизнесс": "Брискли Бизнесс",
  "Будет сгенерирован при сохранении": "Будет сгенерирован при сохранении",
  "в валюте": "в валюте",
  "В выбранном кателоге нет товаров": "В выбранном кателоге нет товаров",
  "В данном разделе вам необходимо ввести пятизначный код для активации модуля.": "В данном разделе вам необходимо ввести пятизначный код для активации модуля.",
  "В корзине пока нет товаров": "В корзине пока нет товаров",
  "В корзине:": "В корзине:",
  "В меню": "В меню",
  "В настройках торговой точки вы можете настроить расписание работы, включить / отключить уведомления, а также назначить сотрудников.": "В настройках торговой точки вы можете настроить расписание работы, включить / отключить уведомления, а также назначить сотрудников.",
  "В приложении": "В приложении",
  "в процентах": "в процентах",
  "В случае деактивации торговая точка будет недоступна в приложении BPay": "В случае деактивации торговая точка будет недоступна в приложении BPay",
  "В торговую точку": "В торговую точку",
  "В этом разделе вы сможете контролировать и создавать поставки на ваши склады.": "В этом разделе вы сможете контролировать и создавать поставки на ваши склады.",
  "В этом разделе вы сможете отслеживать и создавать списания ваших товаров, назначать ответсвенных за этот процесс": "В этом разделе вы сможете отслеживать и создавать списания ваших товаров, назначать ответсвенных за этот процесс",
  "В этом разделе появятся акты, которые вы заключили с вашими контрагентами.": "В этом разделе появятся акты, которые вы заключили с вашими контрагентами.",
  "Валюта": "Валюта",
  "Ваш логотип": "Ваш логотип",
  "Введите адрес...": "Введите адрес...",
  "Введите артикул...": "Введите артикул...",
  "Введите возрастное ограничение": "Введите возрастное ограничение",
  "Введите значение": "Введите значение",
  "Введите значение...": "Введите значение...",
  "Введите ключ...": "Введите ключ...",
  "Введите код...": "Введите код...",
  "Введите название": "Введите название",
  "Введите название каталога": "Введите название каталога",
  "Введите название категории...": "Введите название категории...",
  "Введите название торговой точки...": "Введите название торговой точки...",
  "Введите название...": "Введите название...",
  "Введите наименование": "Введите наименование",
  "Введите наименование...": "Введите наименование...",
  "Введите описание": "Введите описание",
  "Введите описание...": "Введите описание...",
  "Введите причину списания": "Введите причину списания",
  "Введите размер скидки": "Введите размер скидки",
  "Введите размерность...": "Введите размерность...",
  "Введите срок годности...": "Введите срок годности...",
  "Введите сумму": "Введите сумму",
  "Введите цену товара": "Введите цену товара",
  "Введите цену...": "Введите цену...",
  "Введите ID или название товара": "Введите ID или название товара",
  "введите ID клиента": "введите ID клиента",
  "Введите...": "Введите...",
  "вернуть весь заказ": "вернуть весь заказ",
  "вернуть товар/ы": "вернуть товар/ы",
  "Вернуться": "Вернуться",
  "Вернуться в торговую точку": "Вернуться в торговую точку",
  "Вернуться к добавлению товаров": "Вернуться к добавлению товаров",
  "Вернуться к Каталогам": "Вернуться к Каталогам",
  "Вернуться к Категориям": "Вернуться к Категориям",
  "Вернуться к скидкам": "Вернуться к скидкам",
  "Вернуться к списку": "Вернуться к списку",
  "Вернуться к Товарам": "Вернуться к Товарам",
  "Вернуться назад": "Вернуться назад",
  "Видео": "Видео",
  "Видео отстутствует": "Видео отстутствует",
  "Видео:": "Видео:",
  "Виртуальные терминалы": "Виртуальные терминалы",
  "Виртуальный терминал": "Виртуальный терминал",
  "Виртуальный терминал :id": "Виртуальный терминал :id",
  "Виртуальный терминал ID :id": "Виртуальный терминал ID :id",
  "Включена": "Включена",
  "Включить доступ": "Включить доступ",
  "Внимание!\r\nВы уверены, что хотите покинуть страницу? Все несохранённые данные будут утеряны.": "Внимание!\r\nВы уверены, что хотите покинуть страницу? Все несохранённые данные будут утеряны.",
  "Внимание! Категория должна быть привязана к какому либо каталогу.\r\n\r\nЕсли вам необходимо создать новый каталог перейдите по ссылке": "Внимание! Категория должна быть привязана к какому либо каталогу.\r\n\r\nЕсли вам необходимо создать новый каталог перейдите по ссылке",
  "Внимание! Категория может быть прикреплена только к одному каталогу. Создайте новую категорию и прикрепите её к каталогу. Вы также можете пропустить этот шаг чтобы вернуться к нему позже из экрана каталога.\r\n\r\nПрикреплённые категории": "Внимание! Категория может быть прикреплена только к одному каталогу. Создайте новую категорию и прикрепите её к каталогу. Вы также можете пропустить этот шаг чтобы вернуться к нему позже из экрана каталога.\r\n\r\nПрикреплённые категории",
  "Возврат": "Возврат",
  "Возвраты": "Возвраты",
  "Возвращено товаров": "Возвращено товаров:",
  "Возмещения": "Возмещения",
  "Возмещения отсутствуют": "Возмещения отсутствуют",
  "Возрастное ограничение": "Возрастное ограничение",
  "Возрастное ограничение 18+": "Возрастное ограничение 18+",
  "Возрастное ограничение:": "Возрастное ограничение:",
  "Вперед": "Вперед",
  "Вперёд": "Вперёд",
  "Время": "Время",
  "Время действия": "Время действия",
  "Время действия:": "Время действия:",
  "Время операции указывается по часовому поясу торговой точки из которой перемещается товар.": "Время операции указывается по часовому поясу торговой точки из которой перемещается товар.",
  "Время оплаты:": "Время оплаты:",
  "Время сборки:": "Время сборки:",
  "Время создания": "Время создания",
  "Время создания:": "Время создания:",
  "все": "все",
  "Все": "Все",
  "Все дни:": "Все дни:",
  "Все каталоги": "Все каталоги",
  "Всего товаров к возврату:": "Всего товаров к возврату:",
  "Всего товаров:": "Всего товаров:",
  "Всего:": "Всего:",
  "Вы действительно хотите удалить устройство?": "Вы действительно хотите удалить устройство?",
  "Вы можете добавить товары к складу своей торговой точке, прикрепив к ней каталог. Или создать новый каталог и заполнить его товарами": "Вы можете добавить товары к складу своей торговой точке, прикрепив к ней каталог. Или создать новый каталог и заполнить его товарами",
  "Вы можете привязать несколько категорий к каталогу.": "Вы можете привязать несколько категорий к каталогу.",
  "Вы можете add products вручную или load products через .xlsx": "Вы можете add products вручную или load products через .xlsx",
  "Выберите": "Выберите",
  "Выберите валюту": "Выберите валюту",
  "Выберите дату": "Выберите дату",
  "Выберите единицу измерения...": "Выберите единицу измерения...",
  "Выберите каталог": "Выберите каталог",
  "Выберите каталог...": "Выберите каталог...",
  "Выберите категорию": "Выберите категорию",
  "Выберите категорию...": "Выберите категорию...",
  "Выберите магазин": "Выберите магазин",
  "Выберите налоги...": "Выберите налоги...",
  "Выберите объем": "Выберите объем",
  "Выберите отчётный период": "Выберите отчётный период",
  "Выберите поля, которые хотите видеть в таблице.": "Выберите поля, которые хотите видеть в таблице.",
  "Выберите размер наклейки...": "Выберите размер наклейки...",
  "Выберите сотрудника": "Выберите сотрудника",
  "Выберите столбец...": "Выберите столбец...",
  "Выберите тип напитка": "Выберите тип напитка",
  "Выберите товар": "Выберите товар",
  "Выберите товары": "Выберите товары",
  "Выберите товары для отображения в меню": "Выберите товары для отображения в меню",
  "Выберите торговую точку": "Выберите торговую точку",
  "Выберите торговую точку...": "Выберите торговую точку...",
  "Выберите торговые точки": "Выберите торговые точки",
  "Выберите формат в котором будет экспортирован список отчетов": "Выберите формат в котором будет экспортирован список отчетов",
  "Выберите формат для экспорта": "Выберите формат для экспорта",
  "Выберите...": "Выберите...",
  "Выбор каталога": "Выбор каталога",
  "Выбор фотографии": "Выбор фотографии",
  "Выбор языка": "Выбор языка",
  "Выбран": "Выбран",
  "Выбранное устройство": "Выбранное устройство",
  "Выбрано": "Выбрано",
  "Выбрано товаров": "Выбрано товаров",
  "Выбрано:": "Выбрано:",
  "Выбрать": "Выбрать",
  "выбрать дату": "выбрать дату",
  "Выбрать категорию": "Выбрать категорию",
  "выбрать период": "выбрать период",
  "Выбрать сотрудника": "Выбрать сотрудника",
  "Выбрать существующий": "Выбрать существующий",
  "Выбрать товар": "Выбрать товар",
  "Выручка": "Выручка",
  "Выходной": "Выходной",
  "г": "г",
  "Где уведомлять": "Где уведомлять",
  "Генеральный директор:": "Генеральный директор:",
  "Главная": "Главная",
  "год": "год",
  "Горизонтальная инструкция": "Горизонтальная инструкция",
  "Гражданство": "Гражданство",
  "Группа товаров, которые воспринимаются покупателями как взаимосвязанные и взаимозаменяемые. Например, супы или напитки.": "Группа товаров, которые воспринимаются покупателями как взаимосвязанные и взаимозаменяемые. Например, супы или напитки.",
  "Группировать по": "Группировать по",
  "Группировать по:": "Группировать по:",
  "Группировка": "Группировка",
  "д": "д",
  "да": "да",
  "Далее": "Далее",
  "Данные виртуального терминала заполнены успешно, и направлены на проверку.": "Данные виртуального терминала заполнены успешно, и направлены на проверку.",
  "Данные организации": "Данные организации",
  "Данные руководителя:": "Данные руководителя:",
  "Данный номер телефона будет использован в чеках оплаты приложения B-Pay.": "Данный номер телефона будет использован в чеках оплаты приложения B-Pay.",
  "Данные сохранены.": "Данные сохранены.",
  "Дата выдачи": "Дата выдачи",
  "Дата и время": "Дата и время",
  "Дата и время заказа": "Дата и время заказа",
  "Дата и время перемещения": "Дата и время перемещения",
  "Дата и время поставки": "Дата и время поставки",
  "Дата и время списания": "Дата и время списания",
  "Дата изготовления": "Дата изготовления",
  "Дата изготовления:": "Дата изготовления:",
  "Дата оплаты": "Дата оплаты",
  "Дата рождения": "Дата рождения",
  "Дата создания": "Дата создания",
  "Дата создания платежа": "Дата создания платежа",
  "Дата транзакции": "Дата транзакции",
  "Дата:": "Дата:",
  "Дверь": "Дверь",
  "Дверь микромаркета": "Дверь микромаркета",
  "Деактивация категории": "Деактивация категории",
  "деактивирован": "деактивирован",
  "День": "День",
  "День \tКоличество \tСумма \tПродажи \tПоступления \tКомиссия": "День \tКоличество \tСумма \tПродажи \tПоступления \tКомиссия",
  "Дизайн": "Дизайн",
  "Для активации торговой точки и её полного функционирования необходимо заполнить 2 основных раздела.": "Для активации торговой точки и её полного функционирования необходимо заполнить 2 основных раздела.",
  "Для активации торговой точки и её полного функционирования необходимо заполнить 3 основных раздела.": "Для активации торговой точки и её полного функционирования необходимо заполнить 3 основных раздела.",
  "Для дальнейшего использования реквизиты должны быть верно заполнены и проверены нашим специалистом. Вы можете fill in now недостающие данные или вернуться к этому позже.": "Для дальнейшего использования реквизиты должны быть верно заполнены и проверены нашим специалистом. Вы можете fill in now недостающие данные или вернуться к этому позже.",
  "Для добавления реквизитов, введите ИНН нового юридического лица.": "Для добавления реквизитов, введите ИНН нового юридического лица.",
  "Для добавления товаров в торговую точку выберите каталог или создайте новый.": "Для добавления товаров в торговую точку выберите каталог или создайте новый.",
  "Для добавления товаров Вам необходимо create directories и Categories .\r\nВ случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”": "вам необходимо создать только Каталог",
  "В случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”": "вам необходимо создать только Каталог",
  "If you upload your products via .xlsx and if the \"\"Category\"\" field is filled in the file": "you only need to create a catalog",
  "Для одной позиции": "Для одной позиции",
  "Для привязки устройства к вашей торговой точке, необходимо его активировать. Для этого введите 5-значный код, выданный вам при покупке.": "Для привязки устройства к вашей торговой точке, необходимо его активировать. Для этого введите 5-значный код, выданный вам при покупке.",
  "Для продаж нужны товары. Создайте или прикрепите\r\nсклад с товарами к вашей торговой точке": "Для продаж нужны товары. Создайте или прикрепите\r\nсклад с товарами к вашей торговой точке",
  "Для создания категории введите её название и по необходимости определите возрастное ограничение.": "Для создания категории введите её название и по необходимости определите возрастное ограничение.",
  "Для формирования отчёта по движению товаров необходимо задать следующие параметры: период времени, выбрать одну или несколько торговых точек и товар(ы), движение по которым вас интересует.": "Для формирования отчёта по движению товаров необходимо задать следующие параметры: период времени, выбрать одну или несколько торговых точек и товар(ы), движение по которым вас интересует.",
  "Для формирования отчёта укажите необходимые параметры выше и нажмите кнопку «Сформировать отчёт».": "Для формирования отчёта укажите необходимые параметры выше и нажмите кнопку «Сформировать отчёт».",
  "Дни недели": "Дни недели",
  "Дни недели в раписании": "Дни недели в раписании",
  "Добавить": "Добавить",
  "Добавить адрес": "Добавить адрес",
  "Добавить вид товара": "Добавить вид товара",
  "Добавить виртуальный терминал": "Добавить виртуальный терминал",
  "Добавить вручную": "Добавить вручную",
  "Добавить еще": "Добавить еще",
  "Добавить каталог": "Добавить каталог",
  "Добавить категорию": "Добавить категорию",
  "Добавить магазин": "Добавить магазин",
  "Добавить меню": "Добавить меню",
  "Добавить перемещение": "Добавить перемещение",
  "Добавить поставку": "Добавить поставку",
  "Добавить реквизиты": "Добавить реквизиты",
  "Добавить скидку": "Добавить скидку",
  "Добавить сотрудника": "Добавить сотрудника",
  "Добавить списание": "Добавить списание",
  "Добавить срок годности в штрихкод": "Добавить срок годности в штрихкод",
  "Печатать цену": "Печатать цену",
  "Добавить счёт": "Добавить счёт",
  "добавить терминал": "добавить терминал",
  "Добавить товар": "Добавить товар",
  "добавить товары": "добавить товары",
  "Добавить торговую точку": "Добавить торговую точку",
  "Добавить точку": "Добавить точку",
  "Добавить устройство": "Добавить устройство",
  "Добавлен": "Добавлен",
  "Добавлен:": "Добавлен:",
  "Добавление скидки": "Добавление скидки",
  "Добавление товаров": "Добавление товаров",
  "Добавление торговых точек": "Добавление торговых точек",
  "Добавьте новые реквизиты": "Добавьте новые реквизиты",
  "Добавьте первый счёт на оплату.": "Добавьте первый счёт на оплату.",
  "Добавьте первый товар в новый каталог и он станет доступен в вашей торговой точке": "Добавьте первый товар в новый каталог и он станет доступен в вашей торговой точке",
  "Добавьте сопутствующие товары чтобы мы могли предложить их при оформлении покупки в приложении BPay.": "Добавьте сопутствующие товары чтобы мы могли предложить их при оформлении покупки в приложении BPay.",
  "Документация": "Документация",
  "Документы": "Документы",
  "Дополнительное описание товара": "Дополнительное описание товара",
  "Дополнительный вид товара": "Дополнительный вид товара",
  "Дополнительный код": "Дополнительный код",
  "Доставка": "Доставка",
  "Доставка партнерами Briskly": "Доставка партнерами Briskly",
  "Доставки": "Доставки",
  "Доступ ко всему функционалу личного кабинета": "Доступ ко всему функционалу личного кабинета",
  "Доступ по ссылке:": "Доступ по ссылке:",
  "Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера": "Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера",
  "Доступна возможность принимать заказы покупателей и собирать их в торговом зале для отправки клиенту.": "Доступна возможность принимать заказы покупателей и собирать их в торговом зале для отправки клиенту.",
  "Единица измерения": "Единица измерения",
  "Ежедневно": "Ежедневно",
  "Если вам необходимо создать новый каталог перейдите по ссылке": "Если вам необходимо создать новый каталог перейдите по ссылке",
  "Если у вас фирменный стаканчик для кофе, то включите этот пункт и назначьте цену.": "Если у вас фирменный стаканчик для кофе, то включите этот пункт и назначьте цену.",
  "Жиры": "Жиры",
  "За все время": "За все время",
  "заблокирован": "заблокирован",
  "Завершен": "Завершен",
  "Завершение": "Завершение",
  "Завершите регистрацию": "Завершите регистрацию",
  "Завершить": "Завершить",
  "Заголовок": "Заголовок",
  "Заголовок:": "Заголовок:",
  "Загрузить": "Загрузить",
  "загрузить товары": "загрузить товары",
  "Загрузка логотипа торговой точки": "Загрузка логотипа торговой точки",
  "Загрузка фото товара": "Загрузка фото товара",
  "Заказ": "Заказ",
  "Заказ №": "Заказ №",
  "Заказов сегодня": "Заказов сегодня",
  "Закрытая территория": "Закрытая территория",
  "Замок микромаркета": "Замок микромаркета",
  "Заполните все необходимые поля.": "Заполните все необходимые поля.",
  "Заполните наименование платежа и его сумму, а затем скопируйте и отправьте ссылку вашему клиенту.": "Заполните наименование платежа и его сумму, а затем скопируйте и отправьте ссылку вашему клиенту.",
  "Заполните штрихкод": "Заполните штрихкод",
  "Заполните штрихкод...": "Заполните штрихкод...",
  "Заполнить": "Заполнить",
  "Заполнить недостающие данные": "Заполнить недостающие данные",
  "заполнить сейчас": "заполнить сейчас",
  "Здесь будет информация о перемещении ваших товаров из одной торговой точки в другую. Чтобы изменить область видимости товара, создайте перемещение": "Здесь будет информация о перемещении ваших товаров из одной торговой точки в другую. Чтобы изменить область видимости товара, создайте перемещение",
  "Здесь вы сможете отслеживать статус ваших счетов на оплат.": "Здесь вы сможете отслеживать статус ваших счетов на оплат.",
  "Значение QR-кода": "Значение QR-кода",
  "Из торговой точки": "Из торговой точки",
  "Изменения сохранены": "Изменения сохранены",
  "Изменения успешно сохранены": "Изменения успешно сохранены",
  "Изменить": "Изменить",
  "Измерение": "Измерение",
  "Измерение:": "Измерение:",
  "или": "или",
  "Имя": "Имя",
  "Имя сотрудника": "Имя сотрудника",
  "Индивидуальный предприниматель": "Индивидуальный предприниматель",
  "ИНН": "ИНН",
  "ИНН или наименование юридического лица": "ИНН или наименование юридического лица",
  "ИНН организации": "ИНН организации",
  "ИНН, КПП или название компании": "ИНН, КПП или название компании",
  "Инструкции": "Инструкции",
  "Инструкция": "Инструкция",
  "Интеграция": "Интеграция",
  "Информация о покупателе": "Информация о покупателе",
  "Информация о сотруднике": "Информация о сотруднике",
  "Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице «Юридические лица».": "Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице «Юридические лица».",
  "Информация по обновлениям": "Информация по обновлениям",
  "Искать везде": "Искать везде",
  "Исключённая стоимость": "Исключённая стоимость",
  "Используется, если для товара необходимо сканирование дополнительного кода (Data Matrix, ЕГАИС)": "Используется, если для товара необходимо сканирование дополнительного кода (Data Matrix, ЕГАИС)",
  "История обновлений": "История обновлений",
  "Источник": "Источник",
  "Итог. сумма": "Итог. сумма",
  "Итого": "Итого",
  "Итоговая сумма": "Итоговая сумма",
  "Итоговая цена": "Итоговая цена",
  "Итоговый остаток": "Итоговый остаток",
  "К списку": "К списку",
  "Календарь": "Календарь",
  "Калории": "Калории",
  "Каталог": "Каталог",
  "Каталог №": "Каталог №",
  "Каталог:": "Каталог:",
  "Каталог не был обновлен.": "Каталог не был обновлен.",
  "Каталог обновлен": "Каталог обновлен",
  "Каталог успешно обновлен.": "Каталог успешно обновлен.",
  "Каталоги": "Каталоги",
  "Каталоги и категории": "Каталоги и категории",
  "Категории": "Категории",
  "Категории не были созданы": "Категории не были созданы",
  "Категории созданы и прикреплены": "Категории созданы и прикреплены",
  "Категории созданы и успешно прикреплены к каталогу.": "Категории созданы и успешно прикреплены к каталогу.",
  "Категории удалены": "Категории удалены",
  "Категории успешно удалены.": "Категории успешно удалены.",
  "Категории не были удалены.": "Категории не были удалены.",
  "Категорий:": "Категорий:",
  "Категория": "Категория",
  "Категория не пуста": "Категория не пуста",
  "Категория товаров и услуг": "Категория товаров и услуг",
  "Категория №": "Категория №",
  "Категория:": "Категория:",
  "Кем выдан": "Кем выдан",
  "Клиент": "Клиент",
  "Клиент сам заберет заказ по указанному адресу": "Клиент сам заберет заказ по указанному адресу",
  "Ключ привязки": "Ключ привязки",
  "Код активации": "Код активации",
  "Код подразделения": "Код подразделения",
  "Код привязки модуля": "Код привязки модуля",
  "Код привязки": "Код привязки",
  "Кол-во": "Кол-во",
  "Кол-во товаров": "Кол-во товаров",
  "Количество наклеек": "Количество наклеек",
  "Количество товара": "Количество товара",
  "Количество товара:": "Количество товара:",
  "Количество:": "Количество:",
  "Комиссия": "Комиссия",
  "Комиссия банка": "Комиссия банка",
  "Конец": "Конец",
  "Контакты:": "Контакты:",
  "Кофемашина": "Кофемашина",
  "КПП": "КПП",
  "КПП:\r\n\r\nОГРН: \r\n\r\nОКОПФ: \r\n\r\nОКВЭД: \r\n\r\nСистема налогообложения:\r\n\r\nУставный капитал:": "КПП:\r\n\r\nОГРН: \r\n\r\nОКОПФ: \r\n\r\nОКВЭД: \r\n\r\nСистема налогообложения:\r\n\r\nУставный капитал:",
  "Краткое наименование организации:": "Краткое наименование организации:",
  "Круглосуточно": "Круглосуточно",
  "Куда": "Куда",
  "Купить": "Купить",
  "Купить устройство": "Купить устройство",
  "Курьер": "Курьер",
  "м": "м",
  "Магазин": "Магазин",
  "Магазин самообслуживания": "Магазин самообслуживания",
  "Магазины отсутствуют": "Магазины отсутствуют",
  "Макеты оклейки микромаркета": "Макеты оклейки микромаркета",
  "Максимальная мощность": "Максимальная мощность",
  "Максимальный": "Максимальный",
  "Маркетинг": "Маркетинг",
  "Меню": "Меню",
  "Меню удалено": "Меню удалено",
  "Меню не было привязано": "Меню не было привязано",
  "Меню не было удалено": "Меню не было удалено",
  "Место рождения": "Место рождения",
  "месяц": "месяц",
  "месяцы": "месяцы",
  "Микромаркет": "Микромаркет",
  "Минимальная мощность": "Минимальная мощность",
  "Минимальный": "Минимальный",
  "Минимальный размер и формат": "Минимальный размер и формат",
  "Минимальный размер и формат:": "Минимальный размер и формат:",
  "модификации": "модификации",
  "Модуль": "Модуль",
  "Мой сайт готов к приему реальных заказов": "Мой сайт готов к приему реальных заказов",
  "Мощность разогрева блюда": "Мощность разогрева блюда",
  "Мы автоматически загрузили вашу кофемашину стандартными напитками. Для правильного функционирования торговой точки активируйте товары и назначьте цену.": "Мы автоматически загрузили вашу кофемашину стандартными напитками. Для правильного функционирования торговой точки активируйте товары и назначьте цену.",
  "Мы запустили новую версию личного кабинета.": "Мы запустили новую версию личного кабинета.",
  "Перейти к старой версии?": "Перейти к старой версии?",
  "На главную": "На главную",
  "На заказ (наборы скидок)": "На заказ (наборы скидок)",
  "На категорию": "На категорию",
  "На Категорию ID :id": "На Категорию ID :id",
  "На карте": "На карте",
  "На основе каталога": "На основе каталога",
  "На рекламный блок": "На рекламный блок",
  "На сайте есть контактная информация и юридические реквизиты": "На сайте есть контактная информация и юридические реквизиты",
  "На сайте есть описание товаров или услуг, цена, условия возврата и доставки": "На сайте есть описание товаров или услуг, цена, условия возврата и доставки",
  "На стекло": "На стекло",
  "На товар": "На товар",
  "На товар ID :id": "На товар ID :id",
  "Назад": "Назад",
  "Назад к списку": "Назад к списку",
  "Название": "Название",
  "Название банка": "Название банка",
  "Название вашей скидки в бэкофисе": "Название вашей скидки в бэкофисе",
  "Название заказа": "Название заказа",
  "Название каталога": "Название каталога",
  "Название каталога...": "Название каталога...",
  "Название категории": "Название категории",
  "Название категории...": "Название категории...",
  "Название компании": "Название компании",
  "Название меню": "Название меню",
  "Название набора": "Название набора",
  "Название организации": "Название организации",
  "Название отчета": "Название отчета",
  "Название промокода": "Название промокода",
  "Название товара": "Название товара",
  "Название товара две строки": "Название товара две строки",
  "Название тор. точки": "Название тор. точки",
  "Название торговой точки": "Название торговой точки",
  "Название торговой точки...": "Название торговой точки...",
  "Название устройства": "Название устройства",
  "Назначить": "Назначить",
  "Назначить условия доставки": "Назначить условия доставки",
  "Наименование банка": "Наименование банка",
  "Наименование банка:": "Наименование банка:",
  "Наименование категории": "Наименование категории",
  "Наименование платежа": "Наименование платежа",
  "Наименование производителя": "Наименование производителя",
  "Наименование товара": "Наименование товара",
  "Наименование товаров и услуг": "Наименование товаров и услуг",
  "Наименование торговой точки": "Наименование торговой точки",
  "Найдены ошибки": "Найдены ошибки",
  "Наклейки:": "Наклейки:",
  "Наличие": "Наличие",
  "Наличие в торговых точках": "Наличие в торговых точках",
  "Наличие товаров": "Наличие товаров",
  "Наличие:": "Наличие:",
  "Налог": "Налог",
  "Настольный баннер": "Настольный баннер",
  "Настроить интеграцию": "Настроить интеграцию",
  "Настройка таблицы": "Настройка таблицы",
  "Настройки": "Настройки",
  "Настройки профиля": "Настройки профиля",
  "Настройте интеграцию со своей системой товароучёта и автоматически заполните склад товарами.\r\nВнимание!\r\nДля корректного заполнения всех полей может потребоваться пригласить вашего технического специалиста": "Настройте интеграцию со своей системой товароучёта и автоматически заполните склад товарами.\r\nВнимание!\r\nДля корректного заполнения всех полей может потребоваться пригласить вашего технического специалиста",
  "Наценка": "Наценка",
  "Нач. остаток": "Нач. остаток",
  "Нач. сумма": "Нач. сумма",
  "Начало": "Начало",
  "не активна": "не активна",
  "Не валидный телефон": "Не валидный телефон",
  "Не валидный email": "Не валидный email",
  "Не выбран": "Не выбран",
  "Не выбрана": "Не выбрана",
  "Не выбрано": "Не выбрано",
  "Не задан": "Не задан",
  "Не заполнено": "Не заполнено",
  "Не знаете где будет торговая точка?": "Не знаете где будет торговая точка?",
  "Не облагается": "Не облагается",
  "Не установлен": "Не установлен",
  "Неделя": "Неделя",
  "нет": "нет",
  "нет данных": "нет данных",
  "Нет данных": "Нет данных",
  "Нет данных по указанным параметрам": "Нет данных по указанным параметрам",
  "Нет добавленных сотрудников": "Нет добавленных сотрудников",
  "Нет расписания": "Нет расписания",
  "Нет сотрудников": "Нет сотрудников",
  "Новая поставка": "Новая поставка",
  "Новая скидка": "Новая скидка",
  "Новая скидка набора": "Новая скидка набора",
  "Новая торговая точка": "Новая торговая точка",
  "Новое перемещение": "Новое перемещение",
  "Новое списание": "Новое списание",
  "Новое юридическое лицо": "Новое юридическое лицо",
  "Новый виртуальный терминал": "Новый виртуальный терминал",
  "Новый каталог": "Новый каталог",
  "Новый отчет": "Новый отчет",
  "Новый товар": "Новый товар",
  "Номер...": "Номер...",
  "О категории": "О категории",
  "О товаре": "О товаре",
  "Обновление успешно": "Обновление успешно",
  "Обновления": "Обновления",
  "Оборудование": "Оборудование",
  "Общая выручка": "Общая выручка",
  "общая скидка": "общая скидка",
  "Общее кол-во товара:": "Общее кол-во товара:",
  "Общий доход": "Общий доход",
  "Общий срок годности": "Общий срок годности",
  "Объем": "Объем",
  "Обязательное поле": "Обязательное поле",
  "ОГРН": "ОГРН",
  "ОКВЭД": "ОКВЭД",
  "ОКОПФ": "ОКОПФ",
  "Онлайн-витрина": "Онлайн-витрина",
  "Операции": "Операции",
  "Операции будут доступны после первого платежа в подключенных магазинах.": "Операции будут доступны после первого платежа в подключенных магазинах.",
  "Операции отсутствуют": "Операции отсутствуют",
  "Описание": "Описание",
  "Описание состава...": "Описание состава...",
  "Описание товара": "Описание товара",
  "Описание...": "Описание...",
  "Оплата": "Оплата",
  "Оплата №": "Оплата №",
  "Оплата № :id": "Оплата № :id",
  "Оплаты": "Оплаты",
  "Определите условия доставки, площадь и возможность самовывоза.": "Определите условия доставки, площадь и возможность самовывоза.",
  "Организация": "Организация",
  "Основная информация": "Основная информация",
  "Основной": "Основной",
  "Основные данные": "Основные данные",
  "Основные настройки": "Основные настройки",
  "Особенности применения": "Особенности применения",
  "Особенности применения:": "Особенности применения:",
  "Остатки": "Остатки",
  "Ответственный": "Ответственный",
  "Ответственный сотрудник": "Ответственный сотрудник",
  "отключен": "отключен",
  "Открыт": "Открыт",
  "Откуда": "Откуда",
  "Откуда:": "Откуда:",
  "Отмена": "Отмена",
  "Отменить": "Отменить",
  "Отметьте, в случае если доступ к торговой точке ограничен для посторонних посетителей": "Отметьте, в случае если доступ к торговой точке ограничен для посторонних посетителей",
  "Отсутствует каталог": "Отсутствует каталог",
  "Отчество": "Отчество",
  "Отчет по движению": "Отчет по движению",
  "Отчетный период": "Отчетный период",
  "Отчётный период": "Отчётный период",
  "Отчеты": "Отчеты",
  "Отчёты": "Отчёты",
  "Отчёты по движению": "Отчёты по движению",
  "Отчёты по движению отсутствуют\r\nВ данном разделе вы сможете формировать различные отчёты по движению товаров с заданными параметрами, а так же скачать полученные данные в форматах .csv и .xlsx.": "Отчёты по движению отсутствуют\r\nВ данном разделе вы сможете формировать различные отчёты по движению товаров с заданными параметрами, а так же скачать полученные данные в форматах .csv и .xlsx.",
  "Оформить возврат": "Оформить возврат",
  "Оформление возврата": "Оформление возврата",
  "Оффлайн": "Оффлайн",
  "Очистить": "Очистить",
  "Очистить фильтры": "Очистить фильтры",
  "Ошибка активации": "Ошибка активации",
  "Параметры набора": "Параметры набора",
  "Параметры скидки": "Параметры скидки",
  "Паспорт": "Паспорт",
  "Паспорт:": "Паспорт:",
  "первая покупка без учета времени": "первая покупка без учета времени",
  "первая покупка после назначенного временного лимита": "первая покупка после назначенного временного лимита",
  "Первая страница": "Первая страница",
  "Перезагрузить": "Перезагрузить",
  "Перезагрузить кофемашину": "Перезагрузить кофемашину",
  "Перезагрузка кофемашины может помочь в случае некоторых неисправностей устройства.": "Перезагрузка кофемашины может помочь в случае некоторых неисправностей устройства.",
  "Перейти": "Перейти",
  "Переместить в каталог": "Переместить в каталог",
  "Переместить в категорию": "Переместить в категорию",
  "Перемещение :id": "Перемещение :id",
  "Перемещение №": "Перемещение №",
  "перемещения": "перемещения",
  "Перемещения": "Перемещения",
  "Перемещения отсутствуют": "Перемещения отсутствуют",
  "Период": "Период",
  "Период действия": "Период действия",
  "Период действия скидки": "Период действия скидки",
  "Персональные данные": "Персональные данные",
  "Персональные данные индивидульного предпринимателя:": "Персональные данные индивидульного предпринимателя:",
  "Печатать дату изготовления": "Печатать дату изготовления",
  "Печатать срок годности": "Печатать срок годности",
  "Печать стикера": "Печать стикера",
  "Печать стикера и ценника": "Печать стикера и ценника",
  "Питание кофемашины": "Питание кофемашины",
  "Питание устройства": "Питание устройства",
  "Платежи": "Платежи",
  "ПН, СР, ПТ": "ПН, СР, ПТ",
  "По вашему запросу ничего не найдено": "По вашему запросу ничего не найдено",
  "По данному заказу ещё не было возвратов": "По данному заказу ещё не было возвратов",
  "По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.": "По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.",
  "По товару": "По товару",
  "По торговой точке": "По торговой точке",
  "По умолчанию": "По умолчанию",
  "Подвердить": "Подвердить",
  "подключен": "подключен",
  "Подключите первый уже сейчас.": "Подключите первый уже сейчас.",
  "Подключить": "Подключить",
  "Подолжить": "Подолжить",
  "Подписан": "Подписан",
  "Подробнее": "Подробнее",
  "Подтвердить": "Подтвердить",
  "Поиск": "Поиск",
  "Поиск по товару": "Поиск по товару",
  "Показать все": "Показать все",
  "Показать все товары": "Показать все товары",
  "Показать еще": "Показать еще",
  "Показать ещё": "Показать ещё",
  "Показать по 10": "Показать по 10",
  "Показать по 25": "Показать по 25",
  "Показать по 50": "Показать по 50",
  "Показать по 7": "Показать по 7",
  "Показывать в приложении": "Показывать в приложении",
  "полгода": "полгода",
  "Полное наименование компании:": "Полное наименование компании:",
  "Полное наименование организации": "Полное наименование организации",
  "Полное наименование организации:": "Полное наименование организации:",
  "Популярные товары": "Популярные товары",
  "Порядок применения": "Порядок применения",
  "Поставка :id": "Поставка :id",
  "Поставка №": "Поставка №",
  "поставки": "поставки",
  "Поставки": "Поставки",
  "Поставок пока нет": "Поставок пока нет",
  "Поступление": "Поступление",
  "Превью меню": "Превью меню",
  "Предзаказ": "Предзаказ",
  "Префиксы штрихкода весового товара": "Префиксы штрихкода весового товара",
  "Привязать еще устройство": "Привязать еще устройство",
  "Привязать к торговой точке": "Привязать к торговой точке",
  "Привязать устройство": "Привязать устройство",
  "Привязка каталога": "Привязка каталога",
  "Придумайте название": "Придумайте название",
  "Приекрепленная торговая точка": "Приекрепленная торговая точка",
  "Прикрепите устройства для новой торговой точки. Например, Briskly модуль поможет вам со статистикой.": "Прикрепите устройства для новой торговой точки. Например, Briskly модуль поможет вам со статистикой.",
  "Прикрепить склад": "Прикрепить склад",
  "Прикрепить существующие": "Прикрепить существующие",
  "Прикреплен к торговым точкам:": "Прикреплен к торговым точкам:",
  "Приложение": "Приложение",
  "Применить": "Применить",
  "Применить для всех": "Применить для всех",
  "Применить к:": "Применить к:",
  "Применять в торговый точках:": "Применять в торговый точках:",
  "Применять в торговых точках:": "Применять в торговых точках:",
  "Принимает заказы покупателей и собирает их в торговом зале для отправки клиенту": "Принимает заказы покупателей и собирает их в торговом зале для отправки клиенту",
  "Причина списания": "Причина списания",
  "Проверить документы": "Проверить документы",
  "Проверка списка": "Проверка списка",
  "Программист": "Программист",
  "Продано": "Продано",
  "Продолжить": "Продолжить",
  "Производство с доставкой": "Производство с доставкой",
  "Промокоды": "Промокоды",
  "Промоматериалы": "Промоматериалы",
  "Пропустить": "Пропустить",
  "Протокол авторизации": "Протокол авторизации",
  "Профиль пользователя": "Профиль пользователя",
  "Радиус": "Радиус",
  "Радиус (м)": "Радиус (м)",
  "Раздел скидок пуст": "Раздел скидок пуст",
  "Размер наклейки, мм": "Размер наклейки, мм",
  "Размер скидки": "Размер скидки",
  "Размер скидки:": "Размер скидки:",
  "Размерность": "Размерность",
  "Разогрев блюда": "Разогрев блюда",
  "Расписание": "Расписание",
  "Расписание, уведомления и другие настройки": "Расписание, уведомления и другие настройки",
  "Расчётная 10%": "Расчетная 10%",
  "Расчётная 20%": "Расчетная 20%",
  "Рассылки": "Рассылки",
  "Расход": "Расход",
  "Расчетный": "Расчетный",
  "Регистрационный номер:": "Регистрационный номер:",
  "Регистрация": "Регистрация",
  "Редактирование перемещения": "Редактирование перемещения",
  "Редактирование поставки": "Редактирование поставки",
  "Редактирование списания": "Редактирование списания",
  "Редактирование торговой точки": "Редактирование торговой точки",
  "Редактировать": "Редактировать",
  "Реквизиты": "Реквизиты",
  "Реквизиты заполнены успешно, и направлены на проверку.": "Реквизиты заполнены успешно, и направлены на проверку.",
  "Реквизиты терминала": "Реквизиты терминала",
  "Реквизиты торговой точки": "Реквизиты торговой точки",
  "Реквизиты:": "Реквизиты:",
  "Роль": "Роль",
  "Роль в компании": "Роль в компании",
  "Роль сотрудника:": "Роль сотрудника:",
  "Роль:": "Роль:",
  "Сайт вашего магазина": "Сайт вашего магазина",
  "Самовывоз клиентом": "Самовывоз клиентом",
  "Сбербанк": "Сбербанк",
  "Сгенерировать ссылку": "Сгенерировать ссылку",
  "сделайте это сейчас.": "сделайте это сейчас.",
  "сделать это сейчас": "сделать это сейчас",
  "Себестоимость": "Себестоимость",
  "Серийный номер": "Серийный номер",
  "Серийный номер / Ключ активации": "Серийный номер / Ключ активации",
  "Серия": "Серия",
  "Сигаретный шкаф": "Сигаретный шкаф",
  "Система налогообложения": "Система налогообложения",
  "Система товароучёта": "Система товароучёта",
  "Система ТУ": "Система ТУ",
  "Скидка": "Скидка",
  "Скидка в процентах": "Скидка в процентах",
  "Скидка в рублях (фиксированная скидка)": "Скидка в рублях (фиксированная скидка)",
  "Скидки": "Скидки",
  "Скидки на заказ": "Скидки на заказ",
  "Скидки на категорию": "Скидки на категорию",
  "Скидки на товар": "Скидки на товар",
  "Скидки на товары": "Скидки на товары",
  "Скидки набора": "Скидки набора",
  "Скидки:": "Скидки:",
  "Склад": "Склад",
  "Склад / Остатки": "Склад / Остатки",
  "Склад / Отчет :id": "Склад / Отчет :id",
  "Склад / Перемещение": "Склад / Перемещение",
  "Склад / Перемещения": "Склад / Перемещения",
  "Склад / Поставка": "Склад / Поставка",
  "Склад / Поставки": "Склад / Поставки",
  "Склад / Списания": "Склад / Списания",
  "Склад и товары": "Склад и товары",
  "Скрыть все уведомления": "Скрыть все уведомления",
  "Скрыть подсказку": "Скрыть подсказку",
  "Собственная доставка": "Собственная доставка",
  "создайте": "создайте",
  "создайте новый": "создайте новый",
  "Создание возврата": "Создание возврата",
  "Создание каталога": "Создание каталога",
  "Создание перемещения": "Создание перемещения",
  "Создание поставки": "Создание поставки",
  "Создание списания": "Создание списания",
  "Создание терминала": "Создание терминала",
  "Создание товара": "Создание товара",
  "Создание торговой точки": "Создание торговой точки",
  "Создание успешно": "Создание успешно",
  "Создать": "Создать",
  "Создать каталог": "Создать каталог",
  "создать Каталоги": "создать Каталоги",
  "Создать категорию": "Создать категорию",
  "создать Набор скидок": "создать Набор скидок",
  "создать новую": "создать новую",
  "Создать новый": "Создать новый",
  "Создать новый каталог": "Создать новый каталог",
  "Создать отчет по движению": "Создать отчет по движению",
  "Создать сотрудника": "Создать сотрудника",
  "Создать ссылку": "Создать ссылку",
  "Создать счёт": "Создать счёт",
  "Сопутствующие товары": "Сопутствующие товары",
  "Состав": "Состав",
  "Состав заказа": "Состав заказа",
  "Состав меню": "Состав меню",
  "Состав перемещения": "Состав перемещения",
  "Состав поставки": "Состав поставки",
  "Состав списания": "Состав списания",
  "Состав:": "Состав:",
  "Состояние торговой точки": "Состояние торговой точки",
  "Сотрудники": "Сотрудники",
  "Сохранить": "Сохранить",
  "Списание :id": "Списание :id",
  "Списание №": "Списание №",
  "Списаний нет": "Списаний нет",
  "списания": "списания",
  "Списания": "Списания",
  "Списания / Списание": "Списания / Списание",
  "Список ваших каталогов и категорий пока пуст.": "Список ваших каталогов и категорий пока пуст.",
  "Список ваших товаров с заданными параметрами пока пуст.": "Список ваших товаров с заданными параметрами пока пуст.",
  "Список ваших товаров пока пуст.": "Список ваших товаров пока пуст.",
  "Список ссылок": "Список ссылок",
  "Список терминалов пока пуст.": "Список терминалов пока пуст.",
  "Список товаров доступных в торговой точке": "Список товаров доступных в торговой точке",
  "Способ операции": "Способ операции",
  "Способ оплаты": "Способ оплаты",
  "Средний чек": "Средний чек",
  "Средняя мощность": "Средняя мощность",
  "Срок годности": "Срок годности",
  "Срок годности:": "Срок годности:",
  "Ссылка для доступа": "Ссылка для доступа",
  "Ссылка на витрину товаров": "Ссылка на витрину товаров",
  "Ссылка на оплату:": "Ссылка на оплату:",
  "Стандарт": "Стандарт",
  "Стандарт:": "Стандарт:",
  "Статус": "Статус",
  "Статус возврата": "Статус возврата",
  "Статус заказа": "Статус заказа",
  "Статус кофемашины": "Статус кофемашины",
  "Статус платежа": "Статус платежа",
  "Статус поставки": "Статус поставки",
  "Статус реквизитов": "Статус реквизитов",
  "Статус скидки": "Статус скидки",
  "Статус списания": "Статус списания",
  "Статус товара": "Статус товара",
  "Стикер": "Стикер",
  "Стоимость доставки": "Стоимость доставки",
  "Стр. с пропиской": "Стр. с пропиской",
  "Страница меню": "Страница меню",
  "Страница сотрудника": "Страница сотрудника",
  "Страница товара": "Страница товара",
  "Страницы паспорта:": "Страницы паспорта:",
  "Сумма": "Сумма",
  "Сумма возврата:": "Сумма возврата:",
  "Сумма заказа": "Сумма заказа",
  "Сумма заказа:": "Сумма заказа:",
  "Сумма корзины:": "Сумма корзины:",
  "Сумма перемещения:": "Сумма перемещения:",
  "Сумма:": "Сумма:",
  "Сформировать": "Сформировать",
  "Сформировать валидный ШК автоматически": "Сформировать валидный ШК автоматически",
  "Сформировать отчет": "Сформировать отчет",
  "Счета": "Счета",
  "Счета на оплату": "Счета на оплату",
  "Счета отсутствуют": "Счета отсутствуют",
  "Т": "Т",
  "Телефон": "Телефон",
  "Телефон организации": "Телефон организации",
  "Телефон торговой точки": "Телефон торговой точки",
  "Телефон:": "Телефон:",
  "Температура": "Температура",
  "Температура внутри": "Температура внутри",
  "Терминал": "Терминал",
  "Тип": "Тип",
  "Тип договора": "Тип договора",
  "Тип доставки": "Тип доставки",
  "Тип заказа": "Тип заказа",
  "Тип напитка": "Тип напитка",
  "Тип округления": "Тип округления",
  "Тип оплаты": "Тип оплаты",
  "Тип отчета": "Тип отчета",
  "Тип скидки": "Тип скидки",
  "Тип торговой точки": "Тип торговой точки",
  "Тип уведомлений": "Тип уведомлений",
  "Тип:": "Тип:",
  "То, что будет видеть пользователь во вкладке % поверх бренда компании": "То, что будет видеть пользователь во вкладке % поверх бренда компании",
  "Товар №": "Товар №",
  "Товары": "Товары",
  "Торговая точка": "Торговая точка",
  "Торговая точка поставки": "Торговая точка поставки",
  "Торговая точка списания": "Торговая точка списания",
  "Торговая точка №": "Торговая точка №",
  "Торговые точки": "Торговые точки",
  "Торговые точки не были обновлены.": "Торговые точки не были обновлены",
  "Торговые точки обновлены.": "Торговые точки обновлены.",
  "Торговые точки отсутствуют": "Торговые точки отсутствуют",
  "Торговые точки отсутствуют\r\nВ этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала создайте первую торговую точку": "Торговые точки отсутствуют\r\nВ этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала создайте первую торговую точку",
  "Торговые точки терминала": "Торговые точки терминала",
  "Торговые точки успешно обновлены.": "Торговые точки успешно обновлены.",
  "Торговые точки:": "Торговые точки:",
  "Торговых точек:": "Торговых точек:",
  "Точность округления": "Точность округления",
  "У вас есть полный доступ ко всему функционалу личного кабинета.": "У вас есть полный доступ ко всему функционалу личного кабинета.",
  "У вас пока нет оплат": "У вас пока нет оплат",
  "Убрать из категории": "Убрать из категории",
  "Уведомления о состоянии соединения": "Уведомления о состоянии соединения",
  "Уведомления об изменении уровня температуры": "Уведомления об изменении уровня температуры",
  "Углеводы": "Углеводы",
  "Удалить": "Удалить",
  "Удалить каталог": "Удалить каталог",
  "Удалить категорию": "Удалить категорию",
  "Удалить отчет": "Удалить отчет",
  "Удалить сотрудника": "Удалить сотрудника",
  "Удалить товар": "Удалить товар",
  "Удалить торговую точку": "Удалить торговую точку",
  "Удалить устройсвто": "Удалить устройсвто",
  "Удалить устройство?": "Удалить устройство?",
  "Уйти без сохранения": "Уйти без сохранения",
  "Укажите": "Укажите",
  "Укажите адрес": "Укажите адрес",
  "Укажите заголовок": "Укажите заголовок",
  "Укажите код с устройства": "Укажите код с устройства",
  "Укажите название": "Укажите название",
  "Укажите радиус, в зоне действия которого клиент сможет взаимодействовать с торговой точкой": "Укажите радиус, в зоне действия которого клиент сможет взаимодействовать с торговой точкой",
  "Укажите реквизиты для отправки денег на расчётный счёт и выдачи чека клиентам.": "Укажите реквизиты для отправки денег на расчётный счёт и выдачи чека клиентам.",
  "Уставной капитал": "Уставной капитал",
  "Установите расписание работы вашего микромаркета: в какое время он открывается для покупок, а в какое время блокируется.": "Установите расписание работы вашего микромаркета: в какое время он открывается для покупок, а в какое время блокируется.",
  "Установите расписание работы вашего микромаркета. По умолчанию режим работы круглосуточный.": "Установите расписание работы вашего микромаркета. По умолчанию режим работы круглосуточный.",
  "Устройства": "Устройства",
  "Устройство": "Устройство",
  "Фактический адрес организации": "Фактический адрес организации",
  "Фактический адрес: \r\nТел\r\nР/с\r\nБИК": "Фактический адрес: \r\nТел\r\nР/с\r\nБИК",
  "Фамилия": "Фамилия",
  "Фильтры": "Фильтры",
  "ФИО": "ФИО",
  "ФИО:": "ФИО:",
  "Фирменный стаканчик": "Фирменный стаканчик",
  "Фото": "Фото",
  "Фото товара": "Фото товара",
  "Цена": "Цена",
  "Цена доставки": "Цена доставки",
  "Цена за стаканчик": "Цена за стаканчик",
  "Цена товара": "Цена товара",
  "Цена:": "Цена:",
  "Ценник": "Ценник",
  "ч": "ч",
  "Час": "Час",
  "Часовой пояс": "Часовой пояс",
  "Чек-ер": "Чек-ер",
  "Чекер": "Чекер",
  "Что нового": "Что нового",
  "Чтобы задать параметры, сначала выберите тип отчета.": "Чтобы задать параметры, сначала выберите тип отчета.",
  "Чтобы настроить состав меню, пожалуйста выберите базовый каталог": "Чтобы настроить состав меню, пожалуйста выберите базовый каталог",
  "Чтобы прявязать сотрудника к торговой точке выберите необходимую ниже.": "Чтобы прявязать сотрудника к торговой точке выберите необходимую ниже.",
  "Чтобы сменить каталог выберите необходимый ниже.": "Чтобы сменить каталог выберите необходимый ниже.",
  "Чтобы сменить категорию выберите необходимую ниже.": "Чтобы сменить категорию выберите необходимую ниже.",
  "Чтобы создать ссылку укажите ответственного сотрудника и время действия ссылки.": "Чтобы создать ссылку укажите ответственного сотрудника и время действия ссылки.",
  "Чтобы сформировать отчет, необходимо выбрать тип и задать параметры.": "Чтобы сформировать отчет, необходимо выбрать тип и задать параметры.",
  "ШК товара": "ШК товара",
  "шт": "шт",
  "Штрихкод": "Штрихкод",
  "Штрихкод:": "Штрихкод:",
  "Эквайринг": "Эквайринг",
  "Экранное меню": "Экранное меню",
  "Экспорт каталога": "Экспорт каталога",
  "Экспорт отчета": "Экспорт отчета",
  "Экспорт перемещения": "Экспорт перемещения",
  "Экспорт поставки": "Экспорт поставки",
  "Экспорт списания": "Экспорт списания",
  "Экспорт товара": "Экспорт товара",
  "Экспорт торговых точек": "Экспорт торговых точек",
  "Экспорт устройства": "Экспорт устройства",
  "Электронная почта": "Электронная почта",
  "Этаж": "Этаж",
  "этаж:": "этаж:",
  "Юридические лица": "Юридические лица",
  "Юридическое лицо": "Юридическое лицо",
  "Юридическое лицо ID :id": "Юридическое лицо ID :id",
  "Январь": "Январь",
  "all": "all",
  "ID или название торговой точки": "ID или название торговой точки",
  "ID перемещения": "ID перемещения",
  "ID поставки": "ID поставки",
  "ID клиента": "ID клиента",
  "ID списания": "ID списания",
  "ID товара": "ID товара",
  "ID торговой точки": "ID торговой точки",
  "Plus-500 (R290)": "Plus-500 (R290)",
  "R10NS (без рекламного блока)": "R10NS (без рекламного блока)",
  "R10NSG (с рекламным блоком)": "R10NSG (с рекламным блоком)",
  "R4NG, R5NG": "R4NG, R5NG",
  "R5N": "R5N",
  "R7N, R7M": "R7N, R7M",
  "Чек оплаты": "Чек оплаты",
  "Посмотреть чек": "Посмотреть чек",
  "Отчет №": "Отчет №",
  "Сменить компанию-партнёра": "Сменить компанию-партнёра",
  "Вы можете перейти в компанию партнёра. Выберите нужную из списка.": "Вы можете перейти в компанию партнёра. Выберите нужную из списка.",
  "Сменить компанию": "Сменить компанию",
  "Для активации торговой точки и её полного функционирования необходимо заполнить": "Для активации торговой точки и её полного функционирования необходимо заполнить",
  "основных раздела": "основных раздела",
  "Онлайн-корзины": "Онлайн-корзины",
  "Для продаж нужны товары. Создайте или прикрепите<br>склад с товарами к вашей торговой точке": "Для продаж нужны товары. Создайте или прикрепите<br>склад с товарами к вашей торговой точке",
  "Прикрепите устройства для новой торговой точки. Например": "<a href=\"#\" class=\"u-link\">Briskly модуль</a> поможет вам со статистикой.",
  "Посмотреть": "Посмотреть",
  "Порядок напитков": "Порядок напитков",
  "Укажите в каком порядке будут выводиться напитки на экране вашей кофемашины.": "Укажите в каком порядке будут выводиться напитки на экране вашей кофемашины.",
  "Замок закрыт": "Замок закрыт",
  "Закрыта": "Закрыта",
  "Открыть": "Открыть",
  "Уведомления": "Уведомления",
  "Ссылка на чек": "Ссылка на чек",
  "Поиск по таблице": "Поиск по таблице",
  "Экспорт оплаты": "Экспорт оплаты",
  "Скоро все изменится!": "Скоро все изменится!",
  "Пн": "Пн",
  "Вт": "Вт",
  "Ср": "Ср",
  "Чт": "Чт",
  "Пт": "Пт",
  "Сб": "Сб",
  "Вс": "Вс",
  "Половину вверх": "Половину вверх",
  "Половину вниз": "Половину вниз",
  "Целиком вверх": "Целиком вверх",
  "Целиком вниз": "Целиком вниз",
  "Для добавления товаров Вам необходимо": "Для добавления товаров Вам необходимо",
  "и": "и",
  "В случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”, вам необходимо создать только Каталог, Категории при этом будут созданы автоматичеcки.": "В случае загрузки товаров через .xlsx и наличия в файле заполненной графы “Категория”, вам необходимо создать только Каталог, Категории при этом будут созданы автоматичеcки.",
  "В разделе “Документы” размещены все выставленные вам по договору оферты счета, а также акты выполненных работ.": "В разделе “Документы” размещены все выставленные вам по договору оферты счета, а также акты выполненных работ.",
  "В данном блоке вы сможете отслеживать статус ваших счетов на оплату.": "В данном блоке вы сможете отслеживать статус ваших счетов на оплату.",
  "Вы можете": "Вы можете",
  "вручную или": "вручную или",
  "через .xlsx": "через .xlsx",
  "В данном разделе вы сможете формировать различные отчёты по вашей деятельности в виде таблиц с заданными параметрами, а так же будете иметь возможность выгрузить полученные данные в форматах .csv и .xlsx": "В данном разделе вы сможете формировать различные отчёты по вашей деятельности в виде таблиц с заданными параметрами, а так же будете иметь возможность выгрузить полученные данные в форматах .csv и .xlsx",
  "В этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала": "В этом разделе вы можете отслеживать работу своих торговых точек и настраивать их. Для начала",
  "первую торговую точку": "первую торговую точку",
  "Вы изменили настройки печати стикера, но не сохранили данные": "Вы изменили настройки печати стикера, но не сохранили данные",
  "Сохранить и распечатать": "Сохранить и распечатать",
};
