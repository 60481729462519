// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Счета'),
    route: { name: 'bills$list' },
  },
  {
    label: i18n.t('Новый счёт на оплату'),
    route: { name: 'bills$create' },
  },
];

export default [
  {
    path: '/bills',
    name: 'bills$list',
    component: () => import('@/apps/bills/views/List/List.vue'),
    meta: {
      breadCrumb: [breadCrumb[0]],
    },
  },
  {
    path: '/bills/create',
    name: 'bills$create',
    component: () => import('@/apps/bills/views/Create/Create.vue'),
    meta: {
      breadCrumb: [breadCrumb[0], breadCrumb[1]],
    },
  },
];
