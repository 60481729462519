// --- INJECTED ---
import { getI18n } from '@/core/i18n';

const i18n = getI18n();
// --- /INJECTED ---

import { companyAPI } from '@/core/api/company';
import { cloneDeep } from 'lodash';

const state = {
  // Menu
  menuTemplate: {
    id: '',
    title: '',
    displayMode: 1,
    selectedCatalogID: '',
    selectedTradePointsIDs: [],
    selectedItemsIDs: [],
  },
  menu: {
    id: '',
    title: '',
    displayMode: 1,
    selectedCatalogID: '',
    selectedTradePointsIDs: [],
    selectedItemsIDs: [],
  },
  menuDisplayMods: {
    0: i18n.t('Выключено'),
    1: i18n.t('Только просмотр'),
    2: i18n.t('Просмотр и оплата')
  },
  preSelectedDisplayMode: '',
  menus: [],
  paginatedMenus: [],
  filterMenusString: '',

  // Trade Points
  tradePoints: [],
  preSelectedTradePointsIDs: [],
  filterTradePointsString: '',
  isPreSelectAllTradePoints: false,
  isShowTradePointsModal: false,

  // Catalog
  catalogs: [],
  filteredCatalogs: [],
  preSelectedCatalogsIDs: [],
  selectedCatalogsIDs: [],
  isShowCatalogsModal: false,
  filterCatalogsString: '',
  isPreSelectAllCatalogs: false,

  // Item
  items: [],
  paginatedItems: [],
  filterItemsString: '',
  isSelectAllItems: false,
};

const getters = {
  // Menu
  menuTemplate: state => state.menuTemplate,
  menu: state => state.menu,
  menuDisplayMods: state => state.menuDisplayMods,
  preSelectedDisplayMode: state => state.preSelectedDisplayMode,
  menus: state => state.menus,
  paginatedMenus: state => state.paginatedMenus,
  filterMenusString: state => state.filterMenusString,
  filteredMenus: state => {
    return state.menus.filter((menu) => {
      return (
        !!(menu.title.toLowerCase().indexOf(state.filterMenusString.toLowerCase()) + 1) ||
        !!(menu.id.toString().indexOf(state.filterMenusString) + 1)
      );
    });
  },

  // Trade Points
  tradePoints: state => state.tradePoints,
  selectedTradePointsIDs: state => state.menu.selectedTradePointsIDs,
  preSelectedTradePointsIDs: state => state.preSelectedTradePointsIDs,
  filterTradePointsString: state => state.filterTradePointsString,
  filteredTradePoints: state => {
    return state.tradePoints.filter((tradePoint) => {
      return (
        !!(tradePoint.name.toLowerCase().indexOf(state.filterTradePointsString.toLowerCase()) + 1) ||
        !!(tradePoint.id.toString().indexOf(state.filterTradePointsString) + 1)
      );
    });
  },
  isPreSelectAllTradePoints: state =>
    state.tradePoints.length === state.preSelectedTradePointsIDs.length && !!state.preSelectedTradePointsIDs.length,
  isShowTradePointsModal: state => state.isShowTradePointsModal,

  // Catalog
  catalogs: state => state.catalogs,
  selectedCatalogID: state => state.menu.selectedCatalogID,
  selectedCatalogsIDs: state => state.selectedCatalogsIDs,
  filteredCatalogs: state => {
    return state.catalogs.filter(catalog => state.menus.map(menu => menu.catalog_id).includes(catalog.id))
      .filter(catalog => {
        return (
          !!(catalog.name.toLowerCase().indexOf(state.filterCatalogsString.toLowerCase()) + 1) ||
          !!(catalog.id.toString().indexOf(state.filterCatalogsString) + 1)
        );
      })
  },
  isPreSelectAllCatalogs: (state, getters) =>
    getters.filteredCatalogs.length === state.preSelectedCatalogsIDs.length && !!state.preSelectedCatalogsIDs.length,
  preSelectedCatalogsIDs: state => state.preSelectedCatalogsIDs,
  isShowCatalogsModal: state => state.isShowCatalogsModal,
  filterCatalogsString: state => state.filterCatalogsString,

  // Item
  items: state => state.items,
  paginatedItems: state => state.paginatedItems,
  selectedItemsIDs: state => state.menu.selectedItemsIDs,
  filterItemsString: state => state.filterItemsString,
  filteredItems: state => {
    return state.items.filter((item) => {
      return (
        !!(item.name.toLowerCase().indexOf(state.filterItemsString.toLowerCase()) + 1) ||
        !!(item.id.toString().indexOf(state.filterItemsString) + 1)
      );
    });
  },
  isSelectAllItems: (state, getters) => (getters.items.length === getters.selectedItemsIDs.length) && !!getters.selectedItemsIDs.length,
  previewItems: (state, getters) => getters.items.filter(item => getters.selectedItemsIDs.map(id => +id).includes(+item.id)),
};

const mutations = {
  // Menu
  setMenu: (state, payload) => state.menu = payload,
  setMenus: (state, payload) => state.menus = payload,
  setPreSelectedDisplayMode: (state, payload) => state.preSelectedDisplayMode = +payload,
  setPaginatedMenus: (state, payload) => state.paginatedMenus = payload,
  setFilterMenusString: (state, payload) => state.filterMenusString = payload,

  // Trade Point
  setTradePoints: (state, payload) => (state.tradePoints = payload),
  unselectTradePointID: (state, tradePointID) => {
    state.menu.selectedTradePointsIDs = state.menu.selectedTradePointsIDs.filter(
      (selectedTradePointID) => selectedTradePointID !== tradePointID,
    );
  },
  setSelectedTradePointsIDs: (state, tradePointsIDs) => (state.menu.selectedTradePointsIDs = tradePointsIDs),
  setPreSelectedTradePointsIDs: (state, preSelectedTradePointsIDs) => (state.preSelectedTradePointsIDs = preSelectedTradePointsIDs),
  setIsPreSelectAllTradePoints: (state, isPreSelectAllTradePoints) => (state.isPreSelectAllTradePoints = isPreSelectAllTradePoints),
  setFilterTradePointsString: (state, filterTradePointsString) => (state.filterTradePointsString = filterTradePointsString),
  setIsShowTradePointsModal: (state, bool) => (state.isShowTradePointsModal = bool),

  // Catalog
  setCatalogs: (state, payload) => (state.catalogs = payload),
  setPreSelectedCatalogsIDs: (state, payload) => state.preSelectedCatalogsIDs = payload,
  setIsShowCatalogsModal: (state, payload) => state.isShowCatalogsModal = payload,
  setFilterCatalogsString: (state, payload) => state.filterCatalogsString = payload,
  setIsPreSelectAllCatalogs: (state, payload) => state.isPreSelectAllCatalogs = payload,
  setSelectedCatalogsIDs: (state, payload) => state.selectedCatalogsIDs = payload,
  setSelectedCatalogID: (state, payload) => state.menu.selectedCatalogID = payload,

  // Item
  setItems: (state, payload) => (state.items = payload),
  setPaginatedItems: (state, payload) => (state.paginatedItems = payload),
  setSelectedItemsIDs: (state, selectedItemsIDs) => (state.menu.selectedItemsIDs = selectedItemsIDs),
  setFilterItemsString: (state, filterItemsString) => (state.filterItemsString = filterItemsString),
  setIsSelectAllItems: (state, isSelectAllItems) => (state.isSelectAllItems = isSelectAllItems),
};

const actions = {
  // Menu
  setMenu: ({ commit }, payload) => commit('setMenu', payload),
  setMenus: ({ commit }, payload) => commit('setMenus', payload),
  setPreSelectedDisplayMode: ({commit}, payload) => commit('setPreSelectedDisplayMode', payload),
  setSelectedCatalogID: ({commit}, payload) => commit('setSelectedCatalogID', payload),
  setPaginatedMenus: ({ commit }, payload) => commit('setPaginatedMenus', payload),
  setFilterMenusString: ({ commit }, payload) => commit('setFilterMenusString', payload),
  createMenu: async ({dispatch}, payload) => {
    let menu = await dispatch('prepareRequestMenu', payload);
    return companyAPI.createMenu(menu);
  },
  updateMenu: async ({ dispatch }, payload) => {
    let menu = await dispatch('prepareRequestMenu', payload)
    return companyAPI.updateMenu(menu)
  },
  updateRawMenu: ({}, menu) => {
    return companyAPI.updateMenu(menu)
  },
  getMenuByID: ({ commit, dispatch, rootGetters }, menuID) => {
    return companyAPI
      .getMenuByID({
        fields: { ...rootGetters['menus$menusTable/menuRequestFields'] },
        menu_id: menuID,
      })
      .then(async (res) => {
        let menu = await dispatch('prepareResponseMenu', res);
        commit('setMenu', menu);
        return menu;
      });
  },
  getMenusList: ({ commit, rootGetters }, filters = {}) => {
    return companyAPI.getMenuList({
      fields: {...rootGetters['menus$menusTable/menuRequestFields']},
      ...(!!Object.entries(filters).length && {filters})
    }).then(res => commit('setMenus', res.items));
  },
  getMenuByTradePointID: ({ commit, dispatch, rootGetters }, tradePointID) => {
    return companyAPI.getMenuList({
      fields: { ...rootGetters['menus$menusTable/menuRequestFields'] },
      filters: { store_id: [tradePointID] },
    })
      .then(async (res) => {
        let menu = await dispatch('prepareResponseMenu', res)
        commit('setMenu', menu);
        commit('setItems', res.items?.[0]?.items || []);
      });
  },
  deleteMenu: ({}, menuID) => {
    return companyAPI.deleteMenu({menu_id: menuID})
  },

  // Trade Point
  setTradePoints: ({ commit }, payload) => commit('setTradePoints', payload),
  getTradePointsByCatalogID: ({ commit, getters }, catalogID) => {
    return companyAPI
      .getStoreList({
        fields: {
          id: 'id',
          name: 'name',
          extended_type: 'extended_type',
          address: 'address',
          flag_is_test: 'flag_is_test',
        },
        filters: { catalog_id: [catalogID] },
      })
      .then((res) => commit('setTradePoints', res.items));
  },
  unselectTradePointID: ({ commit }, tradePointID) => commit('unselectTradePointID', tradePointID),
  setFilterTradePointsString: ({ commit }, filterTradePointsString) => commit('setFilterTradePointsString', filterTradePointsString),
  setSelectedTradePointsIDs: ({ commit }, selectedTradePointsIDs) => commit('setSelectedTradePointsIDs', selectedTradePointsIDs),
  setPreSelectedTradePointsIDs: ({ commit }, preSelectedTradePointsIDs) =>
    commit('setPreSelectedTradePointsIDs', preSelectedTradePointsIDs),
  savePreSelectedTradePointsIDs: ({ getters, dispatch }) => dispatch('setSelectedTradePointsIDs', getters.preSelectedTradePointsIDs),
  togglePreSelectAllTradePoints: ({ commit, getters }, isPreSelectAllTradePoints) => {
    commit('setIsPreSelectAllTradePoints', isPreSelectAllTradePoints);
    commit('setPreSelectedTradePointsIDs', isPreSelectAllTradePoints ? getters.tradePoints.map((tradePoint) => tradePoint.id) : []);
  },
  setIsShowTradePointsModal: ({ commit }, bool) => commit('setIsShowTradePointsModal', bool),

  // Catalog
  getCatalogs: ({ commit }) => {
    return companyAPI
      .getCatalogList({
        fields: {
          id: 'id',
          name: 'name',
          currency_code: 'currency_code',
          hash_id: 'hash_id'
        },
        limit: 9999,
      })
      .then((res) => commit('setCatalogs', res.items));
  },
  setIsShowCatalogsModal: ({ commit }, bool) => commit('setIsShowCatalogsModal', bool),
  togglePreSelectAllCatalogs: ({ commit, getters }, isPreSelectAllCatalogs) => {
    commit('setIsPreSelectAllCatalogs', isPreSelectAllCatalogs);
    commit('setPreSelectedCatalogsIDs', isPreSelectAllCatalogs ? getters.filteredCatalogs.map((catalog) => catalog.id) : []);
  },
  setFilterCatalogsString: ({commit}, payload) => commit('setFilterCatalogsString', payload),
  setPreSelectedCatalogsIDs: ({commit}, payload) => commit('setPreSelectedCatalogsIDs', payload),
  savePreSelectedCatalogsIDs: ({ getters, commit }) => commit('setSelectedCatalogsIDs', getters.preSelectedCatalogsIDs),
  clearSelectedCatalogs: ({commit}) => {
    commit('setSelectedCatalogsIDs', []);
    commit('setPreSelectedCatalogsIDs', []);
  },

  // Item
  getItemsByCatalogID: ({ commit }, catalogID) => {
    return companyAPI
      .getItemList({
        fields: {
          id: 'id',
          name: 'name',
          unit: 'unit',
          price: 'price',
          quantity_calculated: 'quantity_calculated',
          status: 'status',
          photo_link_100x100: 'photo_link',
        },
        filters: {
          catalog_id: [catalogID],
        },
        limit: 9999,
      })
      .then((res) => commit('setItems', res.items));
  },
  setItems: ({ commit }, items) => commit('setItems', items),
  setPaginatedItems: ({ commit }, payload) => commit('setPaginatedItems', payload),
  setSelectedItemsIDs: ({ commit }, selectedItemsIDs) => commit('setSelectedItemsIDs', selectedItemsIDs),
  setFilterItemsString: ({ commit }, filterItemsString) => commit('setFilterItemsString', filterItemsString),
  toggleSelectAllItems: async ({ commit, getters, dispatch }, isSelectAllItems) => {
    // if (isSelectAllItems) {
    //   await dispatch('getItemsByCatalogID', getters.selectedCatalogID);
    // }
    commit('setIsSelectAllItems', isSelectAllItems);
    commit('setSelectedItemsIDs', isSelectAllItems ? getters.items.map((item) => item.id) : []);
  },

  // Other
  clearAllSelectsAndFilters: ({ commit }) => {
    commit('setPreSelectedTradePointsIDs', []);
    commit('setSelectedTradePointsIDs', []);
    commit('setSelectedItemsIDs', []);
    commit('setFilterItemsString', '');
    commit('setFilterTradePointsString', '');
  },
  clearAllData: ({commit, getters}) => {
    commit('setMenu', cloneDeep(getters.menuTemplate));
    commit('setTradePoints', []);
    commit('setPreSelectedTradePointsIDs', []);
    commit('setSelectedTradePointsIDs', []);
    commit('setFilterTradePointsString', '');
    commit('setItems', []);
    commit('setPaginatedItems', []);
    commit('setFilterItemsString', '');
    commit('setSelectedCatalogID', '')
  },

  prepareResponseMenu: ({getters}, response) => {
    let hasMenu = !!response.items.length
    if(!hasMenu) return getters.menuTemplate;
    let menu = hasMenu ? response.items[0] : getters.menuTemplate;
    let selectedTradePointsIDs = menu.stores?.map(store => store.id) || [];
    let selectedItemsIDs = menu.items?.map(item => item.id) || [];
    menu = {...menu, selectedTradePointsIDs, selectedItemsIDs}
    return {
      id: menu.id,
      title: menu.title,
      displayMode: menu.display_mode,
      selectedCatalogID: menu.catalog_id,
      selectedTradePointsIDs,
      selectedItemsIDs
    }
  },

  prepareRequestMenu: ({}, payload) => {
    return {
      ...(payload.id && {menu_id: payload.id}),
      title: payload.title,
      display_mode: payload.displayMode,
      catalog_id: payload.selectedCatalogID,
      stores: payload.selectedTradePointsIDs,
      items: payload.selectedItemsIDs,
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
