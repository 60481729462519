import { ru1 } from './ru1';

export const ru = {
  ...ru1,
  briskly_requisites: 'Реквизиты Briskly',
  main_data: 'Основные данные',
  unit_796: 'Штука',
  unit_657: 'Изделие',
  unit_111: 'Миллилитр',
  unit_112: 'Литр',
  unit_163: 'Грамм',
  unit_625: 'Лист',
  unit_704: 'Набор',
  unit_715: 'Пара',
  unit_166: 'Килограмм',
  unit_736: 'Рулон',
  unit_778: 'Упаковка',
  unit_876: 'Порция',

  documents_tabs_docs: 'Документы',
  documents_tabs_acts: 'Акты',
  documents_tabs_bills: 'Счета',
  documents_tabs_payment: 'Оплата',
  documents_requisites: 'Реквизиты',
  documents_type: 'Тип',
  documents_added: 'Добавлен',
  documents_is_signed: 'Подписан',

  unit: 'шт',

  documents_document_fallback_name: 'Без имени',
  documents_document_fallback_type: 'Не указан',
  documents_document_fallback_id: 'Без идентификатора',
  documents_document_fallback_requisites: 'Реквизиты не указаны',

  Invoice: 'Счет на оплату',
  UpdStatus1: 'Универсальный передаточный документ',
  ActForTheReportingPeriod1: 'Акт за отчетный период',
  ActForTheReportingPeriod2: 'Акт взаиморасчетов за отчетный период',
  OrdersReport1: 'Отчет о продажах',

  BYN: 'Белорусский рубль (BYN)',
  USD: 'Доллар США (USD)',
  EUR: 'Евро (EUR)',
  KZT: 'Казахский тенге (KZT)',
  CAD: 'Канадский доллар (CAD)',
  CNY: 'Китайский юань (CNY)',
  RUB: 'Российский рубль (RUB)',
  TRY: 'Турецкая лира (TRY)',
  UAH: 'Украинская гривна (UAH)',
  GBP: 'Фунт стерлингов (GBP)',
  CHF: 'Швейцарский франк (CHF)',
  HRK: 'Хорватская куна (HRK)',
  AED: 'Дирхам ОАЭ (AED)',

  _CODE: ' ₽',
  BYN_CODE: '₽',
  USD_CODE: '$',
  EUR_CODE: '€',
  KZT_CODE: '₸',
  CAD_CODE: '$',
  CNY_CODE: '¥',
  RUB_CODE: '₽',
  TRY_CODE: '₺',
  UAH_CODE: '₴',
  GBP_CODE: '£',
  CHF_CODE: '₣',
  HRK_CODE: 'kn',
  AED_CODE: 'DH',

  datetimeCreatedAt: 'Дата и время создания',
  customer: 'Клиент',
  status: 'Статус',
  store: 'Магазин самообслуживания',
  refrigerator: 'Микромаркет',
  store_online: 'Онлайн-витрина',
  cafe: 'Предзаказ',
  production_with_delivery: 'Производство с доставкой',
  filling_station: 'АЗС',
  cigarettes: 'Сигаретный шкаф',
  coffee_machine: 'Кофемашина',

  address: 'Адрес',
  phone: 'Телефон',

  active_status: 'Ожидает оплаты',
  paid_status: 'Оплачен',
  refund_status: 'Возвращен',
  refund_awaiting_status: 'Ожидается возврат',
  refund_error_status: 'Ошибка возврата',
  refund_partial_status: 'Частично возвращен',
  removed_status: 'Отменен',

  // chart data
  avg_amount: 'Средний чек',
  income: ' Доход',
  quantity: 'Количество покупок',
  total_amount: 'Выручка',
  total_customers: 'Покупатели',
  total_count: 'Товары ',

  // Типы торговых точек
  extended_type: 'Тип',
  extended_types_store: 'Магазин самообслуживания',
  extended_types_refrigerator: 'Микромаркет',
  extended_types_store_online: 'Онлайн-витрина',
  extended_types_cafe: 'Предзаказ',
  extended_types_production_with_delivery: 'Производство с доставкой',
  extended_types_filling_station: 'АЗС',
  extended_types_cigarettes: 'Сигаретный шкаф',
  extended_types_coffee_machine: 'Кофемашина',
  extended_types_module: 'Модуль',
  store_count: 'Прикреплённые торговые точки',

  complete_registration: 'Для начала пользования Личным кабинетом пройдите регистрацию.',
  your_company_country: 'Страна Вашей компании',
  register: 'Зарегистрироваться',
  your_company_inn: 'ИНН вашей компании',
  your_company_number: 'Регистрационный номер вашей компании',
  check_email: 'Проверить E-mail',
  check: 'Проверить',

  // requisites status
  requisites_status_pending: 'Проверяются',
  requisites_status_active: 'Активны',
  requisites_status_uncompleted: 'Не заполнены',

  //write-offs status
  completed: 'Завершен',
  opened: 'Открыт',
  cancelled: 'Отменен',

  // integrations
  import_excel_file: 'Загрузить .xlsx файл',
  one_s: '1С',
  iiko: 'Айко',
  iiko_biz: 'Айко Биз',
  yml: 'YML',
  moy_sklad: 'Мой Склад',
  supermag: 'Супермаг',
  mts_kassa: 'МТС Касса',
  store_house: 'StoreHouse',
  poster: 'Poster',
  ya_badge: 'Яндекс.Бейдж',

  article: 'Артикул',
  name: 'Название',
  price: 'Цена',
  barcode: 'Штрихкод',
  unit_id: 'ОКЕИ (Ед. измерения)',
  volume: 'Объем',
  category: 'Категория',
  catalog_name: 'Каталог',

  //notifications
  Success_notification: 'Успешно!',
  Error_notification: 'Ошибка!',

  //tp detial notifications
  temperature: 'Уведомления об изменении температуры',
  connection: 'Уведомления о состоянии соединения',

  device_type_camera: 'Камера',
  device_type_coffee_machine: 'Кофемашина',
  device_type_microwave_oven: 'Микроволновая печь',
  device_type_module: 'Модуль',
  device_type_terminal: 'Терминал',

  tax_system_envd: 'ЕНВД',
  tax_system_esn: 'ЕСХН',
  tax_system_npd: 'НПД',
  tax_system_general: 'ОСН',
  tax_system_patent: 'Патент',
  tax_system_simplified_1: 'УСН (доходы, ставка 6%)',
  tax_system_simplified_2: 'УСН (доходы - расходы, ставка 15%)',

  role_admin: 'Администратор',
  role_checker: 'Чек-ер',
  role_courier: 'Курьер',
  roles: 'Роли',

  error_1000: 'Не правильная ссылка',
  error_1010: 'Не правильная ссылка',
  error_1020: 'Не правильная ссылка',
  error_1030: 'Не правильная ссылка',
  error_1040: 'Не правильная ссылка',
  error_1050: 'Не правильная ссылка',

  report_type_order_stats: 'Статистика по заказам',
  report_type_sale_stats: 'Статистика по продажам',
  report_type_item_stats: 'Статистика по товарам',
  report_type_customer_stats: 'Статистика по клиентам',
  report_type_sales_report: 'Отчет по продажам',
  report_type_promocodes_report: 'Отчет по промокодам',
  report_type_deliveries_report: 'Отчет по доставкам',
  reports_columns_item_price: 'Цена товара',
  reports_columns_item_orders: 'Заказы',
  reports_columns_id: 'ID',

  options_status_ACTIVE: 'Ожидает оплаты',
  options_status_PAID: 'Оплачен',
  options_status_PROCESSED: 'Обработан',
  options_status_REFUND: 'Возвращен',
  options_status_REFUND_AWAITING: 'Ожидается возврат',
  options_status_REFUND_ERROR: 'Ошибка возврата',
  options_status_REFUND_PARTIAL: 'Частично возвращен',
  options_status_REMOVED: 'Удален',

  options_type_BASE: 'Базовый',
  options_type_DECREASE: 'Возврат',
  options_type_INCREASE: 'Доплата',

  options_grouping_DAYS: 'День',
  options_grouping_DAYS_OF_WEEK: 'Дни недели',
  options_grouping_DAYS_OF_WEEK_AND_HOURS: 'Дни недели и часы',
  options_grouping_HOURS: 'Часы',
  options_grouping_WEEKS: 'Неделя',
  options_grouping_MONTHS: 'Месяц',
  options_grouping_YEARS: 'Год',
  options_grouping_NONE: 'Не группировать',

  options_internal: 'Собственная доставка',
  options_null: 'Неизвестно',
  options_payment_service_APP: 'Приложение',
  options_payment_service_TERMINAL: 'Терминал',
  options_payment_service_YANDEX_BADGE: 'Яндекс Бейдж',

  reports_columns_amount: 'Сумма со скидкой',
  reports_columns_base_amount: 'Сумма',
  reports_columns_commission: 'Комиссия',
  reports_columns_count: 'Количество',
  reports_columns_customer_count: 'Количество клиентов',
  reports_columns_customers_count: 'Количество клиентов',
  reports_columns_customer_id: 'ID клиента',
  reports_columns_delivery_price_to_pay: 'Стоимость доставки',
  reports_columns_delivery_price: 'Цена доставки',
  reports_columns_delivery_progress_status: 'Статус доставки',
  reports_columns_external_service: 'Тип доставки',
  reports_columns_hash_id: 'Заказ №',
  reports_columns_income: 'Поступления',
  reports_columns_item_barcode: 'ШК товара',
  reports_columns_barcode: 'ШК товара',
  reports_columns_item_count: 'Кол-во товаров',
  reports_columns_item_hash_id: 'ID товара',
  reports_columns_hash: 'ID товара',
  reports_columns_item_name: 'Название товара',
  reports_columns_name: 'Название товара',
  reports_columns_payment_service: 'Тип оплаты',
  reports_columns_period: 'День',
  reports_columns_promo_code: 'Промокод',
  reports_columns_quantity: 'Количество товара',
  reports_columns_refund_amount: 'Возврат',
  reports_columns_status_refund: 'Статус возврата',
  reports_columns_status: 'Статус заказа',
  reports_columns_store_id: 'ID торговой точки',
  reports_columns_timestamp_inserting: 'Дата и время заказа',
  reports_columns_total_amount: 'Сумма со скидкой',
  reports_columns_total_base_amount: 'Базовая стоимость',
  reports_columns_price: 'Базовая стоимость',
  reports_columns_sum_amount: 'Продажи',
  reports_columns_order_id: 'ID заказа',
  total_name: 'Итого',
  components_list: 'Список',

  // supress annoying warnings
  undefined_CODE: '',
  ' шт': ' шт',

  home: 'Главная',
  Goods: 'Товары',
  Payment: 'Оплаты',
  Reports: 'Отчеты',
  Outlets: 'Торговые точки',
  Warehouse: 'Склад',
  Leftovers: 'Остатки',
  Deliveries: 'Поставки',
  Movements: 'Перемещения',
  'Write-offs': 'Списания',
  'Traffic report': 'Отчет по движению',
  'Catalogs and categories': 'Каталоги и категории',
  Payments: 'Платежи',
  Acquiring: 'Эквайринг',
  Operations: 'Операции',
  Accounts: 'Счета',
  Refunds: 'Возмещения',
  Marketing: 'Маркетинг',
  Discounts: 'Скидки',
  Documentation: 'Документация',
  'The documents': 'Документы',
  Equipment: 'Оборудование',
  Buy: 'Купить',
  Settings: 'Настройки',
  'Legal entities': 'Юридические лица',
  Employees: 'Сотрудники',
  'User profile': 'Профиль пользователя',
  'Download .xlsx': 'Скачать .xlsx',
  Hide: 'Свернуть',
  Show: 'Развернуть',
  'To set the parameters, first select the type of report.': 'Чтобы установить параметры сначала выберите тип отчёта.',
  'To generate a report, you must select the type and set the parameters.':
    'Для формирования отчёта необходимо выбрать тип и задать параметры.',
  'Generate report': 'Сформировать отчёт',
  all: 'Все',
  active: 'Активные',
  inactive: 'Неактивные',
  carts_employee_fio: 'ФИО Сотрудника',
  carts_period_start: 'Дата начала',
  carts_period_end: 'Дата конца',
  carts_link_status: 'Статус ссылки',
  carts_link: 'Ссылка',
  carts_delete_link: ' ',

  carts_active: 'Собирается',
  carts_inactive: 'Отменён',
  carts_on_hold: 'В ожидании',
  carts_paid: 'Оплачен',
  carts_removed: 'Отменен',

  carts_link_active: 'Активна',
  carts_link_inactive: 'Неактивна',

  carts_created: 'Cоздан:',
  carts_timer: 'Собирается:',
  carts_was_assembled: 'Собирался',
  carts_items: 'Товаров:',
  carts_total_summ: 'Сумма:',

  active_BAR: 'Активные корзины',
  on_hold_BAR: 'Корзины в ожидании',
  removed_BAR: 'Отменённые корзины',

  options_dostavista: 'Dostavista',
  options_gett: 'Gett',

  MONTH_01: 'Январь',
  MONTH_02: 'Февраль',
  MONTH_03: 'Март',
  MONTH_04: 'Апрель',
  MONTH_05: 'Май',
  MONTH_06: 'Июнь',
  MONTH_07: 'Июль',
  MONTH_08: 'Август',
  MONTH_09: 'Сентябрь',
  MONTH_10: 'Октябрь',
  MONTH_11: 'Ноябрь',
  MONTH_12: 'Декабрь',

  WEEKDAY_01: 'Понедельник',
  WEEKDAY_02: 'Вторник',
  WEEKDAY_03: 'Среда',
  WEEKDAY_04: 'Четверг',
  WEEKDAY_05: 'Пятница',
  WEEKDAY_06: 'Суббота',
  WEEKDAY_07: 'Воскресенье',

  event_store_id: 'ID торговой точки',
  event_store_name: 'Торговая точка',
  event_created_at: 'Cтарт сессии',
  event_status: 'Статус',
  event_customer_id: 'ID Клиента',

  event_create: 'Старт сессии',
  event_create_position: 'Добавлен товар',
  event_delete_position: 'Удален товар',
  event_decrease_position: 'Удален товар',
  event_increase_position: 'Добавлен товар',
  event_delete: 'Отмена заказа',
  event_paid: 'Оплата',
  event_refund: 'Возврат',

  unknown: 'Другие уведомления',
  camera: 'Уведомления с камеры',

  not_found_text: 'Упс! Что-то пошло не так...',
  back_to_home: 'Вернуться на главную',
  support_service_greeting: 'Привет! Чем можем помочь?',

  week: 'Неделя',
  month: 'Месяц',
  three_months: '3 месяца',
  six_months: '6 месяцев',

  sum_orders_amount: 'По выручке',
  sum_orders_count: 'По заказам',
  sum_items_count: 'По товарам',
  is_only_my_store: 'Мои микромаркеты',
  period: 'Период',
  main_rate: 'Основной показатель',
  region: 'Регион',
  currency: 'Валюта',
  vat_rate: 'НДС (%)',
  'Wrong time value': 'Неверно указано время',
  "You can't activate the ad because it doesn't have an image": 'Для активации рекламы необходимо загрузить изображение',
  'Invalid argument schedule.type': 'Ошибка в расписании показа вашей рекламы',

  validation: {
    required: 'Обязательное поле',
    minLength: 'Минимальная длина — {0} символов',
    maxLength: 'Максимальная длина — {0} символов',
    invalidNumeric: 'Присутствуют недопустимые символы',
    invalidEmail: 'Некорректный адрес',
    invalidPhone: 'Некорректный номер телефона',
  },

  brisklyModuleActivated: 'Briskly Модуль № {0} привязан к торговой точке и готов к работе',
};
