// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
  {
    label: i18n.t('Отчёты по движению'),
    route: { name: 'storage-reports$storage-reports' },
  },
  {
    label: i18n.t('Создать отчет по движению'),
    route: { name: 'storage-reports$storage-reports-create' },
  },
];

export default [
  {
    path: '/storage/reports',
    name: 'storage-reports$storage-reports',
    component: () => import('@/apps/storage-reports/views/StorageReports'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Отчёты по движению'),
    },
  },
  {
    path: '/storage/reports/create',
    name: 'storage-reports$storage-reports-create',
    component: () => import('@/apps/storage-reports/views/Create/Create.vue'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Отчёты по движению'),
    },
  },
  {
    path: '/storage/reports/report/:id/:isNew',
    name: 'storage-reports$report',
    component: () => import('@/apps/storage-reports/views/Report/Report.vue'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Отчёты по движению'),
    },
  },
  {
    path: '/storage/report-detail/:id',
    name: 'storage-reports$detail',
    component: () => import('@/apps/storage-reports/views/Detail/Detail'),
    meta: {
      breadCrumb: breadCrumb.slice(0, 1),
      title: i18n.t('Склад / Отчет :id'),
    },
  },
];
