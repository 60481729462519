export default [
  {
    title: 'Главная',
    route: { name: 'main' },
    icon: 'graph',
  },
  {
    title: 'Торговые точки',
    route: { name: 'tradepoints$list' },
    icon: 'dot',
  },
  {
    title: 'Товары',
    route: { name: 'items$items' },
    icon: 'food',
  },
  {
    title: 'Меню',
    route: { name: 'menus$menus' },
    icon: 'icon_list',
  },
  {
    title: 'Оплаты',
    route: { name: 'orders$orders' },
    icon: 'barcode',
  },
  {
    title: 'Отчеты',
    route: { name: 'reports$list' },
    icon: 'icon_flag',
  },
  {
    title: 'Телеметрия',
    route: { name: 'equipment$equipment' },
    icon: 'icon_telemetry',
  },
  {
    title: 'Склад',
    icon: 'icon_storage',
    children: [
      {
        title: 'Каталоги и категории',
        route: { name: 'catalogs-categories$catalogs-categories' },
      },
      {
        title: 'Остатки',
        route: { name: 'remains$remains' },
      },
      {
        title: 'Поставки',
        route: { name: 'supply$supply' },
      },
      {
        title: 'Перемещения',
        route: { name: 'transfers$transfers' },
      },
      {
        title: 'Списания',
        route: { name: 'write-offs$write-offs' },
      },
      {
        title: 'Отчет по движению',
        route: { name: 'storage-reports$storage-reports' },
      },
    ],
  },
  {
    title: 'Маркетинг',
    icon: 'icon_percent',
    children: [
      {
        title: 'Скидки',
        route: { name: 'discounts$discounts' },
      },
      {
        title: 'Реклама на терминале',
        route: { name: 'terminal-promo$terminal-promo' },
      },
    ],
  },
  {
    title: 'Сервисный центр',
    icon: 'service',
    children: [
      {
        title: 'Диагностика',
        route: { name: 'service-diagnostics$diagnostics' },
      },
    ],
  },
  {
    title: 'Платежи',
    icon: 'icon_cash',
    children: [
      {
        title: 'Эквайринг',
        route: { name: 'shops$list' },
      },
      {
        title: 'Операции',
        route: { name: 'operations$list' },
      },
      {
        title: 'Счета',
        route: { name: 'bills$list' },
      },
      {
        title: 'Возмещения',
        route: { name: 'refunds$list' },
      },
    ],
  },
  {
    title: 'Документы',
    route: { name: 'documents$documents' },
    icon: 'icon_docs',
  },
  {
    title: 'Каталог Briskly',
    route: { name: 'devices$buy' },
    icon: 'icon_chip',
  },
  {
    title: 'Маркетплейс',
    route: { name: 'marketplace' },
    icon: 'store',
  },
  {
    title: 'Настройки',
    icon: 'gear',
    children: [
      {
        title: 'Виртуальные терминалы',
        route: { name: 'entities$list' },
        init_key: 'entities',
      },
      {
        title: 'Сотрудники',
        route: { name: 'terminals$list' },
        init_key: 'terminals',
      },
      {
        title: 'Профиль пользователя',
        route: { name: 'employees$employees' },
      },
      {
        title: 'Настройки',
        route: { name: 'profile$profile' },
      },
    ],
  },
  {
    title: 'Вопросы и ответы',
    route: { name: 'help$faq' },
    icon: 'icon_help',
  },
];
