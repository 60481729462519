import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-57ea0642")
const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "tangerine-counter"
}
const _hoisted_3 = {
  key: 1,
  class: "deer-list"
}
const _hoisted_4 = {
  key: 0,
  class: "deer-left"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snowflakes, (snowflake) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "snowflake",
          style: _normalizeStyle(_ctx.snowflakeCss(snowflake)),
          key: snowflake.id,
          onClick: ($event: any) => (_ctx.handleClick(snowflake))
        }, _toDisplayString(snowflake.text), 13, _hoisted_1))
      }), 128))
    ]),
    (_ctx.tangerineQuest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.tangerineClick) + " 🍊", 1))
      : _createCommentVNode("", true),
    (_ctx.deerQuest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.deerCatched.length < _ctx.deerNames.length)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.deerNames.length - _ctx.deerCatched.length) + " 🦌 left", 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deerCatched.sort(), (deer) => {
            return (_openBlock(), _createElementBlock("span", { key: deer }, _toDisplayString(deer) + " 🦌", 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}