import { en1 } from './en1';
/*

cat translations.csv | ./scripts/generate_locale_dictionaries.php "ru1,en1"

for google table transation script

*/
export const en = {
  ...en1,
  briskly_requisites: 'Briskly requisites',
  main_data: 'Main information',
  unit_796: 'Piece',
  unit_657: 'Item',
  unit_111: 'Milliliter',
  unit_112: 'Liter',
  unit_163: 'Gram',
  unit_625: 'Sheet',
  unit_704: 'Set',
  unit_715: 'Pair',
  unit_166: 'Kilogram',
  unit_736: 'Roll',
  unit_778: 'Package',
  documents_tabs_docs: 'Documents',
  documents_tabs_acts: 'Acts',
  documents_tabs_bills: 'Bills',
  documents_tabs_payment: 'Payment',
  documents_requisites: 'Requisites',
  documents_type: 'Type',
  documents_added: 'Added',
  documents_is_signed: 'Signed',
  documents_document_fallback_name: 'No name',
  documents_document_fallback_type: 'No type',
  documents_document_fallback_id: 'No id',
  documents_document_fallback_requisites: 'No requisites',
  Invoice: 'Invoice',
  UpdStatus1: 'Universal transfer document',
  ActForTheReportingPeriod1: 'Act for the reporting period',
  ActForTheReportingPeriod2: 'Settlement statement for the reporting period',
  OrdersReport1: 'Sales report',
  BYN: 'Belarusian ruble (BYN)',
  USD: 'US dollar (USD)',
  EUR: 'Euro (EUR)',
  KZT: 'Kazakh tenge (KZT)',
  CAD: 'Canadian dollar (CAD)',
  CNY: 'Chinese yuan (CNY)',
  RUB: 'Russian ruble (RUB)',
  TRY: 'Turkish lira (TRY)',
  UAH: 'Ukrainian hryvnia (UAH)',
  GBP: 'Pound Sterling (GBP)',
  CHF: 'Swiss franc (CHF)',
  HRK: 'Croatian kuna (HRK)',
  AED: 'Emirati dirham (AED)',

  BYN_CODE: '₽',
  USD_CODE: '$',
  EUR_CODE: '€',
  KZT_CODE: '₸',
  CAD_CODE: '$',
  CNY_CODE: '¥',
  RUB_CODE: '₽',
  TRY_CODE: '₺',
  UAH_CODE: '₴',
  GBP_CODE: '£',
  CHF_CODE: '₣',
  HRK_CODE: 'kn',
  AED_CODE: 'DH',

  WEEKDAY_01: 'Monday',
  WEEKDAY_02: 'Tuesday',
  WEEKDAY_03: 'Wednesday',
  WEEKDAY_04: 'Thursday',
  WEEKDAY_05: 'Friday',
  WEEKDAY_06: 'Saturday',
  WEEKDAY_07: 'Sunday',

  datetimeCreatedAt: 'Creation date and time',
  customer: 'Customer',
  status: 'Status',
  store: 'Supermarket',
  refrigerator: 'Micromarket',
  store_online: 'Online store',
  cafe: 'Pre-order',
  production_with_delivery: 'Production with delivery',
  filling_station: 'Gas station',
  cigarettes: 'Cigarettes',
  coffee_machine: 'Coffee machine',
  address: 'Address',
  phone: 'Phone',
  active_status: 'Awaiting payment',
  paid_status: 'Paid',
  refund_status: 'Returned',
  refund_awaiting_status: 'Return expected',
  refund_error_status: 'Return error',
  refund_partial_status: 'Partially refunded',
  removed_status: 'Removed',
  avg_amount: 'Average bill',
  income: 'Income',
  quantity: 'Number of purchases',
  total_amount: 'Revenue',
  extended_type: 'Type',
  extended_types_store: 'Store',
  extended_types_refrigerator: 'Micromarket',
  extended_types_store_online: 'Online store',
  extended_types_cafe: 'Cafe',
  extended_types_production_with_delivery: 'Delivery',
  extended_types_filling_station: 'Gas station',
  extended_types_cigarettes: 'Cigarettes',
  extended_types_coffee_machine: 'Coffee machine',
  extended_types_module: 'Module',
  store_count: 'Attached stores',
  complete_registration: 'To start using your Personal Account please sign up.',
  your_company_country: 'Country of your company',
  register: 'Sign up',
  your_company_inn: 'TIN of your company',
  your_company_number: 'Your company registration number',
  requisites_status_pending: 'Checked',
  requisites_status_active: 'Active',
  requisites_status_uncompleted: 'Not filled',
  completed: 'Completed',
  opened: 'Opened',
  import_excel_file: 'Upload .xlsx file',
  one_s: '1C',
  iiko: 'Айко',
  iiko_biz: 'Айко Биз',
  yml: 'YML',
  moy_sklad: 'Мой Склад',
  supermag: 'Супермаг',
  mts_kassa: 'МТС Касса',
  store_house: 'StoreHouse',
  poster: 'Poster',
  ya_badge: 'Яндекс.Бейдж',
  article: 'Vendor code',
  name: 'Name',
  price: 'Price',
  barcode: 'Barcode',
  unit_id: 'ОКЕИ (Ед. измерения)',
  volume: 'Volume',
  category: 'Category',
  catalog_name: 'Catalog',
  Success_notification: 'Success!',
  Error_notification: 'Error!',
  temperature: 'Temperature change notifications',
  connection: 'Connection status notifications',
  device_type_camera: 'Camera',
  device_type_coffee_machine: 'Coffee machine',
  device_type_microwave_oven: 'Microwave',
  device_type_module: 'Module',
  device_type_terminal: 'Terminal',
  tax_system_envd: 'ЕНВД',
  tax_system_esn: 'ЕСХН',
  tax_system_npd: 'НПД',
  tax_system_general: 'ОСН',
  tax_system_patent: 'Patent',
  tax_system_simplified_1: 'УСН (доходы ставка 6%)',
  tax_system_simplified_2: 'УСН (доходы - расходы ставка 15%)',
  role_admin: 'Administrator',
  role_checker: 'Checker',
  role_courier: 'Courier',
  roles: 'Roles',
  error_1000: 'Incorrect link',

  error_1010: 'Incorrect link',
  error_1020: 'Incorrect link',
  error_1030: 'Incorrect link',
  error_1040: 'Incorrect link',
  error_1050: 'Incorrect link',
  components_list: 'List',

  /* reports translation */
  report_type_order_stats: 'Order statistics',
  report_type_sale_stats: 'Sale statistics',
  report_type_item_stats: 'Item statistics',
  report_type_customer_stats: 'Customer statistics',
  report_type_sales_report: 'Sales report',
  report_type_promocodes_report: 'Promocodes report',
  report_type_deliveries_report: 'Deliveries report',
  reports_columns_item_price: 'Item price',
  Отчёт: 'Report',
  'Параметры отчёта': 'Report parameters',
  'Скачать .xlsx': 'Download .xlsx',
  'Скачать .csv': 'Download .csv',
  'Всего:': 'Total:',
  Свернуть: 'Hide',
  Развернуть: 'Show',
  'Чтобы установить параметры сначала выберите тип отчёта.': 'To set the parameters, first select the type of report.',
  'Для формирования отчёта необходимо выбрать тип и задать параметры.':
    'To generate a report, you must select the type and set the parameters.',
  'Сформировать отчёт': 'Generate report',
  tradePoint_status_active: 'Active',
  filters: 'Filters',
  unit: 'unit',

  undefined_CODE: '',
  ' шт': ' шт',

  reports_columns_amount: 'Amount with discount',
  reports_columns_base_amount: 'Sum',
  reports_columns_commission: 'Commission',
  reports_columns_count: 'Quantity',
  reports_columns_customer_count: 'Number of clients',
  reports_columns_customers_count: 'Number of clients',
  reports_columns_customer_id: 'Client ID',
  reports_columns_delivery_price_to_pay: 'Delivery price to pay',
  reports_columns_delivery_price: 'Delivery price',
  reports_columns_delivery_progress_status: 'Delivery status',
  reports_columns_external_service: 'Delivery type',
  reports_columns_hash_id: 'Order №',
  reports_columns_income: 'Income',
  reports_columns_item_barcode: 'Item barcode',
  reports_columns_barcode: 'Item barcode',
  reports_columns_item_count: 'Item quantity',
  reports_columns_item_hash_id: 'Item ID',
  reports_columns_hash: 'Item ID',
  reports_columns_item_name: 'Item name',
  reports_columns_payment_service: 'Payment type',
  reports_columns_period: 'Day',
  reports_columns_promo_code: 'Promo code',
  reports_columns_quantity: 'Item quantity',
  reports_columns_refund_amount: 'Refund',
  reports_columns_status_refund: 'Refund status',
  reports_columns_status: 'Order status',
  reports_columns_store_id: 'Store ID',
  reports_columns_timestamp_inserting: 'Order time',
  reports_columns_total_amount: 'Total amount',
  reports_columns_total_base_amount: 'Base amount',
  reports_columns_price: 'Price',
  reports_columns_sum_amount: 'Sales',
  reports_columns_order_id: 'Order ID',
  reports_columns_item_orders: 'Orders',
  reports_columns_id: 'ID',
  total_name: 'Total',
  'Для дальнейшего использования реквизиты должны быть верно заполнены и проверены нашим специалистом. Вы можете':
    'For further use requisites should be correctly filled and checked by our specialist. You can',
  'недостающие данные или вернуться к этому позже.': 'and come back later.',
  'Система налогообложения': 'Taxation system',
  'КПП:': 'KPP:',
  'ОГРН:': 'OGRN:',
  'ОКОПФ:': 'OKOPF:',
  'ОКВЭД:': 'OKVED:',
  'Здесь будет информация о перемещении ваших товаров из одной торговой точки в другую. Чтобы изменить область видимости товара,создайте перемещение':
    'Here will be information about the movement of your goods from one sales point to another. To change the product visibility area, create a move',
  'Подписать документ': 'Sign document',
  'Кол-во:': 'Quantity:',
  'Данные с выбранными параметрами не найдены': 'No data with chosen parameters',
  'Измените настройки фильтра': 'Please, change filter settings',
  'Поиск по таблице': 'Search...',
  'Кол-во': 'Quantity',
  'Добавить адрес': 'Add address',
  'Прикрепите устройства для новой торговой точки': 'Bind device for new store',
  Например: 'For example',
  'Briskly модуль': 'Briskly module',
  'поможет вам со статистикой': 'will help with statistics',
  options_grouping_DAYS: 'By days',
  options_grouping_WEEKS: 'By weeks',
  options_grouping_DAYS_OF_WEEK_AND_HOURS: 'By days of week and hours',
  options_grouping_DAYS_OF_WEEK: 'By days of week',
  options_grouping_HOURS: 'By hours',
  options_grouping_MONTHS: 'By months',
  options_grouping_YEARS: 'By years',
  options_grouping_NONE: 'None',
  options_payment_service_APP: 'Via app',
  options_payment_service_TERMINAL: 'Via terminal',
  options_payment_service_YANDEX_BADGE: 'Via Yandex Badge',
  options_type_BASE: 'Base',
  options_type_INCREASE: 'Increase',
  options_type_DECREASE: 'Decrease',
  options_status_PAID: 'Paid',
  options_status_PROCESSED: 'Processed',
  options_status_REFUND: 'Refund',
  options_status_REFUND_AWAITING: 'Refund processed',
  options_status_REFUND_ERROR: 'Refund error',
  options_status_REFUND_PARTIAL: 'Partial refund',
  options_status_ACTIVE: 'Active',
  options_status_REMOVED: 'Removed',
  Отсутствует: 'None',
  'По всем': 'All',
  'Все клиенты': 'All clients',
  Активный: 'Active',
  Неактивный: 'Inactive',
  'Перемещение №': 'Movement #',
  'В данном разделе вы сможете формировать различные отчёты по движению товаров с заданными параметрами, а так же скачать полученные данные в форматах .csv и .xlsx':
    'Here you can generate various item movement reports with chosen parameters and download received data in .csv and .xlsx format',
  'Отчёты по движению отсутствуют': 'No movement report',
  'Вы можете привязать каталог к нескольким торговым точкам, для этого воспользуйтесь “+” для добавления дополнительного поля выбора торговой точки':
    'You can bind catalog to multiple stores by clicking "+" button for adding extra store choosing field',
  'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах':
    'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix',
  'Доступные префиксы — от 20 до 29': 'Availible prefixes are from 20 to 29',
  'Если префикс не указан, то товары будут обработаны, как штучные': 'If there is no prefix, items will be considered as singles',
  'В данном разделе будут отображены все скидки на товары и категории товаров, настроенные при их создании и редактировании':
    'There will be all discounts for items and item categories configurated during their creation and editing',
  'Вы сможете настроить уже созданную ранее скидку или ': 'You can configure already created discount or ',
  'и прикрепить её к тому или иному товару или категории товаров.': 'and bind it to any item or item category.',
  'Так же вы можете': 'Also you can',
  ', это комплекс скидок, который вы можете применить ко всему заказу целиком в одной или нескольких торговых точках по заданным параметрам.':
    ", it's a number of discounts which you can bind to whole order or multiple stores by chosen parameters.",
  'При регистрации в систему Briskly Business, происходит автоматическое создание сотрудника с указанным вами номером телефона и присваивается роль Администратор, что даёт вам полный доступ ко всему функционалу Личного кабинета':
    'After signing up in Briskly business system, an employee with selected phone number is automatically created with the role of Administrator which gives access to full functionality of the whole service',
  'В данном разделе вы можете создавать и удалять сотрудников, редактировть их данные, присваивать им роли в соответствии с их обязанностями и закреплять за теми или иными торговыми точками':
    'There you can create and delete employees, edit their data, give them roles according to their position and bind them to differens stores',
  'Как только в какой то из ваших торговых точек будет создана первая оплата, вся информация о ней(состав, стоимость, статус и т.д.) будет размещена в данном разделе':
    'Whenever a new payment will be crated in any of the stores, all its info will be displayed here',
  'Вы сможете просмотреть информацию отфильтровав по: дате и времени создания оплаты, ее статусу и другим параметрам':
    'You can view info here by filtering by payment creation date and time, its status and other parameters',
  'Для торговых точек типа микромаркет доступен просмотр видео к оплатам':
    'For stores with micromarket type payment video capture is availible.',
  'Также в данном разделе Вы можете осуществлять возврат денежных средств клиенту в случае необходимости':
    'Also, ypu can do refunds if necessary',
  'Доступна возможность открывать микромаркет, менять товар, делать фото, загружать фото в интерфейс курьера.':
    "Availible to open micromarkets, change items, take and upload photos into courier's interface.",
  'Минимальный размер и формат': 'Minimal size and format',
  товары: 'Items',
  ' шт.': ' pcs.',
  январь: 'January',
  февраль: 'February',
  март: 'March',
  апрель: 'April',
  май: 'May',
  июнь: 'June',
  июль: 'July',
  август: 'August',
  сентябрь: 'September',
  октябрь: 'October',
  ноябрь: 'November',
  декабрь: 'December',
  'После сохранения данные вашего виртуального терминала будут отправлены на проверку':
    'After saving your terminals data will be verified',
  'После сохранения данные вашего виртуального терминала будут отправлены на проверку.':
    'After saving your terminals data will be verified.',
  'Информация об изменении статуса нового виртуального терминала будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'Status change information will be sent in push notification and also will be displayed in a card on the page',
  Сотрудники: 'Employees',
  'Уставный капитал:': 'Authorized capital:',
  'ИНН:': 'INN',
  'Тел:': 'Tel:',
  'Р/с №:': 'R/s #:',
  'БИК:': 'BIK:',
  'Деактивировать торговую точку': 'Deactivate store',
  'Введите кол-во...': 'Enter quantity...',
  Распечатать: 'Print',
  'Внимание!': '',
  'Вы уверены, что хотите покинуть страницу? Все несохранённые данные будут утеряны.': '',
  'Кол-во наклеек': '# of stickers',
  'Будет сгенерирован при сохранении...': 'Will be generated after save...',
  'Внешний id': 'External id',
  'В настройках торговой точки вы можете настроить расписание работы, включить/отключить уведомления, а также назначить сотрудников.':
    'You can configure schedule, turn notifications on and off and assign employees in store settings.',
  'Ввести идентификатор': 'Enter id',
  'Данный номер телефона будет использован в чеках оплаты приложения B-Pay.': 'This phone number will be used in B-Pay app receipts.',
  'Минимальная длина': 'Minimum length is',
  'Максимальная длина': 'Maximum length is',
  символов: 'symbols',
  'Присутствуют недопустимые символы': 'Input contains unacceptable symbols',
  'Обязательное поле': 'Required field',
  'Не валидный телефон': 'Invalid number',
  'Не валидный email': 'Invalid email',
  'Не валидная дата': 'Invalid date',
  'История обновлений': 'Update history',
  'Внимание! Категория должна быть привязана к какому либо каталогу': 'Attention! Category must be bound to a catalog',
  'Категория может быть прикреплена только к одному каталогу': 'A category can be bound to only one catalog',
  'Создайте новую категорию  и прикрепите её к каталогу': 'Create a new category and bind it to a catalog',
  'Вы также можете пропустить этот шаг чтобы вернуться к нему позже из экрана каталога':
    'You can skip this step to return to it later from catalog screen',
  'Вы собираетесь удалить категорию Внимание! Удаление категории повлечет удаление всех товаров в этой категории':
    'You are going to delete a category Attention! Deleting category will result in all category items deletion',
  'Вы уверены, что хотите покинуть страницу ? Все несохранённые данные будут утеряны.':
    'Are you sure you want to leave this page? All unsaved data will be lost',
  'Столбцы «Название», «Цена» и «Штрихкод» являются обязательными.': '"Name", "Price" and "Barcode" columns are required.',
  'Столбцы «Название», «Цена» и «Штрихкод» являются обязательными': '"Name", "Price" and "Barcode" columns are required',
  'Вы уверены что хотите убрать товар из категории?': 'Are you sure you want to remove item from category?',
  'Прикреплённые торговые точки': 'Bound stores',
  'Прикреплённые категории': 'Bound categories',
  'Все каталоги': 'All catalogs',
  'Для формирования отчёта по движению товаров необходимо задать следующие параметры: период времени, выбрать одну  или несколько торговых точек и  товар(ы), движение по которым вас интересует':
    'To generate a report on the movement of goods, you must set the following parameters: time period, select one or more outlets and product(s), the movement of which you are interested.',
  'Загрузить .xlsx файл': 'Upload an .xlsx file',
  'По значению этого префикса система распознаёт весовой товар с выделением цифрового кода товара и его веса в граммах. Доступные префиксы — от 20 до 29. Если префикс не указан, то товары будут обработаны, как штучные.':
    'System will identify weight item by highlighting the digital code and its weight in grams by using this prefix. Availible prefixes are from 20 to 29. If there is no prefix, items will be considered as singles',
  Неизвестно: 'Unknown',
  'Устройство не найдено. Проверьте корректность введенного кода и повторите попытку':
    'Device not found. Check whether the entered code is correct and try again',
  'Выберите один или несколько каталогов из списка, чтобы привязать их к складу вашей торговой точки. Либо':
    "Choose one ore more catalogs from the list to bind them to your store's warehouse, or",
  'и заполните его товарами.': 'and fill it with items.',
  'Выберите формат в котором будет экспортирован список отчетов': 'Choose format for exporting reports list',
  'Выберите формат в котором будет экспортирован список товаров': 'Choose format for exporting items list',
  'Выберите формат в котором будет экспортирован список оплат': 'Choose format for exporting payments list',
  'Возрастное ограничение категории 18+': '18+ age limit',
  'Новая категория': 'New category',
  'Перенести в категорию': 'Move to a category',
  'Перенести в каталог': 'Move to a catalog',
  'Экспортировать оплату': 'Export payment',
  'Стикер товара': 'Item sticker',
  'Всего товаров к возврату: ': 'Total items to return:',
  'Всего товаров к возврату ': 'Total items to return',
  списание: 'write-off',
  списания: 'write-offs',
  Экспортировать: 'Export',
  'Экспортировать торговую точку': 'Export store',
  'Удалить торговую точку? Это приведет к безвозвратному удалению':
    'Are you sure you want to delete a store? This will result in permanent deletion',
  'Удалить каталог': 'Delete catalog',
  'Вы собираетесь удалить каталог! Для удаления каталога необходимо привязать новый каталог ко всем торговым точкам взамен удаляемого.':
    'You are about to delete a catalog! To delete a catalog, you must bind a new catalog to all outlets in place of the one you are deleting.',
  'Экспортировать каталог': 'Export catalog',
  'Вы можете добавить сразу несколько категорий в один каталог, для этого воспользуйтесь “+” для добавления  дополнительного поля для ввода названия новой категории.':
    'You can add multiple categories in one catalog at once, use "+" for adding extra field for new category name.',
  'Данные сохранены.': 'Data has been saved',
  'Добавить товары в категорию?': 'Add items to a category?',
  'Категория будет создана, но нужно заполнить её товарами': 'Category will be created, but it has to be filled with items',
  'Добавить товар вручную': 'Add item by hand',
  'Прикрепить к торговым точкам': 'Bind to stores',
  'Категория успешно прикреплена.': 'Category successfully bound.',
  'Категория успешно обновлена': 'Category successfylly updated',
  'Категория обновлена': 'Category updated',
  'Категория успешно создана.': 'Category successfully created.',
  'Категория создана': 'Category created',
  'Категория успешно удалена.': 'Category successfully deleted.',
  'Категория удалена': 'Category deleted',
  'Успешно!': 'Success!',
  'успешно привязано к торговой точке': 'has been successfully linked to the point of sale',
  'Каталог не был обновлен.': 'The catalog was not updated.',
  'Каталог успешно обновлен.': 'The catalog has been successfully updated.',
  'Каталог успешно создан.': 'Catalog successfully created.',
  'Каталог обновлен': 'The catalog has been updated',
  'Каталог создан': 'Catalog created',
  'Торговые точки не были обновлены.': 'Trade points were not updated',
  'Торговые точки обновлены.': 'Trade points have been updated.',
  'Торговые точки успешно обновлены.': 'Trade points have been successfully updated.',
  'Торговые точки успешно прикреплены к каталогу.': 'Stores successfully bound to catalog',
  'Торговые точки прикреплены': 'Stores bound',
  'Профиль успешно изменен!': 'Profile successfully updated',
  'Файл успешно загружен!': 'File successfully uploaded',
  'Загрузка отменена': 'Upload cancelled',
  'Сохранение успешно завершено!': 'Saved successfully',
  Успех: 'Success',
  'Успех!': 'Success!',
  Внимание: 'Caution',
  'Создание скидки завершено!': 'Discount save finished',
  Документ: 'Document',
  'был успешно подписан!': 'was successfully signed!',
  'Документ успешно подписан!': 'Document signed successfully',
  'Реквизиты успешно добавлены': 'Requisites added successfully',
  'Загрузите фото!': 'Upload a photo!',
  'Вы не можете создать категорию без выбранного каталога!': 'You cannot create a category without a chosen catalog',
  'Вы не можете добавить больше 7-ми сопутствующих товаров': 'You cannot add more than 7 set items',
  'Товар создан!': 'Item created',
  'Меню создано!': 'The menu has been created!',
  'Меню удалено': 'Menu deleted',
  'Меню не было привязано': 'The menu wasn\'t attached',
  'Меню не было удалено': 'The menu was not deleted',
  'Данное фото уже было загружено!': 'This photo has already been uploaded',
  'Необходимо указать дату и время изготовления': 'Creation date and time is required',
  'Торговая точка успешно деактивирована': 'Store deactivated',
  'Торговая точка успешно активирована': 'Store activated',
  'Порядок напитков успешно изменен': 'Beverage list successfully changed',
  'Основные данные успешно сохранены!': 'Main data successfully saved!',
  'Настройки уведомлений успешно сохранены!': 'Notification settings saved successfully',
  'Настройки расписания успешно сохранены!': 'Schedule settings saved successfully',
  Создание: 'Creation',
  'успешно завершено': 'completed successfully',
  Редактирование: 'Editing',
  'Не выбран каталог в торговой точке!': 'No selected catalog in store!',
  'Ошибка при создании': 'Error in creating',
  'Выберите формат в котором будет': 'Select a format in which',
  'экспортировано перемещение': ' will the movement be exported',
  'экспортирован список перемещений': 'will the movement list be exported',
  'Экспорт перемещения': 'Movement export',
  Прикрепить: 'Bind',
  'Привязать к торговой точке': 'Bind to a store',
  'SWIFT / БИК:': 'SWIFT / BIK',
  'SWIFT / БИК': 'SWIFT / BIK',
  'Не заполнен': 'Not filled in',
  'Наименование банка': 'Bank name',
  'Наименование банка:': 'Bank name:',
  'Для активации торговой точки необходимо заполнить все данные в выбранных реквизитах. Вы можете':
    'In order to activate a store you should fill inn all fields in the chose requisites. You can ',
  'сделать это сейчас': 'do it now',
  'или позже из раздела': 'or later from',
  'Видео отсутствует': 'No video',
  'Сумма возврата': 'Refund amount',
  'Вы действительно хотите удалить товар?': 'Are you sure you want to delete item?',
  'Экспортировать товар': 'Export item',
  'Добавить товары': 'Add items',
  '№ Транзакции': 'Transaction #',
  '№ расчётного счёта': 'Current payment account #',
  'ID клиента': 'Client ID',
  'URL-адрес сайта': 'Site URL',
  ОСН: 'OSN',
  'УСН (доходы, ставка 6%)': 'USN (income, rate 6%)',
  'УСН (доходы - расходы, ставка 15%)': 'USN (income - expenses, rate 15%)',
  ЕНВД: 'UTII',
  ЕСХН: 'CAP',
  Патент: 'Patent',
  'Платёжная информация': 'Payment information',
  'После заполнения и сохранения недостающих данных, ваши реквизиты автоматически будут отправлены на проверку. Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'After entering and saving all missing data your requisites will be automatically sent for checking. Information about status change will be sent ina push message and will be displayed in a card on th page',
  'Доставка партнёрами Briskly': 'Delivery by Briskly partners',
  'Клиент сам заберёт заказ по указанному адресу': 'Client will get order in the specified address themself',
  'Печатать дополнительное описание товара': 'Print additional item description',
  'В этом разделе появятся акты, которые вы заключили с вашими контрагентами. ':
    'There will be acts which you have concluded with your counterparties. ',
  'Половину вверх': 'Half up',
  'Не подписан': 'Not signed',
  'Половину вниз': 'Half down',
  'Целиком вверх': 'Ceil',
  'Целиком вниз': 'Floor',
  'Если вам необходимо создать новый каталог перейдите по ссылке ': 'If you need to create a new catalog, follow the link: ',
  'Загрузить .xlsx': 'Upload .xlsx',
  'загрузить .xlsx файл': 'upload an .xlsx file',
  'сделать это вручную': 'do it by hand',
  'Настройте интеграцию со своей системой товароучёта и автоматически заполните склад товарами.':
    'Set up integration with your billing system and automatically fill your warehouse with goods.',
  'Фактический адрес:': 'Actual address',
  'За указанный период нет данных.Измените параметры отчета.': 'No data for selected period. Please, change report settings.',
  'Для корректного заполнения всех полей может потребоваться пригласить вашего технического специалиста':
    'Attention! For correct filling of all fields you may need to invite your technical specialist.',
  'Распечатать стикеры': 'Print stickers',
  'Экспорт всех товаров': 'Export all items',
  'По данному заказу ещё не было возвратов': 'There have been no returns for this order yet.',
  'Тип события': 'Event type',
  'Показать события': 'Show events',
  'Отчёт о событиях': 'Events report',
  'Чтобы отобразить события выберите нужные значения.': 'Choose needed values to show events.',
  'Движения товаров в корзинах': 'Item movements in carts',
  'Название отчёта': 'Report name',
  'Введите название вашего отчёта': 'Enter your report name',
  'Экспортировать отчет': 'Export report',
  'Вы действительно хотите удалить отчет?': 'Are you sure you want to delete this report?',
  'Вы можете прикрепить каталог к нескольким торговым точкам. Для этого отметьте нужные торговые точки в выпадающем окне.':
    'You can bind catalog to multiple stores. To do this you need to select needed stores in the dropdown window.',
  'ID и название торговой точки': 'Store ID and name',
  'Создать категорию': 'Create a category',
  'Категория прикреплена': 'Category bound',
  'Торговая точка успешно добавлена!': 'Store successfully added!',
  'Код страны': 'Country code',
  'Не валидный номер': 'Invalid number',
  'Информация об изменении статуса виртуального терминала будет отправлена в push-уведомлении, а также отобразится в карточке на странице':
    'Virtual terminal status change info will be sent in push-notification and also will be displayed on page',
  'Виртуальные терминалы': 'Virtual terminals',
  Логин: 'Login',
  Пароль: 'Password',
  'Название типа цен': 'Price type name',
  'Отправлять проведенные заказы': 'Send finished orders',
  'Разрешить загрузку товаров без штрихкодов (не рекомендуется, не будет работать система учета штрихкодов в приложениях).':
    'Allow uploading items without barcodes (not recommended, barcode accounting system in apps will not work )',
  'Идентификатор типа цен': 'Price type identifier',
  События: 'Events',
  'Страница события': 'Event page',
  'Всего товаров к возврату': 'Total items to return',
  '№ оплаты': 'Payment #',
  'Экспортировать товары': 'Export items',
  'Детальный обзор': 'Detailed review',
  Событие: 'Event',
  Товар: 'Item',
  Итог: 'Total',
  'Если вам необходимо создать новый отчёт с небольшими изменениями, вы можете внести их в данном разделе и сформировать отчёт заново с обновлёнными параметрами':
    'If you need to create a new report with slight changes, you can enter them here and generate new report with new parameters',
  'Отчетный период': 'Reporting period',
  'Адрес не указан': 'No address specified',
  Тел: 'Phone',
  'Р/с №': 'Account #',
  перемещение: 'movement',
  перемещения: 'movements',
  'ID тор. точки': 'Store ID',
  'Вы можете добавить сразу несколько категорий в один каталог, для этого воспользуйтесь для добавления дополнительного поля для ввода названия новой категории':
    'You can add multiple categories to one catalog by using an extra field for new category enter',
  'ID торговой точки': 'Store ID',
  'Экспортировать торговую ': 'Export ',
  точку: 'store',
  точки: 'stores',
  'Заполнить сейчас': 'Fill in now',
  поставку: 'delivery',
  поставки: 'deliveries',
  'Сумма:': 'Amount:',
  event_store_id: 'Store ID',
  event_store_name: 'Store name',
  event_created_at: 'Creation time',
  event_status: 'Status',
  event_customer_id: 'Customer ID',
  event_create: 'Creation',
  event_delete_position: 'Delete position',
  event_increase_position: 'Increase position',
  event_decrease_position: 'Decrease position',
  event_create_position: 'Add item',
  event_delete: 'Delete item',
  event_refund: 'Refund',
  'Время события': 'Event time',
  Стоимость: 'Price',
  минут: 'minute',
  сек: 'sec',
  Длительность: 'Length',
  Увеличение: 'Zoom',
  а: '',
  ы: 's',
  '': 's',
  'Успешно удалено': 'Deleted successfully',
  'Категория успешно активирована': 'Category successfully activated',
  'Категория успешно деактивирована': 'Category successfully deactivated',
  'Успешно перемещен в каталог': 'Successfully moved to catalog',
  'Успешно привязан': 'Successfully bound',
  'Успешно перемещено': 'Successfully moved',
  'Успешно убран из категории': 'Successfully removed from category',
  'Успешно сохранено': 'Successfully saved',
  'Ссылка успешно скопирована!': 'Link successfully copied',
  Успешно: 'Success',
  'Категория не пуста': 'Category is not empty',
  'Категории не были созданы': 'Categories were not created',
  'Категории не были удалены.': 'Categories were not deleted',
  'Категории созданы и успешно прикреплены к каталогу.': 'Categories have been created and successfully attached to the catalog.',
  'Категории созданы и прикреплены': 'Categories have been created and attached',
  'Категории удалены': 'Categories have been deleted',
  'Категории успешно удалены.': 'Categories have been successfully deleted.',
  Ошибка: 'Error',
  'Что то пошло не так, попробуйте перезагрузить страницу': 'Something went wrong, please, try to reload page',
  not_found_text: 'Oops! Something went wrong...',
  back_to_home: 'Back to home',
  'Данный заказ не оплачен': 'This order is not yet paid for',
  'Обратите внимание, что клиент добавлял товары в корзину, но не перешел к оплате.':
    "Client added items to cart, but didn't proceed to payment.",
  'Подробную информацию о действиях клиента можно получить во вкладке «Видео» или разделе «События»':
    "You can get detailed info in 'Video' or 'Events' tab",
  'В Архиве': 'In archive',
  support_service_greeting: 'Hi! How can we help?',
  'Удалить скидку': 'Removing discount',
  'Вы действительно хотите удалить скидку на товар?':'Do you really want to remove the discount on the product?',
  'Вы действительно хотите удалить скидку на категорию?': 'Do you really want to remove a discount on a category?',
  'Вы действительно хотите удалить набор скидок?': 'Do you really want to remove the discount set?',
  'Помощь':'Help',
  'Вопросы и ответы':'Faq',

  week: 'Week',
  month: 'Month',
  three_months: '3 Months',
  six_months: '6 Months',

  sum_orders_amount: 'By profit',
  sum_orders_count: 'By orders',
  sum_items_count: 'By items',
  is_only_my_store: 'My markets',
  period: 'Range',
  main_rate: 'Main rate',
  region: 'Region',
  currency: 'Currency',
  vat_rate: 'VAT rate (%)',

  'Печатать текущую дату': 'Print current date',
  'Печатать наименование производителя': 'Print manufacturer\'s name',
  'Разместить на листе формата А4': 'Place on an A4 sheet',

  validation: {
    required: 'Required field',
    minLength: 'Minimum length is {0} characters',
    maxLength: 'Maximum length is {0} characters',
    invalidNumeric: 'Invalid characters',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Invalid phone number',
  },

  brisklyModuleActivated: 'Briskly module № {0} is activated and ready for use',

  'Новый счёт на оплату': 'New invoice for payment',
  'Сумма платежа': 'Payment amount',
  'Успешно скопировано!': 'Successfully copied!',
  'Оплачен': 'Paid',
  'Подтвержден': 'Confirmed',
  'Отменен': 'Canceled',
  'В обработке': 'In process',
  '№ Счёта': 'Invoice #',
  'ч.': 'h.',
  'Активные': 'Active',
  'Архив': 'Archive',
  'Все корзины': 'All carts',
  'Оплаченные': 'Paid',
  'Отмененные': 'Canceled',
  'Возвращенные': 'Returned',
  'Обработанные': 'Processed',
  'В ожидании возврата': 'Awaiting return',
  'С ошибкой возврата': 'Return error',
  'С частичным возвратом': 'Partial return',
  'Новый заказ!': 'New order!',
  'шт.': 'pcs.',
  'Категории успешно прикреплены.': 'Categories successfully attached.',
  'Категории прикреплены': 'Categories attached',
  'Добавление категории': 'Add category',
  'Создание категории': 'Create category',
  'Префикс весового товара': 'Weight product prefix',
  'Выберите одну или несколько торговых точек из списка, чтобы добавить в неё товары из каталога': 'Select one or more points of sale from the list to add products from the catalog to it',
  'Прикрепить торговую точку': 'Attach point of sale',
  'Неактивна': 'Inactive',
  'Вы уверены, что хотите удалить данный каталог?': 'Are you sure you want to delete this catalog?',
  'Перейти к торговым точкам': 'Go to points of sale',
  'Подождите, происходит загрузка файла...': 'Please wait, file is loading...',
  'Загрузка .xslx файла': 'Uploading .xlsx file',
  'Для правильной загрузки каталога, соотнесите наши названия полей с тем, как они названы у вас': 'To correctly upload the catalog, align our field names with those used in your file',
  'Название файла': 'File name',
  'При обработке загруженного файла были обнаружены ошибки заполнения некоторых полей': 'Errors were found in some fields while processing the uploaded file',
  'Исправьте значения и загрузите файл снова': 'Correct the values and upload the file again',
  'Перейти к товарам': 'Go to products',
  'Каталог Briskly': 'Briskly catalog',
  'Без имени': 'Unnamed',
  'Не указан период': 'Period not specified',
  'Не активна': 'Inactive',
  'на товар': 'per product',
  'на категорию': 'per category',
  'на заказ (наборы скидок)': 'per order (discount sets)',
  'Введите ID или название категории': 'Enter category ID or name',
  'Не активен': 'Inactive',
  'Общая скидка': 'Total discount',
  'Первая покупка без учета времени': 'First purchase without time consideration',
  'Первая покупка после назначенного временного лимита': 'First purchase after the assigned time limit',
  'Редактировать скидку': 'Edit discount',
  'Скидка успешно обновлена!': 'Discount successfully updated!',
  'Скидка успешно добавлена!': 'Discount successfully added!',
  'Скрыть': 'Hide',
  'В архиве': 'In archive',
  'Скидка удалена!': 'Discount removed!',
  'Не указано': 'Not specified',
  'Скидка №': 'Discount No.',
  'Акт взаиморасчетов за отчетный период': 'Settlement report for the reporting period',
  'Месяц': 'Month',
  'Полгода': 'Half a year',
  'Год': 'Year',
  'Акты взаиморасчётов': 'Settlement acts',
  'Скачать PDF': 'Download PDF',
  'В данном разделе будут размещены все выставленные вам по договору оферты Счета, а так же Акты выполненных работ.': 'This section will contain all invoices and Acts of work performed under the agreement.',
  'Для подтверждения согласия с условиями сотрудничества и начала работы с платформой вам необходимо подписать': 'To confirm your agreement with the terms of cooperation and start working with the platform, you need to sign',
  '“Договор присоединения к платформе Briskly”.': 'Agreement to join the Briskly platform.',
  'Код потверждения уже выслан': 'The confirmation code has already been sent',
  'Попробуйте еще раз через': 'Try again in',
  'секунд': 'seconds',
  'Код из СМС': 'SMS code',
  'Если код не пришел, попробуйте еще раз через': 'If the code didn\'t come, try again in',
  'Неверный код, попробуйте еще раз': 'Incorrect code, please try again',
  'Отправить код повторно': 'Resend code',
  'Количество точек': 'Number of points',
  'Не найдено': 'Not found',
  'Загрузка..': 'Loading..',
  'Регистрационный номер': 'Registration number',
  'Ошибка!': 'Error!',
  'Информация об изменении статуса новых реквизитов будет отправлена в push-уведомлении, а также отобразится в карточке на странице': 'Information about changes in the status of new details will be sent in a push notification and displayed in the card on the page',
  'ИФНС регистрации:': 'Tax authority registration:',
  'Место регистрации организации:': 'Organization registration place:',
  'Фактический адрес с индексом:': 'Actual address with postal code:',
  'Серия:': 'Series:',
  'Дата выдачи:': 'Issue date:',
  'Код подразделения:': 'Department code:',
  'Выдан:': 'Issued by:',
  'Дата рождения:': 'Date of birth:',
  'Место рождения:': 'Place of birth:',
  'Гражданство:': 'Citizenship:',
  'Адрес регистрации:': 'Registration address:',
  'Необходимо загрузить обе фотографии': 'You must upload both photos',
  'ИФНС регистрации': 'Tax authority registration',
  'Фактический адрес с индексом': 'Actual address with postal code',
  'Генеральный директор': 'CEO',
  'ФИО генерального директора': 'Full name of the CEO',
  'Температура С°': 'Temperature C°',
  'за день': 'per day',
  'за неделю': 'per week',
  'Средняя': 'Average',
  'Минимальная': 'Minimum',
  'Максимальная': 'Maximum',
  'Ср.': 'Avg.',
  'Мин.': 'Min.',
  'Макс.': 'Max.',
  'День недели': 'Day of the week',
  'Изменение&nbsp;температуры': 'Temperature change',
  'Воскресенье': 'Sunday',
  'Понедельник': 'Monday',
  'Вторник': 'Tuesday',
  'Среда': 'Wednesday',
  'Четверг': 'Thursday',
  'Пятница': 'Friday',
  'Суббота': 'Saturday',
  'Скидка на категорию': 'Category discount',
  'Набор скидок': 'Discount set',
  'Документы': 'Documents',
  'Скидка на товар': 'Item discount',
  'Заказы': 'Orders',
  'Юридические лица': 'Legal entities',
  'Категории': 'Categories',
  'Каталоги': 'Catalogs',
  'Журнал учёта температуры': 'Temperature log',
  'Настроить': 'Set up',
  'Ожидаемая': 'Expected',
  'Настройка температуры': 'Temperature setting',
  'Выберите ожидаемую температуру': 'Select the expected temperature',
  'Режим энергосбережения': 'Energy saving mode',
  'Функция недоступна для этого оборудования': 'Function not available for this equipment',
  'За 30 минут': 'In 30 minutes',
  'За 1 минуту': 'In 1 minute',
  'Телеметрия': 'Telemetry',
  'Страница телеметрии': 'Telemetry page',
  'Телеметрия будет доступна через': 'Telemetry will be available in',
  'Включить': 'Enable',
  'Вернуться': 'Return',
  'Удалить товары из каталога?': 'Remove products from the catalog?',
  'Закрыть': 'Close',
  'Создать': 'Create',
  'Шаг': 'Step',
  'Ещё': 'More',
  'Деактивировать рекламу': 'Deactivate ad',
  'Вы уверены, что хотите деактивировать данную рекламу?': 'Are you sure you want to deactivate this ad?',
  'Активировать рекламу': 'Activate ad',
  'Вы уверены, что хотите активировать данную рекламу?': 'Are you sure you want to activate this ad?',
  'На модерации': 'Under moderation',
  'Реклама на модерации': 'Ad under moderation',
  'В данный момент реклама на модерации. Проверка может занять 1-2 рабочих дня.': 'The ad is currently under moderation. The review may take 1-2 business days.',
  'Доработка': 'Revision',
  'Необходима доработка рекламы': 'Ad revision required',
  'БРИСКЛИ': 'BRISKLY',
  'Компания': 'Company',
  'Поздравляем! Теперь вы участвуете в рейтинге!': 'Congratulations! You are now part of the rating!',
  'Вы успешно отказались от участия в рейтинге.': 'You have successfully opted out of the rating.',
  'Последняя проверка:': 'Last check:',
  'Текущее кол-во': 'Current quantity',
  'Ошибка при редактировании': 'Error during editing',
  'Применить сейчас': 'Apply now',
  'Выбрать дату и время': 'Select date and time',
  'Выберите время': 'Select time',
  'Указать одну причину для всех товаров': 'Specify one reason for all items',
  'Указать причину у каждого товара индивидуально': 'Specify a reason for each item individually',
  'Выбрать причину': 'Select reason',
  'Выберите причину списания': 'Select write-off reason',
  'Отменить поставку': 'Cancel delivery',
  'Отменить списание': 'Cancel write-off',
  'Не отменять': 'Do not cancel',
  'Вы действительно хотите отменить поставку?': 'Are you sure you want to cancel the delivery?',
  'Вы действительно хотите отменить списание?': 'Are you sure you want to cancel the write-off?',
  'Время операции указывается по часовому поясу торговой точки.': 'The operation time is specified in the time zone of the store.',
  'Текущее количество:': 'Current quantity:',
  'Необходимо выбрать дату': 'A date must be selected',
  'Необходимо выбрать время': 'A time must be selected',
  'успешно завершена': 'successfully completed',
  'Выбрано:': 'Selected:',
  'Расчётная 10/110': 'Calculated 10/110',
  'Расчётная 20/120': 'Calculated 20/120',
  'Русский': 'Russian',
  'Вы находитесь в последней версии личного кабинета.': 'You are in the latest version of the personal account.',
  'Частые вопросы': 'FAQ',
  'Служба поддержки': 'Support service',
  'Новое сообщение': 'New message',
  'в': 'in',
  'Поставка': 'Delivery',
  'Списание': 'Write-off',
  'Подтвердить удаление': 'Confirm deletion',
  'Вы действительно хотите удалить данную торговую точку?': 'Are you sure you want to delete this store?',
  'Торговая точка успешно удалена': 'The store has been successfully deleted',
  'Оплата заказов': 'Order payment',
  'Данный номер телефона будет указан в чеке клиента после покупки': 'This phone number will be listed on the customer’s receipt after purchase',
  'Показать через:': 'Show in:',
  'Повторить через:': 'Repeat in:',
  'часов': 'hours',
  'Включение опции оплаты на выходе': 'Enabling the pay-on-exit option',
  'При включении опции клиент сможет оплатить заказ только после сканирования QR-кода.': 'When enabled, the client can pay for the order only after scanning the QR code.',
  'Не забудьте указать место размещения QR-кода в торговой точке.': 'Do not forget to specify the location of the QR code in the store.',
  'Описание места...': 'Place description...',
  'Место размещения QR-кода': 'QR code placement',
  'Скачать QR-код': 'Download QR code',
  'Доступ к торговой точке': 'Store access',
  'свободный доступ': 'free access',
  'на закрытой территории': 'in a restricted area',
  'Укажите доступ к торговой точке': 'Specify store access',
  'Активация прошла успешно!': 'Activation was successful!',
  'Briskly модуль — это мозг вашего микромаркета. При покупке вам выдали код, который необходимо привязать к аккаунту.': 'The Briskly module is the brain of your micro market. Upon purchase, you received a code that must be linked to your account.',
  'Получать уведомления об изменение и проблемах с уровнем температуры в вашем миркомаркете.': 'Receive notifications about changes and issues with the temperature level in your micro market.',
  'Получать уведомления об изменение и проблемах с соединением вашего микромаркета и ПО': 'Receive notifications about changes and issues with your micro market’s connection and software',
  'Город': 'City',
  'Район': 'District',
  'На страницу точки': 'To the store page',
  'В сети': 'Online',
  'Не работает': 'Not working',
  'Работает': 'Working',
  'Информация': 'Information',
  'Перемещение': 'Relocation',
  'Открыто': 'Open',
  'Завершено': 'Completed',
  'Отменено': 'Cancelled',
  'Статус списания:': 'Write-off status:',
  'Обрезка изображения': 'Image cropping',
  'Обрезать': 'Crop',
  'Неверный формат файла': 'Invalid file format',
  'Подтверждение удаления': 'Deletion confirmation',
  'Подтвердить удаление?': 'Confirm deletion?',
  'Ошибка удаления': 'Deletion error',
  'Только выделенные записи': 'Only selected records',
  'Роль сотрудника:': 'Employee role:',
  'выбрать торговые точки': 'select stores',
  'заполнить название рекламы': 'fill in the ad name',
  'загрузить фото': 'upload photo',
  'заполнить расписание показа': 'fill in the display schedule',
  'Реклама успешно создана': 'Ad successfully created',
  'Фото загружено': 'Photo uploaded',
  'Реклама отредактирована': 'Ad edited',
  'Реклама активирована': 'Ad activated',
  'Реклама деактивирована': 'Ad deactivated',
  'Добавить рекламу': 'Add ad',
  'В данном разделе будут размещены все загруженные изображения рекламы для показа на ваших терминалах': 'This section will host all uploaded ad images for display on your terminals',
  'Ключ API:': 'API Key:',
  'Секретный ключ:': 'Secret Key:',
  'Номер магазина:': 'Store number:',
  'Приватный ключ:': 'Private Key:',
  'Не валидный формат UUID': 'Invalid UUID format',
  'Неизвестная ошибка': 'Unknown error',
  'Торговые точки ': 'Stores',
  'активны': 'active',
  'неактивны': 'inactive',
  'Мой сайт готов к приёму реальных заказов': 'My site is ready to accept real orders',
  'Кофемашина Kaffit': 'Kaffit coffee machine',
  'Кофемашина Jettino': 'Jettino coffee machine',
  'Выберите тип торговой точки': 'Select the type of store',
  'Заполните название': 'Enter the name',
  'Выберите, как будет выглядеть логотип вашей торговой точки в приложении B-Pay.': 'Choose how your store logo will look in the B-Pay app.',
  'Он будет отображаться в карточке товара.': 'It will be displayed in the product card.',
  'На этом экране вы добавляете сотрудников своего магазина, выбирайте их из списка или заносите данные новых.Назначайте им роли в соответсвии с обязанностями.По умолчанию руководитель подключен ко всем ролям.Подробнее вразделе справки.':
  'On this screen, you add store employees, select them from the list, or add new ones. Assign them roles according to their duties. By default, the manager is assigned to all roles. More details can be found in the help section.',
    'Создание нового сотрудника': 'Creating a new employee',
  'Координаты': 'Coordinates',
  'Введите координаты через запятую...': 'Enter coordinates separated by commas...',
  'Задать координатами': 'Set by coordinates',
  'Задать адрес': 'Set address',
  'Прогресс можно будет отслеживать на экране Магазины/Название текущей торговой точки': 'Progress can be tracked on the Stores/Current store name screen',
  'Выберите реквизиты': 'Select details',
  'На данном шаге вы можете прикрепить сотрудников к данной торговой точке. Вы можете создать нового сотрудника или выбрать существующего.':
  'At this step, you can attach employees to this store. You can create a new employee or select an existing one.',
    'В данном разделе вы можете создать или выбрать существующий каталог с товарами, которые будут продаваться в вашем микромаркете.':
  'In this section, you can create or select an existing catalog with products to be sold in your micro market.',
    'Длина кода должна быть 5 символов': 'The code length must be 5 characters',
  'Заполните реквизиты вашего юридического лица. Мы проверим их в течение 1 рабочего дня. Результат проверки вы сможете увидеть в разделе «Юридические лица».':
  'Fill in the details of your legal entity. We will verify them within 1 business day. The verification result will be available in the "Legal Entities" section.',
    'Внешний ключ товара': 'External product key',
  'Включено для просмотра': 'Enabled for viewing',
  'Включено для просмотра и оплаты': 'Enabled for viewing and payment',
  'Изменить состав': 'Change composition',
  'Товары для меню не выбраны': 'No items selected for the menu',
  'Вы можете создать новое меню для данной торговой точки или установить уже созданное ранее на базе текущего каталога.':
  'You can create a new menu for this store or apply an already created one based on the current catalog.',
    'Выбрать меню': 'Select menu',
  'Создать новое': 'Create new',
  'Выберите меню': 'Choose a menu',
  'Вы можете внести изменения в состав меню для всех прикреплённых к нему торговых точек или только для этой торговой точки (в этом случае будет создано новое меню).':
  'You can make changes to the menu composition for all linked stores or only for this store (a new menu will be created in this case).',
    'Изменить только здесь': 'Change only here',
  'Изменить для всех': 'Change for all',
  'Меню не обновлено': 'Menu not updated',
  'Понятно, больше не показывать': 'Got it, don’t show again',
  'Внешний идентификатор в системе товароучета': 'External ID in the accounting system',
  'По состоянию\\nна': 'As of\\n',
  'товаров': 'products',
  'Выключить доступ': 'Disable access',
  'Введите идентификатор торговой точки в вашей системе товароучета.': 'Enter the store identifier in your accounting system.',
  'Дата отгрузки': 'Shipment date',
  'Отправитель': 'Sender',
  'Дата поступления': 'Receipt date',
  'Модель устройства': 'Device model',
  'Отправка': 'Shipment',
  'Приемка на баланс': 'Acceptance to balance',
  'Детали отправки': 'Shipping details',
  'Получатель': 'Recipient',
  'Основание для передачи оборудования': 'Reason for equipment transfer',
  'Доставляется': 'In delivery',
  'Оборудования пока нет': 'No equipment yet',
  'В этом разделе вы сможете принимать, передавать и отправлять по гарантии оборудование.': 'In this section, you can receive, transfer, and send equipment under warranty.',
  'Передать контрагенту': 'Transfer to contractor',
  'Принять на баланс': 'Accept to balance',
  'На балансе': 'On balance',
  'На прием': 'For acceptance',
  'Отправления': 'Shipments',
  'Выберите оборудование для возврата': 'Select equipment for return',
  'Невозможно выбрать оборудование с разными поставщиками': 'Cannot select equipment from different suppliers',
  'Ошибка при выборе': 'Selection error',
  'Выберите оборудование для приемки': 'Select equipment for acceptance',
  'Выберите оборудование для просмотра информации': 'Select equipment to view information',
  'После подтверждения приёмки оборудования гарантия будет работать 2 года. В противном случае гарантия на оборудование истечёт через 6 месяцев.': 'After confirming equipment acceptance, the warranty will last for 2 years. Otherwise, the warranty will expire in 6 months.',
  'Передача оформлена': 'Transfer completed',
  'Вы можете увидеть полный список устройств на вкладке': 'You can see the full list of devices in the tab',
  'Документ о приёмке доступен во вкладке': 'Acceptance document is available in the tab',
  'Частичная приёмка': 'Partial acceptance',
  'Вы уверены, что хотите принять поставку частично?': 'Are you sure you want to partially accept the shipment?',
  'Всего устройств': 'Total devices',
  'Принять частично': 'Accept partially',
  'Ошибка при приемке': 'Error during acceptance',
  'Непринятое оборудование отображается в разделе «На приём»': 'Unaccepted equipment is displayed in the "For acceptance" section',
  'Оборудование принято частично': 'Equipment partially accepted',
  'Основание для возврата оборудования': 'Reason for equipment return',
  'Для завершения возврата оборудования прикрепите один или несколько файлов с указанием основания к каждому передаваемому объекту. Это может быть скан документа, PDF файл или текстовый документ': 'To complete the equipment return, attach one or more files indicating the reason for each transferred item. This could be a document scan, PDF file, or text document.',
  'Загрузить единый файл для всего оборудования': 'Upload a single file for all equipment',
  'Прикрепить файл': 'Attach file',
  'Вернуть оборудование': 'Return equipment',
  'Возврат оформлен': 'Return completed',
  'Частичный возврат': 'Partial return',
  'Вы уверены, что хотите оформить возврат частично?': 'Are you sure you want to process a partial return?',
  'Вернуть частично': 'Return partially',
  'Отправка оборудования': 'Equipment shipment',
  'Идет процесс отправки...': 'Shipment in progress...',
  'Ошибка при возврате': 'Error during return',
  'Оформлен возврат оборудования': 'Equipment return completed',
  'ИНН контрагента': 'Contractor’s Taxpayer ID',
  'Для добавления нового контрагента воспользуйтесь разделом “Контрагенты”': 'To add a new contractor, use the "Contractors" section.',
  'Ответственное лицо': 'Responsible person',
  'Планируемая дата поставки': 'Planned delivery date',
  'Для завершения передачи оборудования прикрепите один или несколько файлов с указанием основания к каждому передаваемому объекту. Это может быть скан документа, PDF файл или текстовый документ.': 'To complete the equipment transfer, attach one or more files indicating the reason for each transferred item. This could be a document scan, PDF file, or text document.',
  'Частичная отправка': 'Partial shipment',
  'Вы уверены, что хотите отправить поставку частично?': 'Are you sure you want to partially ship the order?',
  'Отправить частично': 'Send partially',
  'Выберите контрагента': 'Select contractor',
  'Выберите оборудование для отправки': 'Select equipment for shipment',
  'Оборудование успешно отправлено': 'Equipment successfully shipped',
  'Диагностика': 'Diagnostics',
  'На передачу': 'For transfer',
  'Склад / Отчёты по движению': 'Warehouse / Movement reports',
  'Выбрать все': 'Select all',
  'Отчет за': 'Report for',
  'Завершена': 'Completed',
  'Отменена': 'Canceled',
  'Название рекламного блока': 'Ad block name',
  'Реклама на терминале': 'Ad on terminal',
  'Новый рекламный блок': 'New ad block',
  'Параметры рекламы': 'Ad settings',
  'Например: 30% на салаты после 19:00': 'For example: 30% off salads after 7 PM',
  'Изображение': 'Image',
  'Размер и формат': 'Size and format',
  'Обязательные требования к баннеру:': 'Mandatory banner requirements:',
  'Рекомендации по дизайну баннера:': 'Banner design recommendations:',
  'Для наилучшего сочетания с дизайном терминала рекомендуем использовать тёмный фон (rbg: 25,27,35; hex: 191B23) для ваших рекламных баннеров.': 'For best compatibility with terminal design, we recommend using a dark background (RGB: 25,27,35; HEX: 191B23) for your banners.',
  'Продолжительность показа слайда': 'Slide display duration',
  'Расписание показов рекламного блока': 'Ad block schedule',
  'Удалить рекламу': 'Delete ad',
  'Подтвердите удаление': 'Confirm deletion',
  'Вы уверены, что хотите удалить данную рекламу?': 'Are you sure you want to delete this ad?',
  'Размер': 'Size',
  'Разрешение': 'Resolution',
  'Для корректного отображения остатков необходимо добавить товары и загрузить для них остатки в разделе "Поставки"': 'To display the correct stock levels, you need to add products and upload their stock levels in the "Supplies" section',
  'Базовая цена:': 'Base price:',
  'Отчет по доставкам': 'Delivery report',
  'Автоматически происходит формирование трёх типов отчётов': 'Three types of reports are automatically generated',
  '(если вы используете данный сервис). Вы можете настроить вывод необходимых вам данных в отчёте с помощью Фильтров и Настроек таблицы.': '(if you use this service). You can adjust the output of the data you need in the report using Filters and Table Settings.',
  '№ Заказа': 'Order №',
  'ID платёжного поручения': 'Payment order ID',
  'Товара': 'Product',
  'Товаров': 'Products',
  'Сумма со скидкой': 'Discounted amount',
  'Скачать': 'Download',
  'Наименование контрагента': 'Contractor name',
  'Статус контрагента': 'Contractor status',
  'Производитель': 'Manufacturer',
  'Дилер': 'Dealer',
  'Конечный потребитель': 'End consumer',
  'Файл не выбран': 'No file selected',
  'Ошибка при загрузке файла': 'Error uploading file',
  'Сервисный центр': 'Service center',
  'Контрагенты': 'Contractors',
  'Добавить контрагента': 'Add contractor',
  'Создать нового контрагента': 'Create a new contractor',
  'Для добавления реквизитов введите ИНН нового контрагента.': 'To add details, enter the tax ID of the new contractor.',
  'ИНН или наименование контрагента': 'Tax ID or contractor name',
  'Телефон сотрудника': 'Employee phone',
  'Тип компании': 'Company type',
  'Неправильный формат': 'Invalid format',
  'Заполните правильно все поля': 'Fill in all fields correctly',
  'Контрагент добавлен': 'Contractor added',
  'Добавить новые реквизиты': 'Add new details',
  'Безымянная компания': 'Unnamed company',
  'Нет': 'No',
  'Удалить контрагента?': 'Delete contractor?',
  'Дистрибьютор': 'Distributor',
  'Мерчант': 'Merchant',
  'Сервисная компания': 'Service company',
  'Модель': 'Model',
  'Дата производства': 'Manufacture date',
  'Место нахождения': 'Location',
  'В КАТЕГОРИЮ': 'TO CATEGORY',
  'ИЗ КАТЕГОРИИ': 'FROM CATEGORY',
  'ПЕЧАТЬ СТИКЕРА': 'PRINT STICKER',
  'Не указан': 'Not specified',
  'Печатать описание товара (описание, состав, КБЖУ, название и адрес изготовителя)': 'Print product description (description, composition, calories, name, and manufacturer address)',
  'Печать штрихкода': 'Print barcode',
  'Вы изменили настройки печати штрихкода, но не сохранили данные.': 'You changed the barcode printing settings but did not save the data.',
  'Печать ценника': 'Print price tag',
  'Вы изменили настройки печати ценника, но не сохранили данные.': 'You changed the price tag printing settings but did not save the data.',
  'М': 'M',
  'Ч': 'H',
  'Д': 'D',
  'Г': 'Y',
  'Сек': 'Sec',
  'Загрузка файла': 'File upload',
  'Для правильной загрузки каталога, соотнесите наши названия полей с тем, как они названы у вас.': 'For correct catalog upload, match our field names with how they are named in your file.',
  'При обработке загруженного файла были обнаружены ошибки заполнения некоторых полей. Исправьте значения и загрузите файл снова.': 'Errors were found in some fields while processing the uploaded file. Fix the values and upload the file again.',
  'Средний чек:': 'Average receipt:',
  'Примите участие в нашем рейтинге, чтобы увидеть места конкурентов!': 'Participate in our ranking to see competitors\' positions!',
  'Место': 'Place',
  'Торговая точка, компания': 'Point of sale, company',
  'Топ-5 товаров по выручке': 'Top 5 products by revenue',
  'сегодня': 'today',
  'Маркетплейс': 'Marketplace',
  'Новое Меню': 'New Menu',
  'Выключено': 'Disabled',
  'Только просмотр': 'View only',
  'Просмотр и оплата': 'View and pay',
  'ID или название меню': 'Menu ID or name',
  'ID и название каталога': 'Catalog ID and name',
  'Вы действительно хотите удалить меню?': 'Do you really want to delete the menu?',
  'Меню обновлено': 'Menu updated',
  'Базовый каталог:': 'Base catalog:',
  'Список ваших меню пока пуст.': 'Your menu list is empty.',
  'Выберите каталоги': 'Select catalogs',
  'Авторизован': 'Authorized',
  'Отклонен': 'Rejected',
  'Зарегистрирован': 'Registered',
  'Не определен': 'Undefined',
  'Возврат оформлен!': 'Return processed!',
  'Товар удален': 'Product removed',
  'Скачать отчет': 'Download report',
  'Компания-партнёр прикреплена': 'Partner company attached',
  'Выберите из списка': 'Select from the list',
  'Мы отправили сообщение с кодом на адрес': 'We have sent a message with the code to the address',
  'Пожалуйста, введите код в поле.': 'Please enter the code in the field.',
  'Неправильный код': 'Incorrect code',
  'Некорректный адрес': 'Incorrect address',
  'Остатков пока нет': 'No stock available yet',
  'Доступ по ссылке:': 'Access via link:',
  'Перезагрузить модуль': 'Reload module',
  'Онлайн': 'Online',
  'Не в сети': 'Offline',
  'Открыта': 'Open',
  'Замок открыт': 'Lock is open',
  'Текущая температура': 'Current temperature',
  'График температуры микромаркета': 'Micro-market temperature chart',
  'Модуль перезагружен': 'Module reloaded',
  'У вас пока нет подходящего оборудования': 'You do not have suitable equipment yet',
  'В данном разделе вы сможете наблюдать за изменениями температуры в ваших микромаркетах в течение дня, недели или месяца, регулировать текущую температуру, а также перезагружать модуль': 'In this section, you can monitor temperature changes in your micro-markets over a day, week, or month, adjust the current temperature, and also reload the module',
  'день': 'day',
  'дня': 'days',
  'дней': 'days',
  'Будет сгенерировано автоматически...': 'Will be generated automatically...',
  'Свойства': 'Properties',
  'Эспрессо': 'Espresso',
  'Для весовых товаров необходимо указать первые 7 цифр штрихкода.': 'For weight-based items, you must specify the first 7 digits of the barcode.',
  'Штрихкод не соответствует стандарту': 'Barcode does not comply with the standard',
  'Штрихкод не уникален': 'Barcode is not unique',
  'Информация о товаре': 'Product information',
  'Например: Ароматный, мягкий сыр. Идеально подойдет для бутербродов с красной рыбой.': 'For example: Fragrant, soft cheese. Perfect for sandwiches with red fish.',
  'Состав товара': 'Product composition',
  'модификация': 'modification',
  'модификаций': 'modifications',
  'На Категорию ID': 'For Category ID',
  'Скидка в': 'Discount in',
  'На товар ID': 'For product ID',
  'у': 's',
  '«Юридические лица».': '“Legal entities.”',
  'Сегодня': 'Today',
  'час': 'hour',
  'ов': 'hours',
  'Стикер': 'Sticker',
  'Выручка:': 'Revenue:',
  'ID или название каталога': 'ID or catalog name',
  'dd.MM.yyyy в HH:mm': 'dd.MM.yyyy at HH:mm',
  'IP Адрес': 'IP Address',
  'ID заказа': 'Order ID',
  'Формат': 'Format',
  'Необходимо': 'Required',
  '+ Поставка': '+ Delivery',
  '+ Списание': '+ Write-off',
  '.м': ' .m',
  '10 минут': '10 minutes',
  '30 минут': '30 minutes',
  '1 час': '1 hour',
  '12 часов': '12 hours',
  '1 день': '1 day'
};
