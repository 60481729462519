<template>
  <div class="chat" ref="chat">
    <div class="u-flex u-flex--col">
      <div class="response">
        <div class="response__container">
          <Icon name="dot" class="admin-icon" />
          <h5 class="response__person">{{ 'Briskly' }}</h5>
        </div>

        <p class="response__text">{{ chat_message.text }}</p>
      </div>
    </div>
    <div class="messages-container">
      <div v-for="(group, key) in messagesPayload" :key="key" class="u-flex u-flex--col message-box">
        <Chip :text="formatChipToDayMonth(group.date)" class="chip" />
        <div v-for="(message, key) in group.items" :key="key">
          <div class="u-flex u-flex--col" v-if="message.sender.alias === 'administrator'">
            <div class="response" @contextmenu="onAdminMessageRightClick($event, message)">
              <div v-if="message.replied_message" class="replied-message">
                <Icon name="dot" class="admin-icon" /><strong>Briskly</strong>
                <span v-if="message.replied_message.deleted_at !== '0000-00-00 00:00:00'">{{ message.replied_message.text }}</span>
                <span class="italic" v-else>Сообщение удалено</span>
              </div>
              <div class="u-flex u-ai-center">
                <Icon name="dot" class="admin-icon" />
                <h5 class="response__person">{{ 'Briskly' }}</h5>
              </div>
              <p class="response__text">{{ message.text }}</p>
              <div v-if="message.files" class="files-container">
                <div v-for="file in message.files">
                  <img src="/icons/attached-file-type.svg" />{{ file.id }}.{{  file.file_extension }}
                </div>
              </div>
              <div class="u-flex u-ai-center request__container">
                <span style="font-size: 12px;" v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)">изменено</span>

                <Icon name="icon_send" class="request__isread" v-if="!message.is_read" />
                <Icon name="icon_isread" class="request__isread" v-else />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
          <div class="u-flex u-flex--col" v-else>
            <div class="request" @contextmenu="onMessageRightClick($event, message)">
              <div v-if="message.replied_message" class="replied-message">
                <strong v-if="message.replied_message?.sender_object_id == message.sender?.id">Вы</strong>
                <div class="u-flex u-ai-center" v-else>
                  <Icon name="dot" class="admin-icon" />
                  <h5 class="response__person">{{ 'Briskly' }}</h5>
                </div>
                <span v-if="message.replied_message.deleted_at && message.replied_message.deleted_at !== '0000-00-00 00:00:00'">{{ message.replied_message.text }}</span>
                <span class="italic" v-else>Сообщение удалено</span>
              </div>
              <p class="request__text">{{ message.text }}</p>
              <div v-if="message.files" class="files-container">
                <div v-for="file in message.files" class="message-file" @click="onClickDownload(file)">
                  <img src="/icons/attached-file-type.svg" />
                  <span>
                    {{ generateFileText(file) }}
                  </span>
                </div>
              </div>

              <div class="u-flex u-ai-center request__container">
                <span style="font-size: 12px;" v-if="message.created_at.substr(-8) !== message.updated_at.substr(-8)">изменено</span>

                <Icon name="icon_send" class="request__isread" v-if="!message.is_read" />
                <Icon name="icon_isread" class="request__isread" v-else />

                <p class="request__date">{{ formatDateToTime(message.created_at) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="end" ref="end"></div>
  </div>
  
  <ContextMenu ref="menu" :model="contextMenuOptions" />
  <ContextMenu ref="replyMenu" :model="replyMenuOption" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 p-4 mb-4 pb-0">
        <i :class="slotProps.message.icon" class="text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <div class="edit-container" v-if="quoteMessageId">
    <span class="pi pi-reply" style="font-size: 20px;"></span>
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Ответить на сообщение</span>
        <div style="color: #191B23;">
          {{ quoteMessageText }}
        </div>
      </div>

      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>

  <div class="edit-container" v-if="editMessageId">
    <span class="pi pi-pencil" style="font-size: 20px;"></span>
    <div style="justify-content: space-between; display: flex; width: 100%; align-items: center;">
      <div style="display: flex; flex-direction: column; gap: 3px">
        <span style="color: #6A7B95;font-size: 14px;font-weight: 500;">Редактировать сообщение</span>
        <div style="color: #191B23;">
          {{ editMessageText }}
        </div>
      </div>

      <span style="cursor: pointer; font-size: 20px;" class="pi pi-times" @click="clearEditValues"></span>
    </div>
  </div>

  <div class="input-container">
    <BaseInput class="chat-input" v-model="newChatMessage" innerIconDirection="left" innerIcon="icon_send-message"
      @keydown.enter="onKeydownSend">
      <template #icon-inner>
        <Icon name="icon_send-message" class="icon-send" @click="onSendMessage" @keydown.enter="onKeydownSend" />
      </template>
    </BaseInput>

    <FileUpload :maxFileSize="20000000" ref="attachment" chooseLabel=" ">
      <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
        <div class="flex">
          <span @click="chooseCallback()" class="pi pi-upload"></span>
        </div>
      </template>
      <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
        <div class="files">
          <div class="files-counter" v-if="files.length">
            {{ files.length }}
          </div>
        </div>
      </template>
      <template #empty>
        <div></div>
      </template>
    </FileUpload>

    <div class="emoji-picker-icon">
      <emoji-picker class="input-button-picker" @onChange="onInputEmoji" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Icon, BaseInput } from '@brskl/ui-lib';

import Chip from '@/apps/reports/component/Chip.vue';
import { Components } from '@brskl/core';
import { supportAPI } from '@/core/api/_support';
import { companyAPI } from '@/core/api/company.js';

import { uniqBy } from 'lodash';
import { format, compareAsc } from 'date-fns';

const { EmojiPicker } = Components;

import FileUpload from 'primevue/fileupload';
import ContextMenu from 'primevue/contextmenu';
import ConfirmPopup from 'primevue/confirmpopup';
import 'primeicons/primeicons.css'
import { mapActions } from 'vuex';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default defineComponent({
  props: {
    messages: {
      type: Object,
    },
    detachedMode: {
      type: Boolean,
      default: false
    }
  },

  emits: ['sendMessage', 'refetch'],
  //@ts-ignore
  components: { Icon, BaseInput, Chip, FileUpload, EmojiPicker, ContextMenu, ConfirmPopup },

  data() {
    return {
      txt: 'txt',
      date: '20-04-2021',
      person: 'meme memev',
      newChatMessage: '',
      editMessageText: '',
      editMessageId: 0,
      quoteMessageId: 0,
      quoteMessageText: '',
      messagesList: [],
      contextMenuEntityTarget: {
        files: []
      },

      chat_message: {
        chat: {
          administrator_id: 48,
          company_id: 0,
          customer: null,
          employee: null,
          funnel: null,
          id: -1,
          last_comment: null,
          last_message: null,
          name: '',
          status: 1,
        },
        chat_id: 2633,
        created_at: this.formatDateToTime(new Date().toISOString()),
        files: [],
        id: -1,
        is_read: false,
        order: [],
        order_id: 0,
        reply_to_id: 0,
        reply_to_message: [],
        sender: {
          alias: 'administrator',
          id: 48,
          name: 'Briskly',
        },
        text: this.$t('support_service_greeting'),
        type: 0,
        scrollTimeout: null,
      },
    };
  },

  computed: {
    groupedItems() {
      if (!this.messagesList) {
        return;
      }

      const formatDate = (date) => format(new Date(date), 'yyyy-MM-dd');
      const list = this.messagesList
        .map((item) => item.created_at.split(' ').join('T'))
        .sort((d1, d2) => compareAsc(new Date(d1), new Date(d2)))
        .map((item) => formatDate(item))

      const dates = uniqBy(
        list,
      );

      const store = {};

      dates.forEach((date) => (store[date] = []));

      this.messagesList.forEach((item) => {
        const key = formatDate(item.created_at.split(' ').join('T'));
        store[key].push(item);
      });

      const result = [];

      for (const [date, items] of Object.entries(store)) {
        const messages = items.filter(m => {
          if (typeof m.deleted_at !== 'undefined') {
            return m.deleted_at == '0000-00-00 00:00:00'
          } else {
            m.deleted_at = '0000-00-00 00:00:00'
            m.updated_at = m.created_at
            return true
          }
        })
        result.push({ date, items: this.sortMessages(messages) });
      }

      return result;
    },
    messagesPayload() {
      return this.groupedItems
    },
    contextMenuOptions() {
      if (!this.contextMenuEntityTarget) {
        return [{ label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() }]
      }

      if (this.contextMenuOptions?.files?.length > 0) {
        return [
          { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
          { label: 'Удалить', icon: 'pi pi-trash', command: () => this.startRemovingMessage() },
        ]
      }
      
      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
        { label: 'Редактировать', icon: 'pi pi-pencil', command: () => this.startEditingMessage() },
        { label: 'Удалить', icon: 'pi pi-trash', command: () => this.startRemovingMessage() },
      ]
    },
    replyMenuOption() {
      return [
        { label: 'Ответить', icon: 'pi pi-copy', command: () => this.startQuotingMessage() },
      ]
    },
  },

  methods: {
    ...mapActions({
      add: 'core$notifications/add',
      setChat: 'core$notifications/setChat',
    }),

    clearEditValues() {
      this.contextMenuEntityTarget = {files: []}; 
      this.editMessageId = 0; 
      this.editMessageText = ''; 
      this.newChatMessage = '';
      this.quoteMessageId = 0;
      this.quoteMessageText = '';
    },

    startEditingMessage() {
      const { id, text } = this.contextMenuEntityTarget
      this.editMessageId = id
      this.editMessageText = "" + text
      this.newChatMessage = text
    },

    startQuotingMessage() {
      const { id, text } = this.contextMenuEntityTarget
      this.quoteMessageId = id
      this.quoteMessageText = text
    },

    async startRemovingMessage() {
      let messageIndex
      const { id } = this.contextMenuEntityTarget

      await supportAPI.deleteMessage({ id });
      // await this.fetchMessages()

      const messages = this.messagesList
      messages.every((m, idx) => {
        if (messageIndex) {
          return false
        }

        if (m.id == id) {
          messageIndex = idx
        }

        return true
      })

      this.messagesList.map(k => {
        if (k.id == id) {
          k.deleted_at = true
        }
      })
    },

    async updateMessage() {
      await supportAPI.updateMessage({ id: this.editMessageId, text: this.newChatMessage });
      this.clearEditValues()
    },

    formatDateToTime(date) {
      const _date = date.split(' ').join('T');
      return format(new Date(_date), 'HH:mm');
    },

    onInputEmoji(emoji) {
      this.newChatMessage = this.newChatMessage + emoji.native
    },

    generateFileText(file) {
      if (file.name) return file.name;

      if (file.file_extension) return `Файл.${file.file_extension}`;
      return 'Прикреплённый файл';
    },

    async onClickDownload(file) {
      const target = file;

      try {
        const result = await supportAPI.downloadFile({ id: target.id });
        window.open(result);
      } catch (error) {
        console.error(error);
      }
    },

    onMessageRightClick(e, message) {
      this.$refs.menu.show(e)
      this.contextMenuEntityTarget = message
    },

    onAdminMessageRightClick(e, message) {
      this.$refs.replyMenu.show(e)
      this.contextMenuEntityTarget = message
    },

    formatChipToDayMonth(date) {
      if (!date) {
        return;
      }

      const _date = date.split(' ').join('T');
      const month = 'MONTH_' + format(new Date(_date), 'MM');
      const day = format(new Date(_date), 'dd');
      return day + ` ${this.$t(month)}`;
    },

    async sendNewChatMessage(text) {
      if (text.trim() === '') return;
      const response = await companyAPI.sendMessage({ text });
    },

    async onSendMessage() {
      const files = this.$refs.attachment.files
      if (files.length > 0) {
        files.forEach(async (file) => {
          const base64Contents = await toBase64(file)

          try {
            await supportAPI.uploadFile({ file: base64Contents });
            await this.fetchMessages(50)
          } catch (e) {
            console.error(e);
          }
        })

        this.$refs.attachment.files = []
      }

      if (this.editMessageId) {
        await this.updateMessage()
        await this.fetchMessages()
        this.newChatMessage = '';
        return false;
      }

      if (this.quoteMessageId) {
        await supportAPI.replyToMessage({ text: this.newChatMessage, reply_to: this.quoteMessageId })
        this.clearEditValues()
        await this.fetchMessages()
        return false;
      }

      // if (this.detachedMode) {
      await this.sendNewChatMessage(this.newChatMessage)
        // await this.fetchMessages(50)
      // }

      this.newChatMessage = '';
      this.$emit('sendMessage', this.newChatMessage);
      this.$emit('refetch')
    },

    onKeydownSend(ev) {
      if (ev.shiftKey) return;

      ev.preventDefault();
      this.onSendMessage();
    },

    scrollToBottom(smooth = true) {
      this.$refs.end.scrollIntoView();
    },

    async fetchMessages(timeout = 500) {
      try {
        let messagesProcessed = false
        const payload = {
          limit: 9999,
          page: 1,
        };

        let { items } = await companyAPI.getMessages(payload);
        items = items.map((i) => ({ ...i, created_at: i.created_at.split(' ').join('T') }));

        const messagesProcessor = (message) => {
          return new Promise(resolve => resolve(this.handleChatMessage({ chat_message: message })))
        }
        const messages = items.map((el) => messagesProcessor(el));
        setTimeout(() => this.scrollToBottom(), timeout)
      } catch (error) {
        console.error(error);
      }
    },

    async handleChatMessage(data, fromSocket = false) {
      const message = data.chat_message
      if (!message.deleted_at) {
        message.deleted_at = '0000-00-00 00:00:00'
        message.updated_at = message.created_at
      }

      this.messagesList.push(message);

      if (fromSocket) {
        setTimeout(() => this.scrollToBottom(), 100)
      }
      
      if (this.revealChat && data.chat_message.is_read === false) {
        await companyAPI.setAsRead({ id: data.chat_message.id });
      } else if (!this.revealChat && data.chat_message.is_read === false && data.chat_message.sender.alias === 'administrator') {
        this.add({
          message: data.chat_message.text,
          type: 'chat-message',
          source: 'handleChatMessage',
          title: this.$t('Новое сообщение'),
        });
      }
    },

    sortMessages(messages) {
      return messages.sort((m1, m2) => compareAsc(new Date(m1.created_at), new Date(m2.created_at)));
    },
  },

  mounted() {
    bus.on('core$chat/newChatMessage', (data) => this.handleChatMessage(data, true));
    this.fetchMessages()
  },

  unmounted() {
    clearTimeout(this.scrollTimeout);
    bus.off('core$chat/newChatMessage', this.handleChatMessage);
  },
});
</script>

<style lang="scss" scoped>
$height-chat-input: 80px;

.message-file {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  gap: 4px;
}

.replied-message {
  border-left: 2px solid #22C951;
  font-size: 12px;
  color: #515C72;
  padding: 4px 8px;
  display:flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.replied-message span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.italic {
  font-style: italic;
}

.edit-container {
  position: fixed;
  bottom: 50px;
  background: #E9EBF3;
  border-radius: 8px;
  display: flex;
  margin: 30px;
  left: 0;
  right: 0;
  gap: 20px;
  padding: 24px 20px;
  align-items: center;
}

.messages-container {
  padding-bottom: 0px;
}

.files {
  position: relative;
}

.files-container img {
  display: flex;
}

:deep(.base-input__field) {
  padding-left: 35px !important;
}

.emoji-picker-icon {
  right: 40px;
  display: flex;
  position: absolute;
  top: 12px;
}

@media screen and (max-width: 768px) {
  .emoji-picker-icon {
    display: none;
  }
}

.files-counter {
  border-radius: 128px;
  background: #22c951;
  padding: 3px 0px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  font-weight: bold;
  height: 18px;
  width: 18px;
  color: #FFF;
  bottom: -8px;
  right: -8px;
}

.p-fileupload {
  position: absolute;
  top: 6px;
  bottom: 0;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  left: 10px;
  margin: auto;
}

:deep(.p-fileupload-header) {
  border: 0 !important;
}

.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  // overflow-y: auto;
  background: #fff;
  padding-bottom: $height-chat-input;
}

.response {
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  min-height: 72px;
  min-width: 243px;
  max-width: 600px;
  background: rgba(196, 211, 234, 0.5);
  border-radius: 8px;
  margin: 8px 32px;
  padding: 12px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    margin: 0 0 0 6px;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
    align-self: flex-end;
  }

  &__container {
    display: flex;
    align-items: center;
    margin: 0 0 4px 0;
  }
}

.request {
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  min-height: 52px;
  min-width: 243px;
  max-width: 600px;

  background: #e9ebf3;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 32px;
  word-break: break-all;

  &__person {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #515c72;
    align-self: flex-start;
  }

  &__container {
    align-self: flex-end;
  }

  &__isread {
    width: 14px !important;
    height: 14px !important;
    margin: 0 4px 0 0;
  }

  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #191b23;
    align-self: flex-start;
  }

  &__date {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #6a7b95;
  }
}

.icon-send {
  width: 24px !important;
  height: 24px !important;
}

.chat-input {
  width: 100%;

  &:deep(.base-input__icon) {
    display: flex;
    align-items: center;
  }
}

.input-container {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background: #fff;
  margin: 30px;
  max-height: $height-chat-input;
}

.chip {
  width: 100px;
  white-space: nowrap;
  align-self: center;
  cursor: default;
  user-select: none;
  margin: 31px 0;
}

.admin-icon {
  width: 24px !important;
  height: 24px !important;
  fill: #22c951;
}
</style>