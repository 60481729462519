import { bus, api } from '@brskl/core';
import router from '@/core/router';
import { NoticeEnumTypes } from '../data/Notice-enum';
import { companyAPI } from '../api/company';

class MainEvent {
  store;

  init(_store) {
    this.store = _store;
    bus.on('core$auth/logout', this.onTokenExpired);
    bus.on('authInit', this.onAuthInit);
    bus.on('shared$auth/tokenExpired', this.onAuthInit);

    bus.on('notification$add', this.handleNotice);
    bus.on('core$auth/init', this.coreAuthHandler);
    // console.log(bus.all); //Можно использовать что бы посмотреть все евенты шины событий
  }

  onTokenExpired = () => {
    if (location.href.includes('carts')) return this.coreAuthHandler();
    const routeName = router.currentRoute.value.name as string;
    if (!['auth'].includes(routeName)) {
      router.push({ name: 'logout', params: { isTokenExpired: 'true' } });
    }
  };

  onAuthInit = () => {
    localStorage.clear();
    console.log(router);

    router.push({ name: 'main' });
  };

  handleNotice = () => {
    this.store.dispatch('core$notifications/add', { message: 'message', type: NoticeEnumTypes.success, title: 'title' }, { root: true });
  };

  coreAuthHandler = async () => {
    const url = new URL(window.location.href);

    const params: Record<string, string> = {};
    const search = url.search.startsWith('?') ? url.search.slice(1) : url.search;
    search.split('=').forEach((item, idx, aa) => {
      if (idx & 1) params[aa[idx - 1]] = item;
    });

    if (params.key) {
      try {
        api.tokens.deleteTokens(); //МБ вернуть
        const { data } = await companyAPI.getTokensBySecretKey({ uuid: params.key });

        const tokens = Object.entries(data.tokens)
          .map(([key, val]) => {
            if (['access_token', 'refresh_token'].includes(key)) return `${key}=${val}`;
          })
          .join('&');

        // history.replaceState({}, document.title, url);
        //@ts-ignore
        api.tokens.saveTokens({ access_token: data.tokens.access_token, refresh_token: data.tokens.refresh_token, session: true }, false);
        //alert(`${url.origin}${url.pathname}?isTV=true`);

        location.href = `${url.origin}${url.pathname}?isTV=true`;
      } catch (error) {
        const routeName = router.currentRoute.value.name as string;
        if (!['auth'].includes(routeName)) {
          router.push({ name: 'logout', params: { isTokenExpired: 'true' } });
        }
      }
    }
  };
}

export default new MainEvent();
